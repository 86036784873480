import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useStores} from "../../common/store";
import api from "../../common/api";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {CardActions, useMediaQuery} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {dataFormat, StringNvl} from "../../common/utils/StringFormatUtils";
import {CommonCodeValue} from "../../common/types";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../../components/ConfirmDialog";
import {Pagination} from "@material-ui/lab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column'
        }
    },
    cardRoot: {
        display: 'flex',
        flex: '0 1 30%',
        flexDirection: 'column',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        background: theme.palette.background.panel,
        '&.MuiPaper-elevation1': {
            boxShadow: 'none',
        },
        '&.MuiPaper-rounded': {
            borderRadius: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: `0 0 ${theme.spacing(2)}px 0`
        }
    },
    cardContent: {
        '& > dl': {
            display: 'flex',
            justifyContent: 'space-between',
            '& > dt': {
                color: theme.palette.text.gray,
            },
            '& > dd': {
                margin: 0,
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                flexDirection: 'column'
            }
        },
    },
    cardAction: {
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'center',
            '& > button': {
                flex: 1
            }
        }
    },
    pageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    moreBtn: {
        flex: 1,
        height:theme.spacing(6),
    }
}));


export default function MySitePage(props) {
    const {t} = useTranslation();
    const {ds} = useStores();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [pageInfo, setPageInfo] = React.useState({page: 1, pageSize: 3});
    const classes = useStyles();

    const [response, setResponse] = useState(null);
    const [confirm, setConfirm] = useState(false);

    const deleteClientKey = useRef();



    const fetch = (page = 1, pageSize, delItem = false) => {
        api.getLinkClient(page - 1, pageSize)
            .then(res => {

                if (isMobile) {
                    if (response === null) {
                        setResponse(res);
                    } else {
                        res.data.forEach((item) => {
                            response.data?.push(item);
                        })
                    }

                    setResponse({...res, data: response.data});
                } else {

                    setResponse(res);
                }

                if( delItem ){
                    if( page > res?.page?.totalPages ) {
                        setPageInfo({...pageInfo, page: page -1 });
                    }
                }

            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    useEffect(() => {
        fetch(pageInfo.page, pageInfo.pageSize);
    }, [pageInfo]);

    const onUnLink = () => {

        console.log("seq => ", deleteClientKey.current);
        api.deleteUnLink(deleteClientKey.current)
            .then(res => {
                console.log(" response => ", res);
                ds.showSuccessAlert(t('UserPage.Detail.Dialog.UnLinkResultMessage'));
                fetch(pageInfo.page, pageInfo.pageSize, true);
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });

        onCloseConfirm();
    }

    const onCloseConfirm = () => {
        deleteClientKey.current = undefined;
        setConfirm(false);
    }

    const onOpenConfirm = (deleteKey) => {

        deleteClientKey.current = deleteKey;
        setConfirm(true);
    }

    const onChangePage = (event, newPage) => {
        setPageInfo({...pageInfo, page: newPage});
    }

    const goNext = () => {
        setPageInfo({...pageInfo, page: pageInfo.page + 1});
    }

    return (
        <React.Fragment>
            <Box className={classes.root}>
                {response != undefined && response?.data?.map((data, index) => (
                        <Card className={classes.cardRoot} key={index}>
                            <CardHeader
                                title={data.clientName}
                            />
                            <Divider light/>
                            <CardContent className={classes.cardContent}>
                                <dl>
                                    <dt>{t('MyPage.Site.Id')}</dt>
                                    <dd>{StringNvl(data.extraKey, ds.user.userKey )}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.Type')}</dt>
                                    <dd>{StringNvl(t(CommonCodeValue.translateKey + '.' + data.userType), '-')}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.VerifyType')}</dt>
                                    <dd>{StringNvl(data.verifyType, 'Default')}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.Status')}</dt>
                                    <dd>{StringNvl(t(CommonCodeValue.translateKey + '.' + data.userStatus), '-')}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.RegDt')}</dt>
                                    <dd>{StringNvl(dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm:ss'), '-')}</dd>
                                </dl>

                            </CardContent>
                            <CardActions className={classes.cardAction}>
                                {
                                    api.MASTER_CLIENT_KEY !== data.clientKey
                                    && <Button variant="contained" color="primary"
                                               onClick={() => onOpenConfirm(data.clientKey)}> {t('MyPage.Site.UnLink')} </Button>
                                }
                            </CardActions>
                        </Card>
                    )
                )}

                <ConfirmDialog open={confirm}
                               onClose={onCloseConfirm}
                               onOk={onUnLink}
                               title={t('MyPage.Site.MSG_01')}
                               content={t('MyPage.Site.MSG_02')}
                />
            </Box>
            <Box className={classes.pageContainer}>
                {isMobile
                    ? (
                        <Button className={classes.moreBtn}
                                variant={'outlined'}
                                onClick={goNext}
                                disabled={response?.page?.last}
                        >
                            { t('MyPage.Site.More')}
                            <ArrowDropDownIcon />
                        </Button>
                    ) : (
                        <Pagination
                            size={isMobile ? 'small' : 'large'} count={response?.page?.totalPages}
                            page={response?.page === undefined ? 1 : response?.page?.currentPage + 1}
                            onChange={onChangePage}
                            shape={'rounded'}
                        />
                    )
                }
            </Box>
        </React.Fragment>
    )
}
