import React, {useEffect, useRef, useState} from 'react';

import * as PropTypes from "prop-types";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CommonCodeValue, UserType} from "../common/types";
import {useStores} from "../common/store";
import {useObserver} from "mobx-react";
import LabelText from "./LabelText";


AccessClientSelectBox.propTypes = {
    className: PropTypes.any,
    title:PropTypes.string,
    contents: PropTypes.any,
    onChange: PropTypes.func,
    addAll: PropTypes.bool,
    displayEmpty: PropTypes.bool

};

export default function AccessClientSelectBox({className, title, defaultClientKey, defaultClientName, onChangeSelect, addAll = true, readOnly = false, displayEmpty = true}) {
    const [clients, setClients] = useState(null);
    const [labelId] = useState(() => _.uniqueId('label-'));
    const {ds} = useStores();
    const clientsArray = useRef([]);

    const onChangeClient = (event) => {
        if (onChangeSelect) {
            onChangeSelect('client', event.target.value);
        }
    }
    if( ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null ) {
        if( clientsArray.current.length === 0 ) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientsArray.current[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }

    return useObserver( () => (
        <React.Fragment>
            { readOnly
                ? (
                    <span>{defaultClientName ? defaultClientName : '-'}</span>
                )
                : (
                    <FormControl variant="outlined" className={className}>
                        <InputLabel id={labelId}>{title}</InputLabel>
                        <Select
                            labelId={labelId}
                            label={title}
                            displayEmpty={displayEmpty}
                            defaultValue={defaultClientKey}
                            onChange={onChangeClient}
                            labelWidth={80}
                        >
                            {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}

                            {
                                clientsArray.current.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                            }
                        </Select>

                    </FormControl>
                ) }
        </React.Fragment>
    ));
}
