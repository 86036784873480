import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import DataTable from '../components/DataTable';
import Button from '@material-ui/core/Button';
import api from "../common/api";
import {Redirect} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next"
import {CommonCodeValue} from "../common/types";
import queryString from 'query-string';
import SearchBox from "../components/SearchBox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ClientCreate from "./ClientCreate";
import {Scrollbars} from "react-custom-scrollbars";
import {useStores} from "../common/store";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        // background: theme.palette.background.default,
        padding: '40px 50px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
            '& > *' :{
                marginTop:theme.spacing(1),
                marginBottom:theme.spacing(1),
            },
        }
    },
    searchBox: {
        minWidth: '20rem',
    },
    searchText: {
        width: '60%',
    },
    button: {
        justifyContent: 'center',
        minWidth: 140,
        paddingTop: 12,
        paddingBottom: 12,
        marginLeft: 16,
    },
}));


const dialogStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        '& .MuiDialog-paperWidthMd': {
            width: '100%'
        }
    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    content: {
        padding: 0,
    },
    track: {
        position: 'absolute',
        width: '7px',
        height: '100vh',
        right: '2px',
        bottom: '2px',
        paddingTop: 'calc(5rem + 5px)',
        borderRadius: '3px',
        paddingRight: '2px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none',
        },
    }
}));

const tableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60
        },
        '& > :nth-child(2)': {
            minWidth: 160,
        },
        '& > :nth-child(3)': {
            minWidth: 130,
            textAlign: 'right',
        },
        '& > :nth-child(4)': {
            minWidth: 130,
            textAlign: 'right',
        },
        '& > :nth-child(5)': {
            minWidth: 130
        },
        '& > :nth-child(6)': {
            minWidth: 130,
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : 'white',
        backgroundColor: theme.palette.type === 'dark' ? '#0d1015' : '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.type === 'dark' ? '#29303a' : '#f8f8f8'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.type === 'dark' ? '#323942' : '#e7e7e7'
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

/**
 *  회원목록 조회 화면
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClientListPage(props) {
    const {ds} = useStores();
    const dataArray = [];
    const pageSize = 5;
    const {location} = props;
    const classes = useStyles();
    const {t} = useTranslation();

    const query = queryString.parse(location.search);
    let page = query['page'] !== undefined ? Number(query['page']) : 1;
    let keyword = query['keyword'] !== undefined ? query['keyword'] : null;
    const [response, setResponse] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);

    const [clickEvent, setClickEvent] = useState(location.state ? location.state.clickEvent : null);

    const changedSearchKeywordEvent = (event) => {
        keyword = event.target.value;
    }

    const rowClickEvent = (row) => (event) => {
        if( row[6] !== undefined ) {
            setClickEvent({event: 'detail', clientKey: row[6]?.content, clientName: row[1]?.content, licenseSeq: row[7]?.content});
        }
    }

    const createClickEvent = () => {
        //setClickEvent({event: 'create'});
        setCreateOpen(prevState => !prevState);
    }

    const search = (event, page = 1) => {
        const query = {page, keyword};
        props.history.push('/admin/clients?' + api.encodeParams(query, true));
    }

    const fetchData = (query) => {
        api.getClientList(query.keyword, query.page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData({keyword, page});
    }, [location.search]);


    if (response !== null) {
        let index = response.page.currentPage * pageSize;
        const numberFormat = new Intl.NumberFormat();
        response.data.map((data, idx) => {
            let clientName = data.clientName ? data.clientName : '-';
            let userCount = data.userCount !== undefined ? numberFormat.format(data.userCount) : '-';
            let authCount = data.authCount !== undefined ? numberFormat.format(data.authCount) : '-';
            let licenseStatus = data.licenseStatus !== undefined ? t(CommonCodeValue.translateKey + '.' + data.licenseStatus) : '-';
            let typeCodeStr = data.licenseType ? t(CommonCodeValue.translateKey + '.' + data.licenseType) : '-';

            dataArray[idx] = [
                {id: 'index', content: ++index},
                {id: 'clientName', align: 'left', content: clientName},
                {id: 'userCount', align: userCount !== '-' ? 'right' : 'center', content: userCount},
                {id: 'authCount', align: authCount !== '-' ? 'right' : 'center', content: authCount},
                {id: 'statusCodeStr', content: licenseStatus},
                {id: 'typeCodeStr', content: typeCodeStr},
                {id: 'clientKey', content: data.clientKey},
                {id: 'licenseSeq', content: data.licenseSeq}
            ];

        });
    }


    if (clickEvent !== null) {
        const eventType = clickEvent.event;
        if (eventType === 'detail') {
            return (
                <Redirect push to={
                    {
                        pathname: '/admin/clients/detail',
                        search: "?" + api.encodeParams({clientKey: clickEvent.clientKey, licenseSeq:clickEvent.licenseSeq}),
                        state: api.encodeParams({clientName: clickEvent.clientName})
                    }
                }/>
            );
        }
    } else {
        return (
            <div className={classes.root}>
                <div className={classes.conditionDiv}>
                    <SearchBox value={keyword}
                               onChange={changedSearchKeywordEvent}
                               doQuery={search}
                               placeholder={t("ClientPage.SearchPlaceHolder")}

                    />

                    {ds.user.isSuperManager() &&
                    <Button className={classes.button}
                            variant="contained"
                            color='primary'
                            size='large'
                            onClick={createClickEvent}>
                        <Typography className={classes.button.text}
                                    variant="button">{t("ClientPage.NewClient")}</Typography>
                    </Button>
                    }
                </div>
                <ResultTable dataArray={dataArray}
                             search={search}
                             currentPage={page}
                             totalPage={response?.page.totalPages}
                             rowClickEvent={rowClickEvent}
                />
                <CreateDialog title={'ClientPage.CreateDialog.Title'}
                              open={createOpen}
                              onClose={createClickEvent}
                              onOk={createClickEvent}
                />
            </div>
        );
    }
}

/**
 * 조회 결과 테이블
 * @param dataArray : 조회 결과 Array
 * @param search : 조회 이벤트 시 호출 할 조회 함수
 * @param rowClickEvent : Row 클릭 시 호출 할 이벤트 함수
 * @param currentPage : 현재 선택 된 페이지 번호
 * @param totalPage : 전체 페이지 개수
 * @returns {JSX.Element} : <DataTable ../>
 * @constructor
 */
function ResultTable({dataArray, search, rowClickEvent, currentPage, totalPage}) {
    const tableStyles = tableStyle();
    const {t} = useTranslation();

    const head = [
        [
            {content: ''},
            {align: 'left', content: t('ClientPage.Client')},
            {content: t('ClientPage.User')},
            {content: t('ClientPage.Auth')},
            {content: t('ClientPage.LicenseStatus')},
            {content: t('ClientPage.LicenseType')}
        ]
    ];

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={tableStyles}
                   headContent={head}
                   bodyContent={dataArray}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
        />
    );
}


function CreateDialog({open, onClose, onOk, title}) {
    const dialogStyles = dialogStyle();
    const {t} = useTranslation();
    const {ds} = useStores();
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const renderTrackVertical = () => {
        return (<div className={dialogStyles.track}/>)
    }

    const scrollThumb = () => {
        const thumbStyle = {
            height: '100vh',
            borderRadius: '5px',
            backgroundColor: ds.isDark ? '#494850b3' : '#b8bbbeb3'
        };
        return (
            <div style={thumbStyle}/>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
            //fullScreen={fullWidth}
            scroll={'paper'}
            className={dialogStyles.root}
        >
            <DialogTitle id={`${title}_dialog_id`} className={dialogStyles.title}>{t(title)}</DialogTitle>
            <Scrollbars style={{height: '100vh', paddingTop: '5rem'}}
                        autoHide
                        renderTrackVertical={renderTrackVertical}
                        renderThumbVertical={scrollThumb}>
                <DialogContent className={dialogStyles.content}>
                    <ClientCreate/>
                </DialogContent>
            </Scrollbars>
        </Dialog>
    )

}
