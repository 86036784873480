import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright';
import {useTranslation} from 'react-i18next';
import api from '../common/api';
import storage, {StorageKey as SK} from '../common/storage';
import Alert from '@material-ui/lab/Alert';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {useStores} from '../common/store';
import {useObserver} from 'mobx-react-lite';
import {Redirect} from 'react-router-dom';
import {AuthStatus, OtpType, ErrCode} from '../common/types';
import {LogoImage, LogoTitle} from '../components/Logo';
import queryString from 'query-string';
import {Switch, useMediaQuery} from "@material-ui/core";
import QrPage, {stopQrLogin} from './QrPage';
import OtpPage from './OtpPage';

const useStyles = makeStyles((theme) => ({
    bg: {
        height: '100vh',
        backgroundImage: `url(${require((theme.palette.type === 'dark') 
            ? ( theme.type === 'gccs' ? '../images/gccs/login_bg_dark.png' : '../images/bsa/login_bg_dark.png' )
            : ( theme.type === 'gccs' ? '../images/gccs/login_bg.png' : '../images/bsa/login_bg_dark.png')
        )})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        display: 'flex',
    },
    dialog: {
        alignSelf: 'center',
        backgroundColor: (theme.palette.type === 'dark' ? '#203550' : '#fff'),
        borderRadius: 15,
        paddingTop: theme.spacing(6.25),
        paddingBottom: theme.spacing(6.25),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
    },
    form: {
        marginTop: '0.875rem',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    height: {
        height: '3.5rem'
    },
    mt1p5: {
        marginTop: '1.5rem'
    },
    mt1p375: {
        marginTop: '1.375rem'
    },
    langEnable: {
        color: (theme.palette.type === 'dark' ? 'rgba(255,255,255,1)' : theme.palette.primary.main),
    },
    langDisable: {
        color: (theme.palette.type === 'dark' ? 'rgba(255,255,255,0.3)' : theme.palette.text.secondary),
    },
    label: {
        color: theme.palette.text.secondary,
    },
    loginBtn: {
        fontSize: '1rem',
        fontWeight: 'normal',
        height: '3.5rem',
        minWidth: '49%'
    },
    description: {
        fontSize: '0.9375rem',
        lineHeight: '1.5625rem',
        color: theme.palette.text.secondary
    },
    version: {
        fontSize: '0.750rem',
        lineHeight: '1.5625rem',
        marginBottom:'0.500rem',
        alignSelf:'flex-end',
        color: theme.palette.text.secondary
    },
    loginLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    progressAlert: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& > p': {
            fontSize: '0.9rem',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& > button': {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    downLinkContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > div ': {
                marginBottom: 6,
                '& > *': {
                    height: 40,
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                },

            }

        }
    },
    downLinkBox: {
        display: 'flex',
        '& > :hover': {
            border: (theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255) !important' : '1px solid rgba(0, 0, 0) !important'),
        }
    },
    notDeviceDownLinkBox: {
        '& > :first-child':{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRightColor: 'transparent'
        },
        '& > :last-child':{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            marginLeft: '-1px'
        }
    },
    downLink: {
        display: 'flex',
        alignItems: 'center',
        border: (theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255, 0.23)' : '1px solid rgba(0, 0, 0, 0.23)'),
        padding: '3px 9px',
        boxSizing: 'border-box',
        lineHeight: '1.75',
        borderRadius: '4px',
        '& > img':{
            imageRendering:'-webkit-optimize-contrast'
        }
    },
    subLoginBtnBox: {
        display: 'flex',
        marginTop: theme.spacing(2),
        flex:1,
        '& > button':{
            display:'flex',
            flex:1,
            color:'#5A95E4',
            backgroundColor: (theme.palette.type === 'dark' ? '#203550' : '#fff'),
            border:'2px solid #2E6EC6',
            boxSizing:'border-box',
            borderRadius:4,
            boxShadow:'none',

        },
        '& > button:hover' :{
            backgroundColor: (theme.palette.type === 'dark' ? '#2e6ec6' : theme.palette.primary.main),
            color:'#fff'
        },
        [theme.breakpoints.up('sm')]: {
            '& > :first-child':{marginRight:theme.spacing(1)},
            '& > :last-child':{marginLeft:theme.spacing(1)}
        }
    }
}));


const dialogStyle = makeStyles((theme) => ({
    root: {
        padding:`${theme.spacing(3)}px ${theme.spacing(2)}px`
    },
    title: {

    },
    content: {
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(1),
        '& > *' :{
            margin:theme.spacing(1)
        }
    },
}));

function AppDownLink() {
    const classes = useStyles();
    const theme = useTheme();

    const storeURL = useRef({aos:'',ios:''});

    let androidIcon = "android.png";
    let iosIcon = "ios.png";

    if (theme.palette.type === 'dark') {
        androidIcon = "android_dark.png";
        iosIcon = "ios_dark.png";
    }

    if( theme.type === 'gccs') {
        if( theme.storeType === 'vip') {
            storeURL.current.aos = 'https://play.google.com/store/apps/details?id=com.fnsvalue.guardian.authenticator';
            storeURL.current.ios = 'https://apps.apple.com/us/app/guardianccs/id1469192314';
        } else {
            storeURL.current.aos = 'https://play.google.com/store/apps/details?id=com.fnsvalue.guardian.authenticator';
            storeURL.current.ios = 'https://apps.apple.com/us/app/guardianccs/id1469192314';
        }
    } else if( theme.type === 'bsa' ){
        if( theme.storeType === 'azure') {
            storeURL.current.aos = 'https://play.google.com/store/apps/details?id=com.tm.bsa.azure';
            storeURL.current.ios = 'https://apps.apple.com/us/app/bsa-azure/id1568992310';
        } else if( theme.storeType === 'poc') {
            storeURL.current.aos = 'https://play.google.com/store/apps/details?id=com.tm.bsa';
            storeURL.current.ios = 'https://apps.apple.com/us/app/bsa-tmpoc/id1563337320';
        } else if( theme.storeType === 'prod') {
            storeURL.current.aos = 'https://play.google.com/store/apps/details?id=com.tm.bsa.clients';
            storeURL.current.ios = 'https://apps.apple.com/us/app/bsa-auth/id1581513812';
        }
    }


    const androidAppDownLoad = () => {
        return (
            <Link key={'androidAppDownLoad'} href={storeURL.current.aos} target={'_blank'} className={classes.downLink}>
                <img alt={'icon'} src={require('../images/' + androidIcon)} width={80}/>
            </Link>
            );
    }

    const iosAppDownLoad = () => {
        return (
            <Link key={'iosAppDownLoad'} href={storeURL.current.ios} target={'_blank'} className={classes.downLink}>
                <img alt={'icon'} src={require('../images/' + iosIcon)} width={80}/>
            </Link>
        );
    }

    const deviceAppDownLoad = () => {
        const device = navigator.userAgent;
        if (device.match(".*Android.*")) {
            // 안드로이드 기기
            return androidAppDownLoad();
        } else if (device.match(".*iPhone.*") || device.match(".*iPad.*")) {
            // ios 기기
            return iosAppDownLoad();
        }

        // 안드로이드, ios 제외 모든 기기
        return [androidAppDownLoad(), iosAppDownLoad()];
    }

    const deviceAppLoads = deviceAppDownLoad();

    return (
        <Box size='small' className={`${classes.downLinkBox} ${(deviceAppLoads.length == 2 ? classes.notDeviceDownLinkBox : '')}`}>
            {deviceAppLoads}
        </Box>
    );
}

function LanguageSelector() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const getStyle = (lang) => {
        if (i18n.language == lang) return classes.langEnable;
        else return classes.langDisable;
    };
    return (
        <ButtonGroup size='small'>
            <Button onClick={() => i18n.changeLanguage('en')} className={getStyle('en')}>English</Button>
            <Button onClick={() => i18n.changeLanguage('ko')} className={getStyle('ko')}>한글</Button>
        </ButtonGroup>
    );
}

function StatusAlert({status, onClose}) {
    return (
        <Alert severity="error" onClose={onClose}>{status}</Alert>
    );
}

function ProgressAlert({status}) {
    return (
        <Alert severity="info">{status}</Alert>
    );
}


function LoginForm(props) {
    const {ds} = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const [userKey, setUserKey] = React.useState('');
    const [otpUserKey, setOtpUserKey] = React.useState('');
    const [rememberMe, setRememberMe] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState(null);
    const [progressMsg, setProgressMsg] = React.useState(null);
    const [countDown, setCountDown] = useState(null);
    let fncClearInterval = useRef(null);
    const [masterClientKey, setMasterClientKey] = useState(api.MASTER_CLIENT_KEY);

    const {location} = props;
    const query = queryString.parse(location.search);
    const [accessClientName, setAccessClientName] = useState( query.clientName );
    const [qrOpen, setQrOpen] = React.useState(false);

    const qrId = React.useRef(null);
    const qrUrl = React.useRef(null);

    const [deviceAuthOpen, setDeviceOpen] = React.useState(true);
    const [otpOpen, setOtpOpen] = React.useState(false);

    const [totpOpen, setTotpOpen] = React.useState(false);
    const [smsOtpOpen, setSmsOtpOpen] = React.useState(false);
    const [mailOtpOpen, setMailOtpOpen] = React.useState(false);

    const onUserKeyChange = (event) => {
        setUserKey(event.target.value);
    };
    const onRememberMe = (event) => {
        setRememberMe(event.target.checked);
    };

    const toggleDeviceAuthOpen = () => {
        setErrMsg(null);
        setDeviceOpen(!deviceAuthOpen);
    }

    const customInterval = (time, onClose, timeoutMsg = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {
            if (id !== null) {
                clearInterval(id);
                if (isTimeout && timeoutMsg) {
                    setErrMsg(timeoutMsg);
                }
                if (onClose) {
                    onClose();
                }
                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    const onLogin = () => {

        setErrMsg(null);
        if (userKey == null || userKey.length <= 0) {
            setErrMsg(t('LoginPage.LoginNoID'));
            return;
        }

        if (rememberMe) {
            // 로그인정보를 저장한다.
            storage.setLocal(SK.LOGIN_INFO, {masterClientKey, userKey, rememberMe});
        } else {
            // 로그인정보를 삭제한다.
            storage.removeLocal(SK.LOGIN_INFO);
        }

        onLoginProcess(userKey);
    };

    const onLoginProcess = (p_userKey, isOtpAuth = false) => {

        setProgressMsg(t('AuthStatus.RequestAuth'));
        console.log("request CLick");

        api.requestAuth(masterClientKey, p_userKey, isOtpAuth)
            .then(res => {
                if(res.rtCode === ErrCode.RT_SUCCESS ){
                    const authTimeRemaining = res.data.authTimeRemaining;
                    const channelKey = res.data.channelKey;

                    // 타이머 시작
                    fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1,
                        null,
                        t('LoginPage.LoginTimeout'));

                    setProgressMsg(t('AuthStatus.CreateChannel'));
                    setProgressMsg(t('AuthStatus.SelectNodes'));

                    setProgressMsg(t('AuthStatus.CheckDevice'));

                    const ws = api.getWebSocket(masterClientKey, p_userKey, channelKey);

                    ws.onclose = (event) => {
                        console.log("ws CLOSE");
                        fncClearInterval.current();
                    };

                    ws.onmessage = (event) => {
                        console.log("ws OnMessage");
                        if (event.type === 'message') {
                            const data = JSON.parse(event.data);
                            const status = data.status;
                            const statusMsg = t('AuthStatus.' + status);

                            if (AuthStatus.isFinish(status)) {
                                ws.close();
                                setProgressMsg(null);
                                if (AuthStatus.isSuccess(status)) {
                                    if (isOtpAuth) {
                                        setOtpOpen(true);
                                    } else {
                                        api.getAuthResult(masterClientKey, p_userKey, channelKey)
                                            .then(data => {
                                                if (data.rtCode === ErrCode.RT_SUCCESS) {
                                                    api.setToken(data.data);
                                                    api.getMe()
                                                        .then(data => {
                                                            ds.setLogin(true);
                                                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                                                        })
                                                        .catch(err => {
                                                            setErrMsg(err.rtMsg);
                                                        });
                                                }
                                            })
                                            .catch(err => {
                                                setErrMsg(err.rtMsg);
                                            });
                                    }
                                } else {
                                    setErrMsg(statusMsg);
                                }
                            } else {
                                setProgressMsg(statusMsg);
                            }
                        } else {
                            setProgressMsg(t('AuthStatus.AuthFailed'));
                        }
                    };

                } else {
                    setErrMsg(t('AuthStatus.AuthFailed'));
                    setProgressMsg(null);
                }
            })
            .catch(err => {
                if (fncClearInterval.current != null) {
                    fncClearInterval.current();
                }
                setErrMsg(err.rtMsg);
                setProgressMsg(null);
            });

    }

    const onCancel = () => {
        if (fncClearInterval.current !== null) {
            fncClearInterval.current();
        }
        api.deleteAuth(masterClientKey, otpUserKey ? otpUserKey : userKey)
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    setProgressMsg(null);
                }
            }).catch(err => {
            setErrMsg(err.rtMsg);
        });

    }

    const onQrLogin = () => {
        api.getLoginQrUrl(masterClientKey)
            .then( (data) => {
                qrId.current = data.qrId;
                qrUrl.current = data.qrUrl;
                setQrOpen( true );
            } )
            .catch( (err) => {
                setErrMsg(err.rtMsg);
        } )
    }

    const onQrCancel = (isStop = false, userKey = null) => {
        setQrOpen(false);
        if( isStop ) { stopQrLogin(qrId.current, userKey, setProgressMsg, setErrMsg); }
    }

    useEffect(() => {

        if( accessClientName !== undefined ) {
            api.getAccessClientKey( accessClientName )
                .then( (data) => {
                    console.log("data => ", data);
                    setMasterClientKey(data);
                } )
                .catch( (err) => {
                    setErrMsg(err.rtMsg);
                } )
        }

        const info = storage.getLocal(SK.LOGIN_INFO);
        if (info && info.rememberMe) {
            setUserKey(info.userKey);
            setRememberMe(info.rememberMe);
        }

    }, []);

    const isError = errMsg != null;
    const isProcess = (progressMsg != null) && (!isError);

    const onErrorHandler = () => {
        setErrMsg(null);
        setProgressMsg(null);
    }

    const onOTPLogin = () => {
        if (userKey) {
            onLoginProcess(userKey, true);
        } else {
            setOtpOpen(true);
        }
    }

    const onOTPSuccess = ({otpUserKey = null, token = null}) => {
        if (otpUserKey) {
            setErrMsg(null);
            if (otpUserKey.length <= 0) {
                setErrMsg(t('LoginPage.LoginNoID'));
                return;
            }

            setOtpUserKey(otpUserKey);
            onLoginProcess(otpUserKey);
        } else if (token) {
            if (rememberMe) {
                storage.setLocal(SK.LOGIN_INFO, {masterClientKey, userKey, rememberMe});
            } else {
                storage.removeLocal(SK.LOGIN_INFO);
            }
            onOTPTokenSuccess({token});
        }
    }

    const onOTPTokenSuccess = async ({token}) => {
        api.setToken(token);
        await timeout(1000);
        api.getMe()
            .then(data => {
                ds.setLogin(true);
                storage.setSession(SK.LOGIN_USER_INFO, {data});
            })
            .catch(err => {
                setErrMsg(err.rtMsg);
            });
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const isNotPhone = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box className={classes.bg}>
            <Container className={classes.dialog} component="main" maxWidth="sm">
                <Box display='flex' justifyContent='center' alignItems='center'>
                    { theme.type === 'gccs' &&
                        <React.Fragment>
                            <LogoImage width={'auto'} height={'80'}/>
                            <LogoTitle width={163} height={24} color={theme.palette.type === 'light' ? theme.palette.primary.main : null}/>
                        </React.Fragment>
                    }
                    { theme.type !== 'gccs' &&
                        <LogoImage width={140} height={'auto'}/>
                    }
                </Box>

                <Box className={classes.form}>
                    <TextField variant="outlined"
                               margin="normal"
                               required
                               fullWidth
                               id="userKey"
                               label="User"
                               name="userKey"
                               value={userKey}
                               disabled={isProcess}
                               onChange={onUserKeyChange}
                               onKeyPress={(e) => {
                                   e.key === 'Enter' && onLogin()
                               }}
                               className={`${classes.height} ${classes.mt1p5}`}
                    />
                    {countDown !== null ?
                        <Box className={classes.loginLabel}>
                            <FormControlLabel control={<Checkbox checked={rememberMe} onChange={onRememberMe} color='primary' disabled={isProcess}/>}
                                              className={classes.label}
                                              label={t('LoginPage.LabelRememberMe')}
                            />

                            <Box className={classes.progressAlert}>
                                <Typography variant='body1' className={classes.description}>
                                    {`${t('LoginPage.TimeRemain')} : ${countDown} (${t('LoginPage.Sec')})`}
                                </Typography>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="primary"
                                    onClick={onCancel}
                                >{t('LoginPage.BtnCancel')}
                                </Button>
                            </Box>
                        </Box>
                        :
                        <FormControlLabel
                            control={<Checkbox checked={rememberMe} onChange={onRememberMe} color='primary'
                                               disabled={isProcess}/>}
                            className={classes.label}
                            label={t('LoginPage.LabelRememberMe')}
                        />
                    }

                    {deviceAuthOpen ?
                        <Box>
                            <Button
                                fullWidth
                                size='large'
                                variant="contained"
                                color="primary"
                                disabled={isProcess}
                                onClick={isProcess ? onCancel : onLogin}
                                className={classes.loginBtn}
                            >{t('LoginPage.BtnLogin')}
                            </Button>

                            <Box className={classes.subLoginBtnBox}>

                                {isNotPhone ?
                                    <Button
                                        size='large'
                                        variant="contained"
                                        disabled={isProcess}
                                        onClick={isProcess ? onQrCancel : onQrLogin}
                                    >QR
                                    </Button>
                                    : ''}

                                <QrPage open={qrOpen}
                                        onClose={onQrCancel}
                                        title={t('LoginPage.QrDialog.Title')}
                                        qrId={qrId.current}
                                        qrUrl={qrUrl.current}
                                        onProgressMsg={setProgressMsg}
                                        onErrMsg={setErrMsg}
                                />

                                <Button
                                    size='large'
                                    variant="contained"
                                    disabled={isProcess}
                                    onClick={isProcess ? () => setOtpOpen(false) : onOTPLogin}
                                >OTP
                                </Button>

                                <OtpPage otpType={OtpType.DeviceOTP}
                                         handedUserKey={userKey}
                                         open={otpOpen}
                                         onClose={() => setOtpOpen(false)}
                                         onSuccess={onOTPSuccess}
                                         numInputs={6}
                                />

                                <Button
                                    size='large'
                                    variant="contained"
                                    disabled={isProcess}
                                    onClick={isProcess ? () => setTotpOpen(false) : () => setTotpOpen(true)}
                                >TOTP
                                </Button>

                                <OtpPage otpType={OtpType.DeviceTOTP}
                                         handedUserKey={userKey}
                                         open={totpOpen}
                                         onClose={() => setTotpOpen(false)}
                                         onSuccess={onOTPTokenSuccess}
                                         numInputs={6}
                                />
                            </Box>
                        </Box>
                        :
                        <Box className={classes.subLoginBtnBox}>
                            <Button
                                size='large'
                                variant="contained"
                                disabled={isProcess}
                                onClick={isProcess ? () => setMailOtpOpen(false) : () => setMailOtpOpen(true)}
                            >E-MAIL
                            </Button>

                            <OtpPage otpType={OtpType.MailOTP}
                                     handedUserKey={userKey}
                                     open={mailOtpOpen}
                                     onClose={() => setMailOtpOpen(false)}
                                     onSuccess={onOTPTokenSuccess}
                                     numInputs={6}
                            />

                            <Button
                                size='large'
                                variant="contained"
                                disabled={isProcess}
                                onClick={isProcess ? () => setSmsOtpOpen(false) : () => setSmsOtpOpen(true)}
                            >SMS
                            </Button>

                            <OtpPage otpType={OtpType.SmsOTP}
                                     handedUserKey={userKey}
                                     open={smsOtpOpen}
                                     onClose={() => setSmsOtpOpen(false)}
                                     onSuccess={onOTPTokenSuccess}
                                     numInputs={6}
                            />
                        </Box>
                    }

                    <Box mt={3} hidden={!isError} className={classes.height}>
                        <StatusAlert status={errMsg} onClose={onErrorHandler}/>
                    </Box>

                    <Box mt={3} hidden={!isProcess} className={classes.height}>
                        <ProgressAlert status={progressMsg} count={countDown}/>
                    </Box>

                    <Box mt={3} className={classes.downLinkContainer}>
                        <AppDownLink/>
                        <LanguageSelector/>
                    </Box>


                    <Box mt={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='body1' className={classes.description}>
                            Please select client type and enter your UserID.
                            <br/>
                            Click LOGIN to continue with authentication.
                        </Typography>

                        { (theme.type === 'gccs'  && theme.storeType === 'dev') &&
                            <Box textAlign={"center"}>
                                <Switch checked={deviceAuthOpen} disabled={isProcess} onChange={toggleDeviceAuthOpen}/>
                                <Typography className={classes.description}>{deviceAuthOpen ? "Device Auth" : "No Device Auth"}</Typography>
                            </Box>
                        }

                    </Box>
                </Box>

                <Box mt={2}>
                    <Copyright/>
                </Box>

            </Container>
        </Box>
    );
}

export default function LoginPage(props) {
    const {ds} = useStores();
    return useObserver(() => {
        if (ds.isLogin) {
            const {location} = props;
            const {from} = location.state || {from: {pathname: '/'}};
            return <Redirect to={from}/>;
        } else {
            return <LoginForm {...props}/>;
        }
    });
}
