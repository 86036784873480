import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {AgreementType, CommonCodeValue, LanguageCode, UseStatusCode, SubClientBehavior, UserType, LicenseStatus} from "../../common/types";
import queryString from 'query-string';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {useStores} from "../../common/store";
import moment from "moment";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBox from "../../components/SearchBox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputTexts from "../../components/InputTexts";
import InputSelect from "../../components/InputSelect";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {useObserver} from "mobx-react-lite";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextEditor from "../../components/TextEditor";
import AccessClientSelectBox from '../../components/AccessClientSelectBox';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {TextField} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '40px 50px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
        "& > form,& > :last-child": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
        }
    },
    selectContainer: {
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& > *": {
            marginRight: theme.spacing(2),
            [theme.breakpoints.between('xs', 'md')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                marginRight: 0,
            }
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
        }
    },
    selectDiv: {
        display: 'inline-flex',
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    selectBox: {
        minWidth: 200,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0,
        }
    },
    searchBoxDiv: {
        display: 'flex',
    },
    searchBox: {
        display: 'flex',
        minWidth: '20rem',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    searchText: {
        width: '100%',
    },
    button: {
        minWidth: 140,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    inputBox: {
        minWidth: 200,
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0
        }
    },

}));

const dialogStyle = makeStyles((theme) => ({
    root: {},
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    content: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    infoContainer: {
        display: 'flex',
        '& > dl': {
            display: 'flex',
            flex: 1,
            '& > *': {
                flex: 1,
                textAlign: 'center',
            }
        }
    },
    selectContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            minWidth: 320,
            flex: 1,
            marginBottom: theme.spacing(2),
            [theme.breakpoints.between('xs', 'md')]: {},
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                minWidth: 160,
            }
        },
        '& > div:first-child': {marginRight: theme.spacing(1)},
        '& > div:nth-child(2)': {marginLeft: theme.spacing(1)},
        [theme.breakpoints.between('xs', 'sm')]: {
            flex: 1,
            flexDirection: 'column',
            marginBottom: 0,
            '& > div:first-child': {marginRight: 0},
            '& > div:nth-child(2)': {marginLeft: 0},
        }
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            marginBottom: theme.spacing(2),
        },
        '& > div:first-child': {
            minWidth: 650,
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth: 'initial',
                flex: 1,
            }
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',

        '& > *': {
            margin: theme.spacing(1)
        }
    }

}));

const tableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60
        },
        '& > :nth-child(2)': {
            minWidth: 130,
        },
        '& > :nth-child(3)': {
            minWidth: 130,
        },
        '& > :nth-child(4)': {
            minWidth: 130,
        },
        '& > :nth-child(5)': {
            minWidth: 130
        },
        '& > :nth-child(6)': {
            minWidth: 130,
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : 'white',
        backgroundColor: theme.palette.type === 'dark' ? '#0d1015' : '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.type === 'dark' ? '#29303a' : '#f8f8f8'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.type === 'dark' ? '#323942' : '#e7e7e7'
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));


export default function LicenseListPage(props) {
    const pageSize = 5;
    const {location} = props;
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();

    const query = queryString.parse(location.search);
    let client = query['client'] !== undefined ? query['client'] : null;
    let vendor = query['vendor'] !== undefined ? query['vendor'] : null;
    let status = query['status'] !== undefined ? query['status'] : null;

    const searchParams = useRef(
        {
            client: client,
            vendor: vendor,
            status: status
        }
    );

    const [page, setPage] = React.useState(1);
    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);

    /********************** USE STATE EVENT **********************/

    const onChangeSelect = (type, value) => {
        if (type === 'client') {
            searchParams.current.client = value;
        } else if (type === 'vendor') {
            searchParams.current.vendor = value;
        } else if (type === 'status') {
            searchParams.current.status = value;
        }
        search(null, 1);
    }
    const createClickEvent = () => {
        setCreateOpen(prevState => !prevState);
    }

    /********************** USE STATE EVENT **********************/

    const rowClickEvent = (row) => (event) => {
        setEvent({clientName: row[1].content, seq: row[6]?.content, clientKey: row[7].content});
    }

    const search = (event, page = 1) => {
        const query = {
            client: searchParams.current.client,
            vendor: searchParams.current.vendor,
            status: searchParams.current.status,
            page: page
        };
        setPage(page);
        fetchData(query, pageSize);
    }

    const fetchData = (query) => {
        const client = query.client !== 'ALL' ? query.client : null;
        const vendor = query.vendor !== 'ALL' ? query.vendor : null;
        const status = query.status !== 'ALL' ? query.status : null;

        api.getLicensePage(client, vendor, status, query.page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData({client, vendor, status, page});
    }, []);


    if (event != null) {
        return (
            <Redirect push to={{
                pathname: '/admin/license/detail',
                search: "?" + api.encodeParams({seq: event.seq, clientKey: event.clientKey}),
                state: api.encodeParams({clientName: event.clientName})
            }}/>
        );
    }
    return (
        <div className={classes.root}>
            <ConditionPanel searchParams={searchParams}
                            search={search}
                            onChangeSelect={onChangeSelect}
                            onClickCreateEvent={createClickEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={page}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
            <CreateDialog title={t('LicensePage.AddDialog.Title')}
                          open={createOpen}
                          onClose={createClickEvent}
                          onOk={createClickEvent}
                          search={search}
            />
        </div>
    );
}

function ConditionPanel({searchParams, search, onChangeSelect, changedSearchKeywordEvent, onClickCreateEvent}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();

    const statusArray = [];
    if (statusArray.length === 0) {
        Object.keys(LicenseStatus)
            .map((key, index) => {
                statusArray[index] = {key: key, value: LicenseStatus[key], text: t(CommonCodeValue.translateKey + '.' + LicenseStatus[key])};
            })
    }

    const clientArray = [];
    const vendorArray = [];
    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clientArray.length === 0) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientArray[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }

        if (vendorArray.length === 0) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isSuperManager( ds.user.accessibleClient[key].userType ) || UserType.isVendorManager( ds.user.accessibleClient[key].userType ) ) {
                    vendorArray[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }

    const onChangeClient = (event) => {
        if (onChangeSelect) {
            onChangeSelect('client', event.target.value);
        }
    }
    const onChangeVendor = (event) => {
        if (onChangeSelect) {
            onChangeSelect('vendor', event.target.value);
        }
    }

    const onChangeStatus = (event) => {
        if (onChangeSelect) {
            onChangeSelect('status', event.target.value);
        }
    }


    return (
        <div className={classes.conditionDiv}>
            <div className={classes.selectContainer}>
                <SelectBox className={classes.inputBox}
                           title={t('LicensePage.ClientBox')}
                           defaultValue={'ALL'}
                           value={searchParams.current.client}
                           contents={clientArray}
                           onChange={onChangeClient}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('LicensePage.VendorBox')}
                           defaultValue={'ALL'}
                           value={searchParams.current.lang}
                           contents={vendorArray}
                           onChange={onChangeVendor}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('LicensePage.StatusBox')}
                           defaultValue={'ALL'}
                           value={searchParams.current.lang}
                           contents={statusArray}
                           onChange={onChangeStatus}
                           addAll={true}
                />
            </div>

            <Button className={classes.button}
                    variant="contained"
                    color='primary'
                    size='large'
                    onClick={onClickCreateEvent}>
                <Typography className={classes.button.text} variant="button">{t('LicensePage.AddButton')}</Typography>
            </Button>
        </div>
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {
    const tableStyles = tableStyle();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: 'No'},
            {content: t('LicensePage.Header.Client')},
            {content: t('LicensePage.Header.Vendor')},
            {content: t('LicensePage.Header.Status')},
            {content: t('LicensePage.Header.ValidityPeriod')},
            {content: t('LicensePage.Header.RegDate')}
        ]
    ];

    if (response !== null) {
        let num = response.page.totalElements - (response.page.currentPage * pageSize);

        response.data.map((data, idx) => {
            // let title = data.title ? data.title : '-';
            let clientName = data.clientName ? data.clientName : '-';
            let vendorClientName = data.vendorClientName ? data.vendorClientName : '-';
            let status = data.status ? t(CommonCodeValue.translateKey + '.' + data.status) : '-';
            let startDt = data.startDt ? _dataFormat(data.startDt, 'date', 'YYYY-MM-DD') : '-';
            let endDt = data.endDt ? _dataFormat(data.endDt, 'date', 'YYYY-MM-DD') : '-';
            let regDt = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm') : '-';

            body[idx] = [
                {id: 'index', content: num--},
                {id: 'client', content: clientName},
                {id: 'vendor', content: vendorClientName},
                {id: 'status', content: status},
                {id: 'validityDate', content: startDt + ' ~ ' + endDt},
                {id: 'regDate', content: regDt},
                {id: 'seq', content: data.seq},
                {id: 'clientKey', content: data.clientKey}
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={tableStyles}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}


function SelectBox({className, title, contents, defaultValue, value, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                defaultValue={defaultValue}
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={title}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}

function CreateDialog({open, onClose, onOk, title, search}) {
    const dialogStyles = dialogStyle();
    const {t} = useTranslation();
    const {ds} = useStores();

    const [openConfirm, setOpenConfirm] = useState(false);

    const licenseKey = useRef();
    const cloneLicenseKey = useRef();

    const onChangeLicenseKey = (event, value) => {
        licenseKey.current = value;
    }

    const saveCancelClick = () => {
        licenseKey.current = _.clone(cloneLicenseKey.current);
        onClose();
    }

    const formId = "agreementForm";
    const form = new MobxReactForm({
        fields: [
            {
                name: 'content',
                value: licenseKey.current,
                rules: 'required',
                label: t('LicensePage.AddDialog.LicenseKey'),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                const values = form.values();
                if (values['content'] !== null && values['content'] !== "") {
                    licenseKey.current = values['content'];
                }
                setOpenConfirm(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map((data, index) => {
                    if (errorMsg === "") {
                        errorMsg = form.errors()[data];
                        if (errorMsg === null) {
                            errorMsg = "";
                        }
                    }
                });
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            }
        }
    });

    const onClickBtn = (isYes) => {
        if (isYes) {
            api.postLicense({licenseKey: licenseKey.current, comment: ''})
                .then((data) => {
                    onClose(true);
                    search();
                    ds.showInfoAlert(t('LicensePage.RegistrationSuccess'));
                })
                .catch(err => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                });
        }
        setOpenConfirm(false);
    }

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
            scroll={'paper'}
            className={dialogStyles.dialog}
        >
            <DialogTitle id={`${title}_dialog_id`} className={dialogStyles.title}>{t(title)}</DialogTitle>
            <DialogContent className={dialogStyles.content}>
                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                    <div className={dialogStyles.bodyContainer}>
                        <div>
                            <InputTexts name={'content'}
                                        form={form}
                                        label={t('LicensePage.AddDialog.LicenseKey')}
                                        rows={10}
                                        variant={'outlined'}
                            />
                        </div>
                    </div>
                </form>
                <div className={dialogStyles.buttonContainer}>
                    <Button variant='contained'
                            size='large'
                            color='primary'
                            onClick={form.onSubmit}
                    >
                        {t('LicensePage.AddDialog.Save')}
                    </Button>
                    <Button variant="contained"
                            type={'button'} form={formId}
                            className={dialogStyles.grayButton}
                            onClick={saveCancelClick}
                    >
                        {t('LicensePage.AddDialog.Cancel')}
                    </Button>
                </div>
            </DialogContent>
            <ConfirmDialog
                open={openConfirm}
                onClick={onClickBtn}
                title={t('LicensePage.AddDialog.ConfirmTitle')}
                content={t('LicensePage.AddDialog.ConfirmContent')}/>
        </Dialog>
    ));
}

function ConfirmDialog({title, content, open, onClick}) {
    const {t} = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClick(true)} color="primary" autoFocus>
                    {t('LicensePage.AddDialog.ConfirmYes')}
                </Button>
                <Button onClick={() => onClick(false)}>
                    {t('LicensePage.AddDialog.ConfirmNo')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
