import React, {useEffect, useState, useRef} from 'react';
import OtpInput from 'react-otp-input';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import api from "../common/api";
import {OtpType, ErrCode, UserVerifyType, CountryTelNum} from "../common/types";
import InputTexts from "../components/InputTexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

const dialogStyle = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    },
    title: {},
    height: {
        height: '3.5rem'
    },
    label: {
        color: theme.palette.text.secondary,
    },
    content: {
        minWidth: 450,
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(1),
        '& > *': {
            margin: theme.spacing(1),
            justifyContent: 'center'
        }
    },
    userInfoForm: {
        '& > *': {
            display: "flex",
            width: '-webkit-fill-available',
            margin: theme.spacing(1)
        }
    },
    countryNumField: {
        marginRight: theme.spacing(1),
        height: '-webkit-fill-available'
    },
    submitBtn: {
        fontSize: '1rem',
        fontWeight: 'normal',
        height: '3.5rem',
        minWidth: '49%'
    },
    otpInput: {
        '& > input': {
            width: `${theme.spacing(5)}px !important`,
            height: theme.spacing(5),
            margin: theme.spacing(1),
            backgroundColor: 'transparent',
            border: theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255, 0.23)' : '1px solid rgba(34, 34, 34, 0.23)',
            borderRadius: theme.spacing(1),
            color: theme.palette.type === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(34,34, 34)',
        },
        '& > input:focus-visible': {
            outline: 'none',
            border: theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255)' : '1px solid rgba(34, 34, 34)'
        }
    },
    otpError: {
        border: '1px solid #f44336 !important',
    },
    errMsg: {
        color: '#f44336',
        fontWeight: "bold"
    },
    progressMsg: {
        color: '#2e6ec6',
        fontWeight: "bold"
    }
}));

function StatusAlert({status, onClose}) {
    return (
        <Alert severity="error" onClose={onClose}>{status}</Alert>
    );
}

function ProgressAlert({status}) {
    return (
        <Alert severity="info">{status}</Alert>
    );
}

function convertInternationalNum(countryNum, phoneNum) {
    let convertNum = countryNum;
    if (phoneNum[0] === '0') convertNum += phoneNum.substr(1, phoneNum.length);
    else convertNum += phoneNum;
    return convertNum;
}

export default function OtpPage({otpType, handedUserKey = null, open, onClose, onSuccess, numInputs}) {
    const dialogStyles = dialogStyle();
    const {t} = useTranslation();
    const masterClientKey = api.MASTER_CLIENT_KEY;

    const [otp, setOtp] = useState(null);
    const [otpErr, setOtpErr] = React.useState(false);
    const [totpUserKey, setTotpUserKey] = useState(null);
    const otpSeq = React.useRef(null);

    const [errMsg, setErrMsg] = React.useState(null);
    const [progressMsg, setProgressMsg] = React.useState(null);

    const [countryNum, setCountryNum] = React.useState(CountryTelNum.SouthKorea);
    const userInfo = useRef(
        {
            userKey: handedUserKey,
            name: "",
            mail: "",
            phone: "",
        }
    );

    const isError = errMsg != null;
    const isProcess = (progressMsg != null) && (!isError);

    const onCountryNumChange = (event) => {
        setCountryNum(event.target.value);
    }

    const onErrorHandler = () => {
        setOtpErr(false);
        setErrMsg(null);
        setProgressMsg(null);
    }

    const setValue = (target) => {
        setOtp(target);
        if (target) {
            setOtpErr(false);
        }
    }

    const onTotpUserKeyChange = (event) => {
        setTotpUserKey(event.target.value);
    };

    const onSendOTP = (e) => {
        e.preventDefault();

        if (OtpType.isDeviceOTP(otpType)) return;

        let verifyType, verifyData;
        if (otpType === OtpType.MailOTP) {
            verifyType = UserVerifyType.Email;
            verifyData = userInfo.current.mail;
        } else if (otpType === OtpType.SmsOTP) {
            verifyType = UserVerifyType.PhoneNum;
            verifyData = convertInternationalNum(countryNum, userInfo.current.phone);
        }

        api.sendOtp(userInfo.current.userKey, userInfo.current.name, masterClientKey, verifyType, verifyData)
            .then(res => {
                console.log("res => ", res);
                if (res.rtCode === ErrCode.RT_SUCCESS) {
                    setOtpErr(false);
                    setErrMsg(null);
                    setProgressMsg(t('OtpPage.SendSuccess'));
                    otpSeq.current = res.data.seq;
                }
            })
            .catch(err => {
                setErrMsg(err.rtMsg);
            });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (otpType === OtpType.DeviceTOTP && !totpUserKey) {
            setErrMsg(t('OtpPage.NoUserKey'));
            return;
        }

        if (!otp || otp.length != numInputs) {
            setOtpErr(true);
            setErrMsg(t('OtpPage.NoOtp'));
            return;
        }

        if (otpType === OtpType.DeviceOTP) {
            // OTP 검증
            if (handedUserKey) {
                api.postOtpUserVerify(masterClientKey, handedUserKey, otp)
                    .then(res => {
                        if (res.rtCode === ErrCode.RT_SUCCESS) {
                            setOtpErr(false);
                            setErrMsg(null);
                            setProgressMsg(t('AuthStatus.AuthCompleted'));
                            onSuccess({token: res.data});
                        }
                    })
                    .catch(err => {
                        setOtpErr(true);
                        setErrMsg(err.rtMsg);
                    });
            } else {
                api.getOtpVerify(masterClientKey, otp)
                    .then(res => {
                        console.log("res => ", res);
                        if (res.rtCode === ErrCode.RT_SUCCESS) {
                            onSuccess({otpUserKey: res.data});
                            onClose();
                        }
                    })
                    .catch(err => {
                        setOtpErr(true);
                        setErrMsg(err.rtMsg);
                    });
            }
        } else if (otpType === OtpType.DeviceTOTP) {
            // TOTP 검증
            api.postTotpUserVerify(masterClientKey, totpUserKey, otp)
                .then(res => {
                    if (res.rtCode === ErrCode.RT_SUCCESS) {
                        setOtpErr(false);
                        setErrMsg(null);
                        setProgressMsg(t('AuthStatus.AuthCompleted'));
                        onSuccess({token: res.data});
                    }
                })
                .catch(err => {
                    setOtpErr(true);
                    setErrMsg(err.rtMsg);
                });
        } else {
            if (!otpSeq.current) {
                setErrMsg(t('OtpPage.NoDeviceMessage'));
                return;
            }

            if (otpType === OtpType.MailOTP) {
                // MAIL OTP 검증
                api.postMailOtpUserVerify(masterClientKey, userInfo.current.userKey, Number(otpSeq.current), userInfo.current.mail, otp)
                    .then(res => {
                        if (res.rtCode === ErrCode.RT_SUCCESS) {
                            setOtpErr(false);
                            setErrMsg(null);
                            setProgressMsg(t('AuthStatus.AuthCompleted'));
                            onSuccess({token: res.data});
                        }
                    })
                    .catch(err => {
                        setOtpErr(true);
                        setErrMsg(err.rtMsg);
                    });
            } else if (otpType === OtpType.SmsOTP) {
                // SMS OTP 검증
                api.postSmsOtpUserVerify(masterClientKey, userInfo.current.userKey, Number(otpSeq.current), convertInternationalNum(countryNum, userInfo.current.phone), otp)
                    .then(res => {
                        if (res.rtCode === ErrCode.RT_SUCCESS) {
                            setOtpErr(false);
                            setErrMsg(null);
                            setProgressMsg(t('AuthStatus.AuthCompleted'));
                            onSuccess({token: res.data});
                        }
                    })
                    .catch(err => {
                        setOtpErr(true);
                        setErrMsg(err.rtMsg);
                    });
            }
        }
    }

    useEffect(() => {
        if (open) {
            setTotpUserKey(handedUserKey);
            userInfo.current.userKey = handedUserKey;
        } else {
            setOtp(null);
            setOtpErr(false);
            setErrMsg(null);
            setProgressMsg(null);
        }
    }, [open])

    const form = new MobxReactForm({
        fields: [
            {name: 'userKey', value: userInfo.current.userKey, rules: 'required', label: t('OtpPage.UserInfo.UserKey')},
            {
                name: 'name',
                value: userInfo.current.name,
                rules: OtpType.isNotDeviceOTP(otpType) && 'required',
                label: t('OtpPage.UserInfo.Name')
            },
            {
                name: 'mail',
                value: userInfo.current.mail,
                rules: otpType === OtpType.MailOTP && 'required|email',
                label: t('OtpPage.UserInfo.Mail')
            },
            {
                name: 'phone',
                value: userInfo.current.phone,
                rules: otpType === OtpType.SmsOTP && 'required',
                label: t('OtpPage.UserInfo.Phone')
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                const values = form.values();
                Object.keys(values).map((key, index) => {
                    if (values[key] !== null && values[key] !== "") {
                        userInfo.current[key] = values[key];
                    }
                });
                onSendOTP(event);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map((data, index) => {
                    if (errorMsg === "") {
                        errorMsg = form.errors()[data];
                        if (errorMsg === null) {
                            errorMsg = "";
                        }
                    }
                });
                console.log("onError", form.errors());
            }
        }
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`OTP dialog`}
            className={dialogStyles.root}
            maxWidth='md'
        >
            <DialogTitle id={`OTP_dialog_id`} className={dialogStyles.title}>
                {otpType === OtpType.DeviceTOTP ? t('OtpPage.Title.Totp') :
                    otpType === OtpType.MailOTP ? t('OtpPage.Title.Email') :
                        otpType === OtpType.SmsOTP ? t('OtpPage.Title.Sms') :
                            t('OtpPage.Title.Basic')
                }
            </DialogTitle>
            <form>
                <DialogContent className={dialogStyles.content}>
                    {otpType === OtpType.DeviceTOTP &&
                    <Box className={dialogStyles.userInfoForm}>
                        <TextField variant="outlined"
                                   required
                                   name="userKey"
                                   label="User"
                                   value={totpUserKey}
                                   disabled={isProcess}
                                   onChange={onTotpUserKeyChange}
                                   onKeyPress={(e) => {
                                       e.key === 'Enter' && onSubmit(e)
                                   }}
                        />
                    </Box>
                    }
                    {OtpType.isNotDeviceOTP(otpType) &&
                    <form className={dialogStyles.userInfoForm} onSubmit={form.onSubmit}>
                        <InputTexts
                            form={form}
                            name={'userKey'}
                            label={t('OtpPage.UserInfo.UserKey')}
                            value={handedUserKey}
                            variant={'outlined'}
                            disabled={isProcess}
                        />

                        <InputTexts
                            form={form}
                            name={'name'}
                            label={t('OtpPage.UserInfo.Name')}
                            value={userInfo.current.name}
                            variant={'outlined'}
                            disabled={isProcess}
                        />

                        {otpType === OtpType.MailOTP ?
                            <InputTexts
                                form={form}
                                name={'mail'}
                                label={t('OtpPage.UserInfo.Mail')}
                                value={userInfo.current.mail}
                                variant={'outlined'}
                                disabled={isProcess}
                            />
                            : otpType === OtpType.SmsOTP ?
                                <Box>
                                    <Select variant="outlined"
                                            required
                                            disabled={isProcess}
                                            value={countryNum}
                                            onChange={onCountryNumChange}
                                            className={dialogStyles.countryNumField}
                                    >
                                        {
                                            CountryTelNum.getArray().map((content, idx) => <MenuItem key={idx} value={content}>+ {content}</MenuItem>)
                                        }
                                    </Select>
                                    <InputTexts
                                        form={form}
                                        name={'phone'}
                                        label={t('OtpPage.UserInfo.Phone')}
                                        value={userInfo.current.phone}
                                        variant={'outlined'}
                                        disabled={isProcess}
                                    />
                                </Box>
                                : null}

                        <Button
                            fullWidth
                            size='large'
                            variant="contained"
                            color="primary"
                            disabled={isProcess}
                            onClick={form.onSubmit}
                            className={dialogStyles.submitBtn}
                        >{t('OtpPage.SendBtn')}
                        </Button>
                    </form>
                    }

                    {OtpType.isDeviceOTP(otpType) && <Typography variant={'subtitle1'}>{t('OtpPage.Message')}</Typography>}
                    <OtpInput
                        className={dialogStyles.otpInput}
                        value={otp}
                        onChange={setValue}
                        numInputs={numInputs}
                        separator={<span>-</span>}
                        isInputNum={true}
                        hasErrored={otpErr}
                        errorStyle={dialogStyles.otpError}
                        shouldAutoFocus={true}
                    />

                    <Box mt={3} hidden={!isError} className={dialogStyles.height}>
                        <StatusAlert status={errMsg} onClose={onErrorHandler}/>
                    </Box>
                    <Box mt={3} hidden={!isProcess} className={dialogStyles.height}>
                        <ProgressAlert status={progressMsg}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type={"submit"} onClick={onSubmit} color='primary'>{t("Agreement.Detail.OkBtn")}</Button>
                    <Button onClick={onClose}>{t("Agreement.Detail.CancelBtn")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}
