import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Copyright from "./Copyright";
import {LogoGifImage, LogoImage} from "./Logo";
import {ListSubheader} from "@material-ui/core";
import {useHistory, useLocation} from 'react-router';
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useStores} from "../common/store";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '16.25rem',
        height: '10.625rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    root: {
        position: 'relative',
        height: '100%',
        backgroundColor: theme.palette.background.sideMenu,
    },
    group: {
        paddingBottom: 0,
        marginBottom: 10,
        marginTop: 10
    },
    subHeader: {
        // backgroundColor: '#263b58',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 23,
        height: '3rem',
        ...theme.typography.subtitle1,
        color: theme.palette.text.secondary,

    },
    subIcon: {
        minWidth: 22,
        marginRight: 10,
    },
    menuItem: {
        paddingLeft: 52,
        display: 'flex',
        alignItems: 'center',
        height: '2.5rem',
        color: theme.palette.text.secondary,
    },
    dot: {
        display: 'none',
        width: 3,
        height: 3,
        borderRadius: 1.5,
        backgroundColor: theme.palette.text.disabled,
    },
    menuText: {
        marginLeft: 9,
        "& > span": {
            ...theme.typography.body2
        }
    },
    selectedHeader: {
        color: theme.palette.text.primary
    },
    selectedMenu: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "& span": {
            fontWeight: 600,
        },
        '&.MuiListItem-button:hover':{
            color: (theme.palette.type === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary),
        }
    },
    copyright: {
        position: 'absolute',
        bottom: '50px',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.between('xs', 'xs')]: {
            position: 'static',
            margin: theme.spacing(3),
        }
    },
    title: {
        width: '10rem'
    }
}));

function Logo() {
    const classes = useStyles();
    return (
        <div className={classes.logo}>
            <LogoGifImage width={260} height={170}/>
        </div>

    );
}

function GroupItem({group, openItem}) {
    const {ds} = useStores();
    const classes = useStyles();
    const {pathname} = useLocation();

    let selectedIdx = -1;
    group.items.forEach((item, idx) => {
        if (_.isArray(item.url)) {
            if (_.includes(item.url, pathname)) {
                selectedIdx = idx;
            }
        }

        if (pathname === item.url) {
            if (item.hidden === true) {
                selectedIdx = item.menuIndex;
            } else {
                selectedIdx = idx;
            }
        }
    })




    const header = (group.menuAuth?<HeaderItem label={group.label} icon={ds.isDark ? group.iconImg :group.iconImgBlack} selected={selectedIdx !== -1}/>:null);
    return (
        group.menuAuth &&
        <React.Fragment>
            <List subheader={header} component="nav" className={classes.group}>
                {
                    group.items.map((item, idx) =>
                        !item.hidden
                            ? (item.menuAuth?<MenuItem key={idx.toString()} item={item} selected={idx === selectedIdx} openItem={openItem}/>:null)
                            : null
                    )
                }
            </List>
            <Divider/>
        </React.Fragment>
    );


}

function HeaderItem({icon, label, selected}) {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <ListSubheader disableSticky component="div" className={`${classes.subHeader} ${selected ? classes.selectedHeader : null}`}>
            <ListItemIcon className={classes.subIcon}>
                <img alt={'icon-' + label} src={require('../images/' + icon)} width={22} height={22}/>
            </ListItemIcon>
            <div className={classes.title}>
                {t("Menu." + label)}
            </div>
            <ExpandMoreIcon/>
        </ListSubheader>
    )
}

function MenuItem({item, selected, openItem}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {handleClick} = openItem

    const onClick = (e) => {
        if (handleClick) {
            handleClick(e, openItem);
        }
        if (Array.isArray(item.url)) {
            history.push(item.url[0]);
        } else {
            history.push(item.url);
        }
    };


    return (
        <ListItem button disableGutters className={`${classes.menuItem} ${selected ? classes.selectedMenu : null}`}
                  onClick={onClick}>
            <ListItemText className={classes.menuText} primary={t("Menu." + item.label)}/>
        </ListItem>
    );
}

export default function SideMenu({item, openItem}) {

    const classes = useStyles();
    return (

        <div className={classes.root}>
            <Logo/>
            <Divider/>
            {
                item.map((group, idx) =>
                    <React.Fragment key={idx.toString()}>
                        <GroupItem group={group} openItem={openItem}/>
                    </React.Fragment>
                )
            }
            <div className={classes.copyright}>
                <Copyright/>
            </div>
        </div>
    )
}