import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useStores} from '../common/store';
import {useObserver} from 'mobx-react-lite';
import PersonOutline from '@material-ui/icons/PersonOutline';
import LanguageIcon from '@material-ui/icons/Language';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import {CommonCodeValue} from '../common/types';
import validatorjs from "validatorjs";
import Divider from "@material-ui/core/Divider";
import {useMediaQuery} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import storage, {StorageKey as SK} from '../common/storage';
import {useHistory} from 'react-router';
import api from "../common/api";

const useStyles = makeStyles((theme) => ({
    header:{
        position:'fixed',
        width:'calc(100% - 260px)',
        zIndex:2,
        [theme.breakpoints.between('xs', 'md')]: {
            width:'100%',
        },
    },
    root: {
        display: 'flex',
        height: '5rem',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (theme.palette.type === "dark") ? '#212832' : '#2452a6',
        [theme.breakpoints.between('xs', 'md')]: {
            height: '3.5rem',
        }
    },
    root2: {
        display: 'flex',
        // maxWidth: 1440,
        width: '100%',
        [theme.breakpoints.between('xs', 'md')]: {
            paddingLeft: '0.313rem',
            paddingRight: '0.313rem',
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            paddingLeft: '3.125rem',
            paddingRight: '3.125rem',
        }
    },
    root3: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
    },
    menuBtn: {
        color: theme.palette.text.white,
        [theme.breakpoints.between('lg', 'xl')]: {
            display: 'none'
        }
    },
    userInfo: {
        minWidth: 200,
        display: 'flex',
        alignItems: 'center',
        // color: (theme.palette.type === "dark") ? theme.palette.text.secondary : theme.palette.primary.contrastText,
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.between('xs', 'md')]: {
            cursor:'pointer',
            padding:12,
            minWidth:'auto',
            '& > p':{
                display:'none'
            }
        }
    },
    logoutBox:{
        display:'flex',
        alignItems:'center',
        color:'#bdbdbd',
        cursor:'pointer',
        marginLeft:'1.3rem',
        [theme.breakpoints.between('xs', 'md')]: {
            display:'none',
            marginLeft:'0',
        }
    },
    langSelector: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4rem',
        marginRight: '4rem',
        '& .MuiOutlinedInput-notchedOutline':{
            borderColor:'#fff',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':{
            borderColor:'hsl(0 0% 100% / 0.5)',
        },
        '& .MuiSelect-icon':{
            color:'#fff',
        }
    },
    langSelect: {
        minWidth: 100,
        color: theme.palette.text.white,
        '& > div': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    },
    langMobSelector:{
        marginLeft:theme.spacing(2),
        '& > .MuiInput-underline:before, & > .MuiInput-underline:after, & > .MuiInput-underline:hover:not(.Mui-disabled):before':{
            border:'none',
        },
        '& .MuiSelect-root.MuiSelect-select':{
            borderRadius:theme.spacing(6)
        }
    },
    langMobSelect:{
        '& .MuiSelect-select.MuiSelect-select': {
            padding:'12px',
        },
        '& .MuiSvgIcon-root.MuiSelect-icon':{
            display:'none',
        }
    },
    themeSelect: {
        color: theme.palette.text.white,
    },
    userPop: {
        '& .MuiPopover-paper': {
            backgroundColor: theme.palette.background.sideMenu,
        }
    },
}));


function MenuButton({openItem}) {

    const classes = useStyles();

    const {handleClick} = openItem

    function onClick(e) {
        if (handleClick) {
            handleClick(e, openItem);
        }
    }

    return (

        <IconButton onClick={onClick} className={classes.menuBtn}>
            <MenuIcon/>
        </IconButton>

    );

}

function UserInfo() {
    const classes = useStyles();
    const {ds} = useStores();
    const {name, userType} = ds.user;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const {t} = useTranslation();

    const userTypeName =CommonCodeValue.getText(userType);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let personStyles = "";

    if( isMobile ) {
        personStyles = {fontSize: 24};
    } else {
        personStyles = {fontSize: 24, marginRight: 13};
    }

    const userInfoPop = (event) => {
        if(isMobile) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const userInfoPopId = "user-info-popover";

    const logoutHandler = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload(false);
    }

    return useObserver(() => (
        <React.Fragment>
            <div className={classes.userInfo} onClick={userInfoPop} aria-describedby={userInfoPopId}>
                <PersonOutline style={personStyles}/>
                <Typography variant="body1">
                    {name} ({userTypeName})
                </Typography>
            </div>
            <div className={classes.logoutBox} onClick={logoutHandler}>
                <ExitToAppIcon />
                <Typography variant="body1">
                    {t('Menu.Logout')}
                </Typography>
            </div>
            <Popover
                id={userInfoPopId}
                className={classes.userPop}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List component="nav" aria-label="member logout">
                    { isMobile ? (
                        <ListItem >
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary={`${name} (${userTypeName})`} />
                        </ListItem>
                    ):null}
                    <ListItem button onClick={logoutHandler}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Menu.Logout')} />
                    </ListItem>
                </List>
            </Popover>
        </React.Fragment>
    ));
}

function LanguageSelector() {
    const classes = useStyles();
    const {i18n} = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const onChange = (evt) => {
        i18n.changeLanguage(evt.target.value);
        validatorjs.useLang(evt.target.value);
    };
    const onRender = (val) => {
        let title = '';
        switch (val) {
            case 'ko':
                title = '한국어';
                break;
            case 'en':
                title = 'English';
                break;
        }

        return (
            <div style={{display: 'flex', minWidth: 100}}>
                <LanguageIcon/>
                <Typography style={{marginLeft: '1rem', width: '100%'}} display='block'
                            align='center'>{title}</Typography>
            </div>
        )
    };

    const onMobileRender = (val) => {
        return (
            <div style={{display: 'flex'}}>
                <LanguageIcon style={{width:'24px',height:'24px',color: theme.palette.text.white}} />
            </div>
        )
    };

    useEffect(() => {
        validatorjs.useLang(i18n.language ? i18n.language : 'en');
    }, [])

    if( isMobile ) {
        return (
            <div className={classes.langMobSelector}>
                <Select className={classes.langMobSelect}
                    value={i18n.language}
                    onChange={onChange}
                    renderValue={onMobileRender}
                >
                    <MenuItem value="ko">한국어</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                </Select>
            </div>
        )
    } else {
        return (
            <div className={classes.langSelector}>
                <Select
                    className={classes.langSelect}
                    value={i18n.language}
                    onChange={onChange}
                    variant='outlined'
                    renderValue={onRender}
                >
                    <MenuItem value="ko">한국어</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                </Select>
            </div>
        )
    }
}

export default function TopBar({openItem}) {

    const classes = useStyles();
    const {ds} = useStores();

    return (
        <div className={classes.header}>
            <div className={classes.root}>
                <div className={classes.root2}>
                    <MenuButton openItem={openItem} className={classes.menuBtn}/>
                    <div className={classes.root3}>
                        <UserInfo/>
                        <LanguageSelector/>
                        <IconButton onClick={ds.setToggleTheme} className={classes.themeSelect}>
                            <Brightness6Icon/>
                        </IconButton>
                    </div>
                </div>
            </div>
            <Divider/>
        </div>

);
}
