import React, {useState} from 'react';
import TabPanels from "../../components/TabPanels";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import LogsPage from "./LogsPage";
import UserManagementPage from "./UserManagementPage";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        padding: theme.spacing(6),
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        }
    },
}));

export default function SettingsPage() {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);

    const items = [
        {label: t('UserManagement.TabTitle')},
        {label: t('Logs.TabTitle')},
    ];

    const onTabChange = (selectedIndex) => {
        setTabIndex(selectedIndex);
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} tabItems={[]} changeHandler={onTabChange} useBack={false} />
            <Box className={classes.tabContent}>
                {switchTab(tabIndex)}
            </Box>
        </React.Fragment>
    );


    function switchTab(index) {
        switch (index) {
            case 0 :
                return <UserManagementPage/>
            case 1 :
                return <LogsPage/>
            default:
                return null
        }
    }
}
