import React, {useEffect, useRef, useState} from 'react';
import LoginPage from './pages/LoginPage';
import api from './common/api';
import AuthRoute from './components/AuthRoute';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import store, {useStores} from './common/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'mobx-react-lite/batchingForReactDom';
import Layout from './pages/Layout';
import {ThemeProvider} from "@material-ui/styles";
import {useObserver} from "mobx-react-lite";
import {SnackbarProvider} from 'notistack';
import {Scrollbars} from 'react-custom-scrollbars';
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
    track: {
        position: 'absolute',
        width: '7px',
        height: '100vh',
        right: '2px',
        bottom: '2px',
        paddingTop: 'calc(5rem + 5px)',
        borderRadius: '3px',
        paddingRight: '2px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none',
        },
    },
    topBtnBox: {
        display:'none',
        opacity:0,
        position: 'fixed',
        bottom: theme.spacing(6),
        right: '5%',
        zIndex: '10',
        cursor:'pointer',
    },
    topBtn: {
        background: "#6f6f6f",
        padding: theme.spacing(4),
        [theme.breakpoints.between('xs', 'md')]: {
            padding: theme.spacing(3),
        },
        '& span':{
            fontSize: theme.typography.button.fontSize,
            color:'#fff',
        }
    }
}));

function App(props) {
    const classes = useStyles();
    const {ds} = useStores();

    const [checked, setChecked] = useState(false);
    const [toTop, setToTop] = useState(false);

    const scrollbar = useRef(null);
    const fadeValue = useRef(
        {
            old: false,
            new: false
        }
    );
    const topBox = useRef(null);

    useEffect(() => {
        if (api.hasToken()) {
            api.getMe()
                .then(data => {
                    ds.setLogin(true);
                })
                .catch(err => {
                    ds.setLogin(false);
                })
                .finally(() => {
                    setChecked(true);
                });
        } else {
            setChecked(true);
        }
    }, []);

    const renderTrackVertical = () => {
        return (<div className={classes.track}/>)
    }

    const scrollThumb = () => {
        const thumbStyle = {
            height: '100vh',
            borderRadius: '5px',
            backgroundColor: ds.isDark ? '#494850b3' : '#b8bbbeb3'
        };
        return (
            <div style={thumbStyle}/>
        )
    }

    const scrollToTop = () => {
        if (!scrollbar || !scrollbar.current) {
            return;
        }
        scrollbar.current.view.scroll({
            top: 0,
            behavior: 'smooth',
        });
    }

    const fadeButton = () => {

        if (!topBox || !topBox.current) {
            return;
        }
        const {current} = topBox;
        if (fadeValue.current.new) {
            current.style.display="block";
            current.style.transition  = 'opacity 0.6s 0.1s';
            setTimeout(()=>{
                current.style.opacity = 1;
            },400);
        } else {
            current.style.transition = `opacity 0.3s 0.1s`;
            current.style.opacity = 0
            setTimeout(()=>{
                current.style.display="none";
            },300);
        }
    }

    const handleUpdate = (values) => {
        fadeValue.current.old = fadeValue.current.new;
        fadeValue.current.new = values.scrollTop > 200;

        if (fadeValue.current.old !== fadeValue.current.new) {
            fadeButton();
        }
    }

    const TopButton = () => {

        const buttonStyles = {
            boxShadow : ds.isDark === false ? '0 10px 20px #bebebe' : 'none'
        }

        return (
            <div ref={topBox} className={classes.topBtnBox}>
                <Avatar className={classes.topBtn} onClick={scrollToTop} style={buttonStyles}>
                    <span>TOP</span>
                </Avatar>
            </div>
        )
    }

    return useObserver(() => {
        return checked ?
            <CssBaseline>
                <ThemeProvider theme={store.theme}>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                        <Scrollbars style={{height: '100vh', paddingTop: '5rem'}}
                                    ref={scrollbar}
                                    onUpdate={handleUpdate}
                                    autoHide
                                    renderTrackVertical={renderTrackVertical}
                                    renderThumbVertical={scrollThumb}>
                            <Router>
                                <Switch>
                                    <Route path='/login' component={LoginPage}/>
                                    <AuthRoute path='/' component={Layout}/>
                                </Switch>
                            </Router>
                        </Scrollbars>
                        <TopButton/>
                    </SnackbarProvider>
                </ThemeProvider>
            </CssBaseline>
            : null;
    });
}

export default App;
