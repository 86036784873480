import {createMuiTheme} from '@material-ui/core/styles';

/*
* type: Login, Menu Image Theme ( gccs, bsa )
* storeType: Login Page Store Link (vip, azure, poc, prod )
*            vip : lab.fnsvalue.co.kr
*            azure : azlab.fnsvalue.co.kr
*            poc : api.tabgdc.co.my
*            prod: api.onebsa.com.my
* */

const theme_dark = createMuiTheme({
    type : 'bsa',
    storeType:'prod',
    palette: {
        primary: {
            light: '#6b9cf9',
            main: '#2e6ec6',
            dark: '#004495',
            contrastText: '#fff'
        },
        secondary: {
            light: '#5bc2f6',
            main: '#0092c3',
            dark: '#006492',
            contrastText: '#fff'
        },
        background: {
            panel:'#2c333c',
            paper: '#212832',
            default: '#151c27',
            sideMenu:'#203550',
            scroll:'#494850b3',
            blackBox:'#21262e',
            tab:'#2c333c',
        },
        text: {
            primary: 'rgb(255, 255, 255)',
            secondary: 'rgb(187, 187, 187)',
            disabled: 'rgb(170, 170, 170)',
            hint: 'rgb(153, 153, 153)',
            divider: 'rgb(133, 133, 133)',
            title:'#67a7ff',
            gray:'#bbb'

        },
        border: {
            primary:'#545a61'
        },
        type: 'dark'
    },
    typography: {
        fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '2.5rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em'
        },
        h2: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 300,
            fontSize: '2.125rem',
            lineHeight: 1.167,
            letterSpacing: '-0.00833em'
        },
        h3: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.75rem',
            lineHeight: 1.167,
            letterSpacing: '0em'
        },
        h4: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.235,
            letterSpacing: '0.00735em'
        },
        h5: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        h6: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.125rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        subtitle1: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.9375rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em'
        },
        subtitle2: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.57,
            letterSpacing: '0.00714em'
        },
        body1: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.9375rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em'
        },
        body2: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
        },
        button: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.9375rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em',
            textTransform: 'uppercase'
        }
    },
    buttons:{
        btn1 : {
            background : {
                hover:'#6a6d72',
                default: '#4c5159'
            },
            color:'#bbb',
        }
    },
    props: {
        MuiTypography: {}
    }
});
/* ============================================================================================================================================================================ */
const theme_norm = createMuiTheme({
    type : 'bsa',
    storeType:'prod',
    palette: {
        primary: {
            light: '#6698ff',
            main: '#0f6be7',
            dark: '#0041b4',
            contrastText: '#fff'
        },
        secondary: {
            light: '#6bffff',
            main: '#00ccff',
            dark: '#009bcc',
            contrastText: '#fff'
        },
        background: {
            panel:'#e7e7e7',
            paper: '#fff',
            default: '#f0f6fc',
            sideMenu:'#fff',
            scroll:'#b8bbbeb3',
            blackBox:'#21262e',
            tab:'#dedede',
        },
        text: {
            primary: 'rgb(34,34, 34)',
            secondary: 'rgb(127,127,127)',
            disabled: 'rgb(170,170,170)',
            hint: 'rgb(170, 170, 170)',
            divider: 'rgb(215, 215, 215)',
            title:'#0f6be7',
            gray:'#7f7f7f',
            white:'#fff',
        },
        border: {
            primary:'#545a61'
        },
        // type: 'dark'
    },
    typography: {
        fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '2.5rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em'
        },
        h2: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 300,
            fontSize: '2.125rem',
            lineHeight: 1.167,
            letterSpacing: '-0.00833em'
        },
        h3: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.75rem',
            lineHeight: 1.167,
            letterSpacing: '0em'
        },
        h4: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.235,
            letterSpacing: '0.00735em'
        },
        h5: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        h6: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.125rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        subtitle1: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.9375rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em'
        },
        subtitle2: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.57,
            letterSpacing: '0.00714em'
        },
        body1: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.9375rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em'
        },
        body2: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
        },
        button: {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.9375rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em',
            textTransform: 'uppercase'
        },
    },
    buttons:{
        btn1 : {
            background : {
                hover:'#bfbfbf',
                default: '#999999'
            },
            color:'#fff'
        }
    },
    props: {
        MuiTypography: {}
    }
});


export default {norm:theme_norm, dark:theme_dark};
