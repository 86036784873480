import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import TopBar from '../components/TopBar';
import SideMenu from '../components/SideMenu';
import {Route} from 'react-router-dom';
import ClientListPage from './ClientListPage';
import MyPage from './MyPage';
import ClientDetailsPage from './ClientDetailsPage';
import Page from '../components/Page';
import {useMediaQuery} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import QnaPage from "./QnaPage";
import UserListPage from "./UserListPage";
import {useSnackbar} from 'notistack';
import {useStores} from "../common/store";
import UserDetailsPage from "./UserDetailsPage";
import Slide from "@material-ui/core/Slide";
import Settings from "./Settings";
import {useTranslation} from "react-i18next";
import AgreementListPage from "./AgreementListPage";
import AgreementDetailsPage from "./AgreementDetailsPage";
import LicenseListPage from "./license/LicenseListPage";
import LicenseDetailPage from "./license/LicenseDetailPage";
import SettingsPage from "./settings/SettingsPage";

function adminMenuFunc(user) {
    //const ADMIN_MENU_ITEMS = [
    return [
        {
            name: 'profile',
            label: 'profile',
            iconImg: 'icon_profile.png',
            iconImgBlack: 'icon_profile_black.png',
            active: true,
            menuAuth: true,
            items: [
                {
                    name: 'mypage',
                    label: 'mypage',
                    url: ['/mypage', "/"],
                    router: '/MyPage',
                    page: MyPage,
                    parentName: 'profile',
                    navs: ['Home', 'MyPage'],
                    menuAuth: true,
                },
            ]
        },
        {
            name: 'management',
            label: 'management',
            iconImg: 'icon_management.png',
            iconImgBlack: 'icon_management_black.png',
            active: false,
            menuAuth: user.isManager(),
            items: [
                {
                    url: '/admin/clients',
                    label: 'clients',
                    name: 'client',
                    router: '/admin/Client',
                    page: ClientListPage,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'Client'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/users',
                    label: 'users',
                    name: 'user',
                    router: '/admin/User',
                    page: UserListPage,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'User'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/agreements',
                    label: 'agreements',
                    name: 'agreement',
                    router: '/admin/Agreement',
                    page: AgreementListPage,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'Agreement'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/license',
                    label: 'license',
                    name: 'license',
                    router: '/admin/License',
                    page: LicenseListPage,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'License'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/clients/detail',
                    label: ':clientName',
                    name: 'client',
                    router: '/admin/Client',
                    page: ClientDetailsPage,
                    menuIndex: 0,
                    hidden: true,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'Client', ':clientName'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/users/detail',
                    label: ':userKey',
                    name: 'user',
                    router: '/admin/User',
                    page: UserDetailsPage,
                    menuIndex: 1,
                    hidden: true,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'User', ':userKey'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/agreements/detail',
                    label: 'agreementDetail',
                    name: 'agreement',
                    router: '/admin/Agreement',
                    page: AgreementDetailsPage,
                    menuIndex: 2,
                    hidden: true,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'Agreement', 'AgreementDetail'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/license/detail',
                    label: ':clientName',
                    name: 'license',
                    router: '/admin/License',
                    page: LicenseDetailPage,
                    menuIndex: 3,
                    hidden: true,
                    parentName: 'management',
                    navs: ['Home', 'Admin', 'License', ':clientName'],
                    menuAuth: user.isManager(),
                }
            ]
        },
        {
            name: 'others',
            label: 'others',
            iconImg: 'icon_others.png',
            iconImgBlack: 'icon_others_black.png',
            active: false,
            menuAuth: user.isManager(),
            items: [
                {
                    url: '/admin/qna',
                    label: 'qna',
                    name: 'q&a',
                    router: '/admin/Q&A',
                    page: QnaPage,
                    parentName: 'others',
                    navs: ['Home', 'Q&A'],
                    menuAuth: user.isManager(),
                },
                {
                    url: '/admin/settings',
                    label: 'settings',
                    name: 'settings',
                    router: '/admin/settings',
                    page: SettingsPage,
                    parentName: 'others',
                    navs: ['Home', 'Settings'],
                    menuAuth: user.isSuperManager(),
                },
            ]
        }
    ];
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.paper,

    },
    root2: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
        marginLeft: 260,
        backgroundImage: `url(${require((theme.palette.type === 'dark')
            ? (theme.type === 'gccs' ? '../images/gccs/main_bg_dark.png' : '../images/bsa/main_bg_dark.png')
            : (theme.type === 'gccs' ? '../images/gccs/main_bg.png' : '../images/bsa/main_bg_dark.png')
        )})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundAttachment: 'fixed',
        [theme.breakpoints.between('xs', 'md')]: {
            marginLeft: 0,
            transition: theme.transitions.create(
                ['margin-left'],
                {duration: 500}
            )
        }
    },
    topBar: {
        flexGrow: 0,
        height: '5rem',
    },
    sideMenu: {
        position: 'fixed',
        width: 260,
        height: '100vh',
        backgroundColor: theme.palette.background.sideMenu,
        boxShadow: (theme.palette.type === 'dark' ? '' : '0px 10px 26px 0px rgba(19,82,146,0.2)'),
        [theme.breakpoints.between('xs', 'md')]: {
            position: 'absoulte',
            transform: 'translateX(-260px)',
            transition: theme.transitions.create(
                ['transform'],
                {duration: 500}
            )
        },
    },
    sideMenuIsWidth: {
        position: 'absolute',
        width: 260,
        height: '100vh',
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root': {
            backgroundColor: theme.palette.background.sideMenu,
        }

    },
    mobileSideMenu: {
        height: '100vh',

    },

    main: {
        display: 'flex',
        justifyContent: 'center',
        padding: '3.125rem',
        marginTop: '5rem',
        [theme.breakpoints.between('xs', 'md')]: {
            paddingTop: 0,
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            paddingLeft: '3.125rem',
            paddingRight: '3.125rem',
        },
    },
}));

export default function Layout(props) {
    const {ds} = useStores();
    const {t} = useTranslation();

    const classes = useStyles();
    const theme = useTheme();
    const isWidth = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [open, setOpen] = React.useState(false);

    const handleClick = (e, props) => {
        setOpen(props.open);
    };

    const openItem = {open: !open, isWidth: isWidth, handleClick};

    const menuItems = adminMenuFunc(ds.user);

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        ds.alertFunc = (variant, message) => {
            enqueueSnackbar(message, {variant});
        }

        /** URL AUTH CHECK **/
        const connectionUrl = props.location.pathname;
        let authCheck = false; // 권한 확인
        let urlCheck = true;   // url 확인

        menuItems.forEach((data, index) => {
            data.items.forEach((item, index) => {
                if (item.menuAuth === false) {
                    if (_.isArray(item.url)) {
                        if (_.includes(item.url, connectionUrl)) {
                            authCheck = true;
                        }
                    } else {
                        if (item.url === connectionUrl) {
                            authCheck = true;
                        }
                    }
                }

                if (_.isArray(item.url)) {
                    if (_.includes(item.url, connectionUrl)) {
                        urlCheck = false;
                    }
                } else {
                    if (item.url === connectionUrl) {
                        urlCheck = false;
                    }
                }

            })
        });

        if (authCheck || urlCheck) {
            ds.showErrorAlert(t('Error.WRONG_APPROACH'));
            props.history.push('/');
        }
        /** URL AUTH CHECK **/
    }, [])

    return (
        <div className={classes.root}>
            {
                isWidth ?
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={(event) => handleClick(event, {open: false})}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.sideMenuIsWidth}
                        BackdropComponent={Backdrop}
                        BackdropProps={{timeout: 500}}
                        TransitionComponent={Transition}
                    >
                        <SideMenu item={menuItems} openItem={openItem}/>
                    </Dialog>
                    :
                    <div className={classes.sideMenu}>
                        <SideMenu item={menuItems} openItem={openItem}/>
                    </div>
            }
            <div className={classes.root2}>
                <TopBar openItem={openItem} className={classes.topBar}/>
                <main className={classes.main}>
                    {menuItems.map(item => (
                        item.items.map((innerItem, index) => (
                            <Route exact key={index.toString()} path={innerItem.url}
                                   render={props => (<Page {...props} {...innerItem} />)}/>
                        ))
                    ))}
                </main>
            </div>
        </div>
    );
}

function Transition(props) {
    return <Slide direction="right" {...props} />;
}

