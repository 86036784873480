import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    copyright: {
        color: theme.palette.text.disabled,
    }
}));

export default function Copyright(props) {
    const classes = useStyles();
    return (
        <Typography variant='body2' align='left' className={classes.copyright}>
            {'Copyright © '}
            <Link color='inherit' href='https://fnsvalue.co.kr' target='_blank'>
                FNSVALUE Co Ltd.
            </Link>
            {' All Rights Reserved.'}
        </Typography>
    );
}