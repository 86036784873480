import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router';
import queryString from 'query-string';
import TabPanels from "../components/TabPanels";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DetailPage from "./users/DetailPage";
import AuthHistoryPage from "./users/AuthHistoryPage";
import EventPage from "./users/EventPage";


const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position:'relative',
    },
    tabContent: {
        padding:theme.spacing(6),
        [theme.breakpoints.between('xs', 'md')]: {
            padding:20,
        }
    },
}));


export default function UserDetailsPage(props) {
    const classes = useStyles();
    const {location} = props;
    const {t} = useTranslation();
    const history = useHistory();

    const [tabIndex, setTabIndex] = React.useState(0);

    const querySearch = queryString.parse(location.search);
    const queryState = queryString.parse(location.state);
    const clientKey = querySearch.clientKey;
    const userKey = querySearch.userKey;
    const clientName = queryState.clientName;


    /********************** USE STATE EVENT **********************/
    const changeTabIndex = (value) => {
        setTabIndex(value);
    }
    /********************** USE STATE EVENT **********************/


    useEffect( () => {
        if( clientKey === undefined || userKey === undefined ) {
            alert(t("Error.WRONG_APPROACH"));
            history.push('/admin/users');
        }

    }, []);

    const items = [
        {label:t('UserPage.Title.Detail'), page:DetailPage, params:{clientKey:clientKey,userKey:userKey,clientName:clientName}},
        {label:t('UserPage.Title.AuthHistory'), page:AuthHistoryPage, params:{clientKey:clientKey,userKey:userKey}},
        {label:t('UserPage.Title.Event'),  page:EventPage, params:{clientKey:clientKey,userKey:userKey}},
    ]

    return (
        <React.Fragment>

            <TabPanels items={items} selected={tabIndex} changeHandler={changeTabIndex} />

            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.tabContent}>
                        {items.map((data, index) => (
                            (data.page !== undefined) &&
                            <TabPanel key={index.toString()} value={tabIndex} index={index}>
                                <data.page params={data.params}/>
                            </TabPanel>
                        ))}
                    </div>
                </Grid>
        </Grid>

        </React.Fragment>
    )
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box>{children}</Box>)}
        </div>
    );
}
