import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import api from "../../common/api";
import {CommonCodeValue, EventType, Category} from "../../common/types";
import DataTable from "../../components/DataTable";
import moment from "moment";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {useStores} from "../../common/store";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {useObserver} from "mobx-react-lite";
import LabelText from "../../components/LabelText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '40px 50px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: '20px',
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
        }
    },
    dateBox: {
        display: 'inline-flex',
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    inputBox: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0
        }
    },
    searchBoxDiv: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    searchText: {
        width: '60%',
    },
    button: {
        minWidth: 140,
        marginLeft: theme.spacing(1),
    }
}));

const dataTableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(2)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(3)': {
            minWidth: 160,
            width: '10%'
        },
        '& > :nth-child(4)': {
            minWidth: 130,
            width: '20%'
        },
        '& > :nth-child(5)': {
            minWidth: 130,
            width: '30%'
        },
        '& > :nth-child(6)': {
            minWidth: 130,
            width: '20%'
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : 'white',
        backgroundColor: theme.palette.type === 'dark' ? '#0d1015' : '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.type === 'dark' ? '#29303a' : '#f8f8f8'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.type === 'dark' ? '#323942' : '#e7e7e7'
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

const detailContentStyle = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        display: 'flex',
        padding: '20px 30px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        },
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '& .MuiDialogContent-root:first-child': {
            paddingTop: 0
        },
    },
    dialog_content: {
        padding: 0
    },
    root2: {
        flexGrow: 1,
        flexBasis: 0,
        borderRadius: 10,
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',

        backgroundColor: theme.palette.type === 'dark' ? '#2c333c' : '#e7e7e7',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
            marginBottom: '1.875rem',
            paddingTop: '2.188rem',
            paddingBottom: '2.188rem',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.between('sm', 'md')]: {
            maxWidth: 'auto',
        }
    },
    title: {
        color: theme.palette.text.primary,
        "& > *": {
            color: theme.palette.text.title,
        }
    },
    detailContainer: {
        border: (theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255)' : '1px solid rgba(0, 0, 0)'),
        borderRadius: 10,
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > *": {
            color: theme.palette.text.primary,
        },
        '& > div:first-child': {
            minWidth: 130
        },
    },
    headerInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexFlow: 'wrap',
        '& > div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            '& > dl': {
                display: 'flex',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                '& > dt': {
                    flex: 1,
                    minWidth: 80,
                    color: theme.palette.text.gray,
                },
                '& > dd': {
                    flexGrow: 1,
                    minWidth: 150,
                    marginRight: theme.spacing(1),
                    marginInlineStart: theme.spacing(1),
                }
            }
        }

    },
    Divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    bodyContainer: {
        minHeight: 250,
        display: 'flex',
        flex: 1,
        flexDirection: "column",
        "& > *": {
            color: theme.palette.text.primary,
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& > span': {
            ...theme.typography.h5
        }
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'stretch',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        flex: 1,
        minHeight: '350px'
    },
    footerContainer: {
        paddingTop: theme.spacing(2),
    },
    buttonBox: {
        paddingBottom: theme.spacing(1),
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(-2),
        },
    },

}));


export default function MyEventPage(props) {
    const pageSize = 5;
    const {ds} = useStores();
    const [response, setResponse] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const [clientKey, setClientKey] = useState('ALL');
    const [category, setCategory] = useState('ALL');
    const [eventType, setEventType] = useState('ALL');
    const [startDt, setStartDt] = useState(null);
    const [endDt, setEndDt] = useState(null);

    let dialogContent = useRef(null);

    const changedSearchDateEvent = (type) => (date, value) => {
        if (type === "startDt") {
            setStartDt(value);
        } else if (type === "endDt") {
            setEndDt(value);
        }
    }

    const onChangeSelect = (type) => ({target}) => {
        if (type === 'client') {
            setClientKey(target.value);
        } else if (type === 'category') {
            setCategory(target.value)
        } else if (type === 'type') {
            setEventType(target.value)
        }
    }

    const rowClickEvent = (row) => (event) => {
        setOpenDialog(true);
        if (row[6] !== undefined) {
            dialogContent.current = row[6].content;
        }
    }

    const search = (event, page = 1) => {
        api.getMyEvent(clientKey !== 'ALL' ? clientKey : null,
            category !== 'ALL' ? category : null,
            eventType !== 'ALL' ? eventType : null,
            startDt,
            endDt,
            page - 1,
            pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    const onCloseDialog = () => {
        setOpenDialog(false);
    }

    useEffect(() => {
        search(null, 1);
    }, [clientKey, category, eventType, startDt, endDt]);

    return (
        <React.Fragment>
            <ConditionPanel status={status}
                            startDt={startDt}
                            endDt={endDt}
                            onChangeSelect={onChangeSelect}
                            changedSearchKeywordEvent={changedSearchDateEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={response !== null ? response.page.currentPage + 1 : 1}
                         totalPage={response !== null ? response.page.totalPages : 0}
                         rowClickEvent={rowClickEvent}
                         pageSize={pageSize}
            />
            <DetailEventDialog open={openDialog} content={dialogContent} onCloseEvent={onCloseDialog}/>
        </React.Fragment>
    )
}

function ResultTable({response, search, currentPage, totalPage, rowClickEvent, pageSize}) {
    const classes = dataTableStyle();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: t('UserPage.Event.No')},
            {content: t('UserPage.Event.Client')},
            {content: t('UserPage.Event.Category')},
            {content: t('UserPage.Event.Type')},
            {content: t('UserPage.Event.Comment')},
            {content: t('UserPage.Event.RegDate')}
        ]
    ];


    if (response !== null) {
        let num = response.page.totalElements - (response.page.currentPage * pageSize);

        if (response.data !== undefined) {
            response.data.map((data, idx) => {
                let category = data.category ? t(CommonCodeValue.translateKey + '.' + data.category) : '-';
                let client = data.clientName ? data.clientName : '-';
                let type = data.type ? t(CommonCodeValue.translateKey + '.' + data.type) : '-';
                let comment = data.comment ? data.comment : '-';
                let regDate = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm:ss') : '-';

                body[idx] = [
                    {id: 'index', content: num--},
                    {id: 'client', content: client},
                    {id: 'category', content: category},
                    {id: 'type', content: type},
                    {id: 'comment', content: comment},
                    {id: 'date', content: regDate},
                    {id: 'data', content: data},
                ];
            });
        }
    }

    const pageChangeEvent = (event, pageIndex) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={classes}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   oncClickRowEvent={rowClickEvent}
                   onChangePageEvent={pageChangeEvent}
                   emptyText={t('Error.NoData')}
        />
    );
}

function ConditionPanel({status, startDt, endDt, onChangeSelect, changedSearchKeywordEvent}) {
    const [clientArray, setClientArray] = useState([]);
    const categoryArray = [];
    const typeArray = [];
    const classes = useStyles();
    const {t} = useTranslation();

    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);


    const onOpenEvent = (type, value) => () => {
        if (type === "startDt") {
            setIsOpenStart(value);
        } else if (type === "endDt") {

            setIsOpenEnd(value);
        }
    }

    if (clientArray.length === 0) {
        api.getClientCodes()
            .then((data) => {
                const responseArray = [];
                data.map((item, index) => {
                        responseArray[index] = {
                            key: item.clientKey,
                            value: item.clientKey,
                            text: item.clientName
                        }
                    }
                );
                setClientArray(responseArray);
            });
    }


    if (categoryArray.length === 0) {
        Object.keys(Category).map((key, index) => {
            categoryArray[index] = {
                key: key,
                value: Category[key],
                text: t(CommonCodeValue.translateKey + '.' + Category[key])
            };
        });
    }

    if (typeArray.length === 0) {
        Object.keys(EventType).map((key, index) => {
            typeArray[index] = {
                key: key,
                value: EventType[key],
                text: t(CommonCodeValue.translateKey + '.' + EventType[key])
            };
        });
    }


    return (
        <div className={classes.conditionDiv}>
            <SelectBox className={classes.inputBox}
                       title={t('UserPage.Event.Client')}
                       value={status}
                       contents={clientArray}
                       onChange={onChangeSelect('client')}
                       addAll={true}
            />
            <SelectBox className={classes.inputBox}
                       title={t('UserPage.Event.Category')}
                       value={status}
                       contents={categoryArray}
                       onChange={onChangeSelect('category')}
                       addAll={true}
            />
            <SelectBox className={classes.inputBox}
                       title={t('UserPage.Event.Type')}
                       value={status}
                       contents={typeArray}
                       onChange={onChangeSelect('type')}
                       addAll={true}
            />
            <div className={classes.dateBox}>
                <DateBox className={classes.inputBox}
                         keyword={startDt}
                         title={t('UserPage.Event.StartDate')}
                         changedSearchKeywordEvent={changedSearchKeywordEvent('startDt')}
                         onOpen={onOpenEvent('startDt', true)}
                         onClose={onOpenEvent('startDt', false)}
                         open={isOpenStart}
                />
                <DateBox className={classes.inputBox}
                         keyword={endDt}
                         title={t('UserPage.Event.EndDate')}
                         changedSearchKeywordEvent={changedSearchKeywordEvent('endDt')}
                         onOpen={onOpenEvent('endDt', true)}
                         onClose={onOpenEvent('endDt', false)}
                         open={isOpenEnd}
                         minDate={startDt}
                />
            </div>
        </div>
    );
}

function SelectBox({className, title, contents, value, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                defaultValue={value}
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={title}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}


function DateBox({className, keyword, changedSearchKeywordEvent, title, onOpen, onClose, open, minDate}) {
    const {t} = useTranslation();

    return (
        <FormControl className={className}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    autoOk
                    clearable
                    orientation={"landscape"}
                    variant="dialog"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    label={title}
                    minDateMessage={t('UserPage.Event.InvalidDate')}
                    value={keyword}
                    onChange={changedSearchKeywordEvent}
                    onClick={onOpen}
                    open={open}
                    onOpen={onOpen}
                    onClose={onClose}
                    minDate={minDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    );
}

function DetailEventDialog({open, onCloseEvent, content}) {
    if (!open) {
        return null;
    }

    const {t} = useTranslation();
    const classes = detailContentStyle();

    return useObserver(() =>
        <Dialog className={classes.dialog} open={open} onClose={onCloseEvent} maxWidth='md'>
            <DialogContent className={classes.dialog_content}>
                <div className={classes.root2}>
                    <Typography variant='h5' className={classes.title}>
                        <span>{t('UserPage.Event.DetailTitle')}</span>
                    </Typography>

                    <div className={classes.headerContainer}>
                        <div className={classes.headerInfo}>
                            <div>
                                <dl>
                                    <dt>{t('UserPage.Event.Seq')}</dt>
                                    <dd><span>{content.current.seq}</span></dd>
                                </dl>
                            </div>

                            <div>
                                <dl>
                                    <dt>{t('UserPage.Event.Client')}</dt>
                                    <dd><span>{content.current.clientName}</span></dd>
                                </dl>
                            </div>
                            <div>
                                <dl>
                                    <dt>{t('UserPage.Event.UserKey')}</dt>
                                    <dd><span>{content.current.userKey}</span></dd>
                                </dl>
                                <dl>
                                    <dt>{t('UserPage.Event.Category')}</dt>
                                    <dd><span>{content.current.category ? t('CommonCode.' + content.current.category) : '-'}</span></dd>
                                </dl>
                            </div>
                            <div>
                                <dl>
                                    <dt>{t('UserPage.Event.Type')}</dt>
                                    <dd><span>{content.current.type ? t('CommonCode.' + content.current.type) : '-'}</span></dd>
                                </dl>
                                <dl>
                                    <dt>{t('UserPage.Event.RegDate')}</dt>
                                    <dd>
                                        <LabelText translation={false} value={content.current.regDt} dataType={'date'} format={'YYYY-MM-DD HH:mm:ss'}/>
                                    </dd>
                                </dl>
                            </div>

                            <div>
                                <dl>
                                    <dt>{t('UserPage.Event.Manager.UserKey')}</dt>
                                    <dd><span>{content.current.mgrUserKey}</span></dd>
                                </dl>
                                <dl>
                                    <dt>{t('UserPage.Event.Ip')}</dt>
                                    <dd><span>{content.current.mgrIp}</span></dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <Divider className={classes.Divider}/>

                    <div className={classes.bodyContainer}>
                        <TextField
                            label={t('UserPage.Event.Comment')}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={12}
                            value={content.current.comment}
                            disabled
                        />
                    </div>
                    <div className={classes.footerContainer}>
                        <div className={classes.buttonBox}>
                            <React.Fragment>
                                <Button variant="contained" type={'button'} onClick={onCloseEvent}>
                                    {t('UserPage.Event.Close')}
                                </Button>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }

    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
