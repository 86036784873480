import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import LabelText from "./LabelText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useObserver} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Box} from "@material-ui/core";
import _ from "lodash";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputSelect:{
        fontSize:theme.typography.body2.fontSize,
    },
}));

export default function InputSelect({
                                        form,
                                        name,
                                        placeholder,
                                        value,
                                        variant,
                                        label,
                                        selectType,
                                        dataList,
                                        dataKey,
                                        dataValue,
                                        isEditable = true,
                                        style,
                                        text
                                    }) {
    const classes = useStyles();
    const {t} = useTranslation();

    const _valueOf = (dataList, data, dataKey, dataValue) => {

        if (data === undefined || data === null) {
            return null;
        }

        let selectKey = "";
        if( dataKey === undefined ){
            Object.keys(dataList).map((key, index) => {
                if (dataList[key] === data) {
                    selectKey =  t('CommonCode.' + dataList[key]);
                }
            });
        } else {
            Object.keys(dataList).map((key, index) => {
                if (dataList[key][dataValue] === data) {
                    selectKey =  dataList[key][dataKey];
                }
            });
        }
        return selectKey;
    }

    const field = form.$(name);

    let defaultSelectKey = '';

    if ( text !== undefined ) {
        defaultSelectKey = text;
    } else {
        if (value !== undefined) {
            if (selectType !== undefined) {
                defaultSelectKey = _valueOf(selectType, value);
            } else if (dataList !== undefined) {
                defaultSelectKey = _valueOf(dataList, value, dataKey, dataValue);
            }
        }
    }





    const onChangeSelectEvent = (event) => {
        defaultSelectKey = event.target.value;
        field.value = defaultSelectKey;
    };

    const SelectList = () => {
        if (selectType !== undefined) {
            return (
                <React.Fragment>
                    {variant === 'outlined' ? <InputLabel>{label}</InputLabel> : '' }
                    <Select
                        labelWidth={label?40:undefined}
                        label={label}
                        name={name}
                        displayEmpty={true}
                        variant={variant!==undefined?variant:'outlined'}
                        onChange={onChangeSelectEvent}
                        defaultValue={(value===undefined?"":value)}
                        className={`${classes.inputSelect} ${style?style:null}`}
                    >
                        {(placeholder!==undefined? (<MenuItem value={""} disabled>{placeholder}</MenuItem>) :null)}
                        {Object.keys(selectType).map((key, index) => (
                            <MenuItem key={index} value={selectType[key]} name={name}>
                                {selectType.getText(selectType[key])}
                            </MenuItem>
                        ))}
                    </Select>
                </React.Fragment>
            )
        } else if (dataList !== undefined && dataList.length > 0) {
            return (
                <React.Fragment>
                    {variant === 'outlined' ? <InputLabel>{label}</InputLabel> : '' }
                    <Select
                        labelWidth={label?40:undefined}
                        label={label}
                        name={name}
                        displayEmpty={true}
                        onChange={onChangeSelectEvent}
                        variant={variant!==undefined?variant:'outlined'}
                        defaultValue={(value===undefined?"":value)}
                        className={`${classes.inputSelect} ${style?style:null}`}
                    >
                        {(placeholder!==undefined? (<MenuItem value={""} disabled>{placeholder}</MenuItem>) :null)}
                        {Object.keys(dataList).map((key, index) => (
                            <MenuItem key={index} value={dataList[key][dataValue]} name={key}>
                                {dataList[key][dataKey]}
                            </MenuItem>
                        ))}
                    </Select>
                </React.Fragment>
            )
        } else {
            return (
                "-"
            )
        }
    }
    return useObserver( () => (
        <React.Fragment>
            {
                isEditable
                ?
                (
                    <FormControl variant="outlined" className={classes.formControl} {...(field?.error ? {error: true} : null)}>
                        <SelectList/>
                        {field ? <FormHelperText>{field.error ? field.error : " "} </FormHelperText> : null }
                    </FormControl>
                )
                :
                (
                    <LabelText translation={false} label={defaultSelectKey}/>
                )
            }
        </React.Fragment>
    ));
}

