import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router';
import {useMediaQuery} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles((theme) => ({
    tabHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.background.tab,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        [theme.breakpoints.between('xs', 'xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    tabContent: {
        width:'100%',
        color: theme.palette.text.primary,
        '& button ': {
            fontFamily: ['Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.125rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em',
            height: '70px'
        },
        '& .MuiTabs-indicator': {
            background: theme.palette.primary.main,
        },
        '& .Mui-selected > .MuiTab-wrapper': {
            color: theme.palette.text.title,
        },
        '& .MuiTabs-scroller.MuiTabs-scrollable .MuiTabs-flexContainer .MuiTab-root': {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            }
        }
    },
    tabBtnBox: {
        display: 'inline-block',
        paddingRight: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'xs')]: {
            width: '100%',
            textAlign: 'right',
            padding: theme.spacing(1),
            background: theme.palette.background.paper
        }
    },
    backBtnBox: {
        display: 'inline-block',
        paddingRight: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
    grayButton: {
        background: theme.buttons.btn1.background.default,
        color: theme.buttons.btn1.color,
        '&:hover': {
            background: theme.buttons.btn1.background.hover,
        }
    },
    cirCleBtnBox: {
        cursor:'pointer',
        [theme.breakpoints.between('xs', 'md')]: {
            position: 'fixed',
            bottom: theme.spacing(6),
            right: '5%',
            zIndex: '10',
            marginBottom:theme.spacing(8),
            padding: 0,
        }
    },
    circleBtn: {
        boxShadow: (theme.palette.type === 'dark' ? 'none' : '0 10px 20px #bebebe'),
        background: "#999",
        padding: theme.spacing(3),
        margin: 0
    }
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function TabPanels(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {items, selected, changeHandler, tabItems, useBack} = props;

    const backButton = () => {
        const history = useHistory();
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

        if (isMobile) {
            return (
                <Box className={classes.cirCleBtnBox}>
                    <Avatar className={classes.circleBtn} onClick={() => history.goBack()}>
                        <KeyboardBackspaceIcon fontSize={"default"} />
                    </Avatar>
                </Box>
            )
        } else {
            return (
                <Box className={classes.backBtnBox}>
                    <Button color={'primary'}
                            variant="contained"
                            type={'button'}
                            className={classes.grayButton}
                            onClick={() => history.goBack()}
                    >
                        {t('CommonButton.BackButton')}
                    </Button>
                </Box>
            )
        }


    }

    /* TabPanels index change Event */
    const tabIndexChangeHandler = (event, newValue) => {
        changeHandler(newValue);
    }
    return (
        <Grid container>
            <Grid item xl={12} xs={12} className={classes.tabHeader}>
                <Tabs
                    value={selected}
                    onChange={tabIndexChangeHandler}
                    className={classes.tabContent}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        items.map((v, index) => (
                                ((v.hidden !== 'true') && (v.page !== null) &&
                                    <Tab key={index.toString()} label={v.label} {...a11yProps(index)}
                                         disabled={(v.disabled !== undefined)}/>
                                )
                            )
                        )
                    }
                </Tabs>
                <Box className={classes.tabBtnBox}>
                    {(tabItems !== undefined) && tabItems.map((data, index) => (data.component(data, index)))}
                </Box>

                {useBack !== false && backButton()}
            </Grid>
        </Grid>
    );
}