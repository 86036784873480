import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import api from "../../common/api";
import {AuthResult, CommonCodeValue} from "../../common/types";
import DataTable from "../../components/DataTable";
import moment from "moment";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '40px 50px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: '20px',
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
        }
    },
    dateBox: {
        display: 'inline-flex',
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    inputBox: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0
        }
    },
    searchBoxDiv: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    searchText: {
        width: '60%',
    },
    button: {
        minWidth: 140,
        marginLeft: theme.spacing(1),
    },
}));

const dataTableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60,
            width: '10%'
        },
        '& > :nth-child(2)': {
            minWidth: 160,
            width: '20%'
        },
        '& > :nth-child(3)': {
            minWidth: 130,
            width: '20%'
        },
        '& > :nth-child(4)': {
            minWidth: 130,
            width: '30%'
        },
        '& > :nth-child(5)': {
            minWidth: 130,
            width: '20%'
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : 'white',
        backgroundColor: theme.palette.type === 'dark' ? '#0d1015' : '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.type === 'dark' ? '#29303a' : '#f8f8f8'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.type === 'dark' ? '#323942' : '#e7e7e7'
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

export default function AuthHistoryPage(props) {
    const pageSize = 5;
    const {clientKey} = props;
    const [response, setResponse] = useState(null);
    const [status, setStatus] = useState('ALL');
    const [startDt, setStartDt] = useState(null);
    const [endDt, setEndDt] = useState(null);

    const changedSearchDateEvent = (type) => (date, value) => {
        if (type === "startDt") {
            setStartDt(value);
        } else if (type === "endDt") {
            setEndDt(value);
        }
    }

    const onChangeSelect = ({target}) => {
        setStatus(target.value);
    }

    const search = (event, page = 1) => {
        const statusStr = status !== 'ALL' ? status : null;
        api.getClientAuthHistory(clientKey, statusStr, startDt, endDt, page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        search(null, 1);
    }, [status, startDt, endDt]);

    return (
        <React.Fragment>
            <ConditionPanel status={status}
                            startDt={startDt}
                            endDt={endDt}
                            onChangeSelect={onChangeSelect}
                            changedSearchKeywordEvent={changedSearchDateEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={response !== null ? response.page.currentPage + 1 : 1}
                         totalPage={response !== null ? response.page.totalPages : 0}
                         pageSize={pageSize}
            />
        </React.Fragment>
    )
}

function ResultTable({response, search, currentPage, totalPage, pageSize}) {
    const classes = dataTableStyle();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: t('ClientDetail.AuthHistory.No')},
            {content: t('ClientDetail.AuthHistory.Date')},
            {content: t('ClientDetail.AuthHistory.UserId')},
            {content: t('ClientDetail.AuthHistory.Result')},
            {content: t('ClientDetail.AuthHistory.Content')},
            {content: t('ClientDetail.AuthHistory.Ip')},
        ]
    ];

    if (response !== null) {
        let num = response.page.totalElements - (response.page.currentPage * pageSize);

        if (response.data !== undefined) {
            response.data.map((data, idx) => {

                let regDate = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm:ss') : '-';
                let userKey = data.userKey ? data.userKey : '-';
                let result = data.status ? t(CommonCodeValue.translateKey + '.' + data.status) : '-';
                let content = data.content ? data.content : '-';
                let connectIp = data.connectIp ? data.connectIp : '-';

                body[idx] = [
                    {id: 'index', content: num--},
                    {id: 'date', content: regDate},
                    {id: 'userKey', content: userKey},
                    {id: 'result', content: result},
                    {id: 'content', content: content},
                    {id: 'connectIp', content: connectIp}
                ];

            });
        }
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={classes}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   onChangePageEvent={pageChangeEvent}
                   emptyText={t('Error.NoData')}
        />
    );
}

function ConditionPanel({status, startDt, endDt, onChangeSelect, changedSearchKeywordEvent}) {
    const statusArray = [];
    const classes = useStyles();
    const {t} = useTranslation();

    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);


    const onOpenEvent = (type, value) => () => {
        if (type === "startDt") {
            setIsOpenStart(value);
        } else if (type === "endDt") {
            setIsOpenEnd(value);
        }
    }

    if (statusArray.length === 0) {
        Object.keys(AuthResult).map((key, index) => {
            statusArray[index] = {
                key: key,
                value: AuthResult[key],
                text: t(CommonCodeValue.translateKey + '.' + AuthResult[key])
            };
        })
    }

    return (
        <div className={classes.conditionDiv}>
            <SelectBox className={classes.inputBox}
                       title={t('ClientDetail.AuthHistory.Result')}
                       value={status}
                       contents={statusArray}
                       onChange={onChangeSelect}
                       addAll={true}
            />
            <div className={classes.dateBox}>
                <DateBox className={classes.inputBox}
                         keyword={startDt}
                         title={t('ClientDetail.AuthHistory.StartDate')}
                         changedSearchKeywordEvent={changedSearchKeywordEvent('startDt')}
                         onOpen={onOpenEvent('startDt', true)}
                         onClose={onOpenEvent('startDt', false)}
                         open={isOpenStart}
                />
                <DateBox className={classes.inputBox}
                         keyword={endDt}
                         title={t('ClientDetail.AuthHistory.EndDate')}
                         changedSearchKeywordEvent={changedSearchKeywordEvent('endDt')}
                         onOpen={onOpenEvent('endDt', true)}
                         onClose={onOpenEvent('endDt', false)}
                         open={isOpenEnd}
                         minDate={startDt}
                />
            </div>
        </div>
    );
}

function SelectBox({className, title, contents, value, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                defaultValue={value}
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={title}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}


function DateBox({className, keyword, changedSearchKeywordEvent, title, onOpen, onClose, open, minDate}) {
    const {t} = useTranslation();

    return (
        <FormControl className={className}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    autoOk
                    clearable
                    orientation={"landscape"}
                    variant="dialog"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    label={title}
                    minDateMessage={t('ClientDetail.AuthHistory.InvalidDate')}
                    value={keyword}
                    onChange={changedSearchKeywordEvent}
                    onClick={onOpen}
                    open={open}
                    onOpen={onOpen}
                    onClose={onClose}
                    minDate={minDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
