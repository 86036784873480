import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Spinner from "react-spinkit";
import {Box} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    loginBtn: {
        '& > span': {
            paddingLeft: '25px',
        }
    },
    progressBar: {
        '& > div': {
            height: '50%'
        }
    }
}));

const backGroundStyles = makeStyles((theme) => ({
    loadingBg: {
        position: 'absolute',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        background: '#000000a6',
        '& > div': {
            minWidth:'50px',
            minHeight:'50px'
        }
    },

}));

export default function ProgressButton(props) {
    const classes = useStyles();
    const {color, variant, label, clickHandler, form, type, loading} = props;

    return (
        <Button color={color !== undefined ? color : 'primary'}
                variant={variant !== undefined ? variant : 'contained'}
                type={type !== undefined ? type : null}
                form={form !== undefined ? form : null}
                className={(loading ? classes.loginBtn : null)}
                onClick={clickHandler !== undefined ? clickHandler : null}
        >
            <Fade in={loading}
                  style={{
                      position: 'absolute',
                      top: 0, left: 0,
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center'
                  }}
            >
                <Spinner name="line-scale"
                         color={'#fff'}
                         className={classes.progressBar}
                         noFadeIn={true}
                />
            </Fade>
            {(loading ? '　' : label)}
        </Button>
    )
}

export function LoginBackGround ({loading}) {
    const classes = backGroundStyles();
    return (
        <Fade in={loading}
              className={classes.loadingBg}
              unmountOnExit
        >
            <Box>
                <CircularProgress />
            </Box>
        </Fade>
    )
}