import React, {useEffect, useRef, useState} from 'react';

import * as PropTypes from "prop-types";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CommonCodeValue, UserType} from "../common/types";
import {useStores} from "../common/store";
import {useObserver} from "mobx-react";
import LabelText from "./LabelText";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const dialogStyles = makeStyles((theme) => ({
        dialog_title: {
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
        dialog_content: {
            marginTop: -theme.spacing(1),
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 960,
        },
        dialog_buttons: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: theme.spacing(1),
            "& > *": {
                minWidth: 100,
            }
        }
    })
);


export default function ConfirmDialog (props) {
    const classes = dialogStyles();

    const {open, onClose, onOk, title, content} = props;


    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
        >
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>{t(title===undefined?'ConfirmDialog.Title':title)}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t(content==undefined?'ConfirmDialog.Content':content)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color="primary" autoFocus>{t("ConfirmDialog.OkBtn")}</Button>
                <Button onClick={onClose}>{t("ConfirmDialog.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    )
}
