import React from 'react';
import {action, observable} from 'mobx';
import {MobXProviderContext} from 'mobx-react';
import theme from './theme';
import storage, {StorageKey as SK} from './storage';

class Store {
    @observable
    isLogin = false;

    @observable
    token = null;

    @observable
    user = null;

    isDark = storage.getLocal(SK.THEME);
    @observable
    theme = (this.isDark === true || this.isDark === null) ? theme.dark : theme.norm;

    alertFunc = null;

    @action
    setLogin = (isLogin) => {
        this.isLogin = isLogin;
    };

    @action
    setToken = (token) => {
        this.token = token;
    };

    @action
    setUser = (user) => {
        this.user = user;
    };

    @action
    setToggleTheme = () => {
        if (this.isDark === true || this.isDark === null) {
            this.isDark = false;
        } else {
            this.isDark = true;
        }
        storage.setLocal(SK.THEME, this.isDark);
        this.theme = this.isDark ? theme.dark : theme.norm;
    }

    showAlert(severity, message) {
        this.alertFunc(severity, message);
    }

    @action
    showSuccessAlert(message) {
        this.showAlert("success", message);
    }

    @action
    showWarningAlert(message) {
        this.showAlert("warning", message);
    }

    @action
    showErrorAlert(message) {
        this.showAlert("error", message);
    }

    @action
    showInfoAlert(message) {
        this.showAlert("info", message);
    }
};

export default new Store();

export function useStores() {
    return React.useContext(MobXProviderContext);
}