import {formatPhoneNumber, parsePhoneNumber} from "react-phone-number-input";
import moment from "moment";
import _ from "lodash";
import {CommonCodeValue} from "../types";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";


export function PhoneNumberFormat(phoneNumber, global = true) {

    let result = '-'
    if (phoneNumber !== undefined && phoneNumber !== null) {
        const number = _.startsWith(phoneNumber, '+') ? phoneNumber : '+' + phoneNumber;
        result = formatPhoneNumber(number);
        if (global) {
            const countryNumber = parsePhoneNumber(number);
            if (countryNumber !== undefined && countryNumber !== '') {
                let callingCode = "";
                if (countryNumber?.countryCallingCode !== undefined ){
                    callingCode = `+${countryNumber?.countryCallingCode})`;
                }
                result = `${callingCode} ${result}`;
            } else {
                result = phoneNumber;
            }
        }
    }
    return result;
}

export function StringNvl(str, empty = '') {
    if( str === undefined || str === null ) { return empty; }
    return str;
}

export function dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }

    return data;
}

