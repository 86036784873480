import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import _ from "lodash";
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 1440,
        margin: '0 auth',
        // backgroundColor: theme.palette.background.paper
    },
    root2: {
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'flex-start',
        marginBottom: 33
    },
    root3: {
        backgroundColor: (theme.palette.type === 'dark' ? '#212832' : '#ffffff'),
        borderRadius: 10,
        boxShadow: (theme.palette.type === 'dark' ? 'none' : '0px 10px 26px 0px rgba(19,82,146,0.2)'),
        [theme.breakpoints.between('xs', 'md')]: {
            maxWidth: 'calc(100vw - 2.5rem)',
        }
    },
    homeIcon: {
        marginRight: 9,
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.text.secondary
    },
    breadc: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        alignSelf: 'flex-end',
        [theme.breakpoints.between('xs', 'md')]: {
            alignSelf: 'flex-start',
            flexWrap:'wrap'
        }
    },
    headBox:{
        [theme.breakpoints.between('xs', 'md')]: {
            wordBreak: 'break-all',
        }
    },
    splitter: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        }
    }
}));

export default function Page(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {label, location} = props;

    const menuNames = (transKey, label) => {
        if (_.includes(label, ":")) {
            let title = "";
            const querySearch = queryString.parse(location.search);
            const queryState = queryString.parse(location.state);
            const param = label.replace(":", "");
            if (querySearch[param] !== undefined) {
                title = querySearch[param];
            } else if (queryState[param] !== undefined) {
                title = queryState[param];
            } else {
                title = label;
            }
            return title;
        } else {
            return t(transKey + label);
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.root2}>
                <div className={classes.breadc}>
                    <HomeIcon className={classes.homeIcon}/>
                    {
                        props.navs.map((nav, idx) => (
                            <React.Fragment key={idx.toString()}>
                                <Typography variant='subtitle1'
                                            color='textSecondary'>{menuNames("Menu.Nav.", nav)}</Typography>
                                {(props.navs.length - 1) != idx ?
                                    <ChevronRightIcon className={classes.splitter}/> : null}
                            </React.Fragment>
                        ))
                    }
                </div>
                <div className={classes.headBox}>
                    <Typography variant='h1' color='textPrimary'>{menuNames("Menu.", label)}</Typography>
                </div>
            </div>
            <div className={classes.root3}>
                {props.page(props)}
            </div>
        </div>
    );
}
