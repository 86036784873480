import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import InputSelect from "../components/InputSelect";
import {ClientStatus, CountryCode, ErrCode, ClientContractType, LoginType} from "../common/types";
import LabelText from "../components/LabelText";
import InputTexts from "../components/InputTexts";
import {useStores} from "../common/store";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import _ from "lodash";
import api from "../common/api";
import ProgressButton, {LoginBackGround} from "../components/ProgressButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import AccessClientSelectBox from "../components/AccessClientSelectBox";
import storage, {StorageKey as SK} from "../common/storage";

const useStyles = makeStyles((theme) => ({
    stepContainer: {
        width: '100%',
        padding:0,
    },
    stepper:{
        padding:0,
        paddingBottom:theme.spacing(6),
        paddingLeft:theme.spacing(5),
        paddingRight:theme.spacing(5),
        [theme.breakpoints.between('xs', 'md')]: {
            paddingLeft:theme.spacing(2),
            paddingRight:theme.spacing(2),
        }
    },
    stepLabel:{
        '& > .MuiStepLabel-labelContainer': {
            paddingLeft: theme.spacing(3),
        }
    },
    stepContent: {
      minWidth:'850px',
      [theme.breakpoints.between('xs', 'md')]: {
        minWidth:'auto',
      }
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        textAlign:'center',
        '& > Button' : {
            marginRight:theme.spacing(2),
        }
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    form:{
        marginTop:theme.spacing(3),
    },
    accessClientSelectBox: {
        width:'100%'
    },

}));
const contentStyle = makeStyles((theme) => ({
    row:{
        display:'inline-block',
        width:'48%',
        marginLeft:theme.spacing(1),
        marginRight:theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            width:'100%',
        }
    },
    row2:{
        display:'inline-block',
        width:'98%',
        marginLeft:theme.spacing(1),
        marginRight:theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            width:'100%',
        }
    },
    flexContainer:{
        display:'flex',
        [theme.breakpoints.between('xs', 'md')]: {
            display:'block',
        }
    },
    fieldset:{
        flex:'1',
        margin:theme.spacing(1),
        '& > div ':{
            paddingBottom:theme.spacing(1),
            marginLeft:theme.spacing(1),
            marginRight:theme.spacing(1),
        }
    },
    fieldLabel:{
        display:'block',
        marginBottom:theme.spacing(2),
        marginLeft:theme.spacing(1),
    }
}));


const dialogStyles = makeStyles((theme) => ({
        dialog_title: {
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
    })
);

function setMobxReactForm (fields, handleNext) {
    return new MobxReactForm({ fields }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSubmit(form) {
                console.log("Form Values!", form.values());
            },
            onSuccess(form) {
                console.log("Form Values!", form.values());
                if( handleNext !== undefined ){
                    handleNext(form.values());
                }
            },
            onError(form) {
                console.log("All form errors", form.errors());
            }
        }
    });
}

export default function ClientCreate(props) {
    const {ds} = useStores();
    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [response, setResponse] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saveDialog, setSaveDialog] = React.useState(false);

    const steps = ['ClientDetail.Detail.BasicInfo', 'ClientDetail.Detail.AppInfo', 'ClientDetail.Detail.Manager'];

    const storages = useRef(
        {
            clientStorage : {},
            vendorStorage : {}
        }
    );


    /********************** USE EFFECT **********************/
    useEffect(() => {
        storages.current.clientStorage.vendorClientKey = ds.user.clientKey;
    },[]);
    /********************** USE EFFECT **********************/




    /********************** USE STATE EVENT **********************/

    const loadingHandler = (value) => {
        setLoading(value);
    }

    const handleNext = (values) => {

        console.log( "activeStep ===>>> ",activeStep);
        // Move Step
        if( activeStep < (steps.length-1) ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if( values !== undefined) {
            console.log( "values ===>>> ",values);
            Object.keys(values).map( (key,index) => {
                storages.current.clientStorage[key] = values[key];
            });
        }

        /************************* SAVE PROCESSING *************************/
        if( activeStep === steps.length - 1 ) {
            saveOpenHandler(true);
        }
        /************************* SAVE PROCESSING *************************/
    };

    const handleBack = () => {

        console.log( "activeStep ===>>> ",activeStep);
        if( activeStep === 1 ) {
            if( appForm !== undefined ) {
                setStorage(appForm.get());
            }
        } else if ( activeStep === 2 ) {
            if( personForm !== undefined ) {
                setStorage(personForm.get());
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const saveOpenHandler = (value) => {
        setSaveDialog(value);
    }

    /********************** USE STATE EVENT : E **********************/

    const setStorage = (values) => {
        Object.keys(values).map( (key,index) => {
            storages.current.clientStorage[key] = values[key].value;
        });
    }


    /********************** STEP CONTENT : S **********************/

    const getStepContent = (step, fields, form) => {
        const {t} = useTranslation();
        const contentStyles = contentStyle();
        if( 0 === step ) {
            return (
                <div className={contentStyles.fieldset}>
                    {fields.map((data, index) => (
                        <div key={data.label} className={data.fullWidth ? contentStyles.row2 : contentStyles.row}>
                            <data.component {...data} form={form}/>
                        </div>
                    ))}
                </div>
            )
        } else if( 1 === step ) {
            return (
                <React.Fragment>
                    <div className={contentStyles.flexContainer}>
                        <div className={contentStyles.fieldset}>
                            <LabelText translation={false} label={'FCM'} className={contentStyles.fieldLabel}/>
                            <InputTexts name={'fcmServerKey'}
                                        variant={'outlined'}
                                        rows={4}
                                        form={form}
                                        value={storages.current.clientStorage?.fcmServerKey}
                            />
                        </div>
                    </div>
                </React.Fragment>

            )
        } else if( 2 === step ) {
            return (
                <div className={contentStyles.flexContainer}>
                    <div className={contentStyles.fieldset}>
                        {fields.map((data, index) => (
                            <data.component key={data.label} {...data} form={form}/>
                        ))}
                    </div>
                </div>
            )
        }
        return ''
    }

    /********************** STEP CONTENT : E **********************/

    const onAccessClientChange = (type, value) => {
        infoForm.$("vendorClientKey").value = value;
    }

    const saveOkClick = () => {
        loadingHandler(true);
        saveOpenHandler(false);

        storageProcessing();
    }


    const storageProcessing = () => {

        storages.current.clientStorage["licenseSeq"] = -1;
        storages.current.clientStorage["managerSeq"] = -1;

        api.setClient(storages.current.clientStorage)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    ds.showInfoAlert(t('ClientPage.CreateDialog.SaveClientSuccess'));

                    const response = data.data;

                    api.updateVerifyToken()
                        .then( (response2) => {
                            api.setToken(response2.data);
                            history.push({
                                pathname: '/admin/clients/detail',
                                search: "?" + api.encodeParams({clientKey: response.clientKey}),
                                state: api.encodeParams({clientName: response.clientName})
                            });

                        })
                        .catch((error) => {
                            ds.showErrorAlert(api.getErrMsg(error.rtCode));
                            console.log("error ==> ", error);
                        });
                }
            })
            .catch((err) => {
                loadingHandler(false);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("error ==> ", err);
            });

    }

    const CustomButton = ({type, variant, color, form, className, step}) => {

        if( step < steps.length-1 ) {
            return (
                <Button type={type}
                        variant={variant}
                        color={color}
                        form={form}
                        className={className}
                >
                    {t('ClientPage.CreateDialog.Next')}
                </Button>
            )
        } else {
            return (
                <ProgressButton variant={'contained'}
                                color={'primary'}
                                loading={loading}
                                label={t('ClientPage.CreateDialog.Save')}
                                form={form}
                />
            )
        }
    }

    const info = [
        { title:t('ClientDetail.Detail.Vendor'),  name:'vendorClientKey', rules:'required', value:storages.current.clientStorage?.vendorClientKey, component:AccessClientSelectBox,  variant:'outlined', addAll:false, onChangeSelect: onAccessClientChange, className: classes.accessClientSelectBox},
        { label:t('ClientDetail.Detail.Client'),  name:'clientName',    rules:'required|max:50', value:storages.current.clientStorage?.clientName, component:InputTexts,  variant:'outlined' },
        { label:t('ClientDetail.Detail.Status'),  name:'clientStatus',  rules:'required',    value:storages.current.clientStorage?.clientStatus,  component:InputSelect,  variant:'outlined', selectType:ClientStatus},
        { label:t('ClientDetail.Detail.LoginType'),  name:'loginType',  rules:'required',    value:storages.current.clientStorage?.loginType,  component:InputSelect,  variant:'outlined', selectType:LoginType},
        { label:t('ClientDetail.Detail.Region'),  name:'clientCountry', rules:'required',    value:storages.current.clientStorage?.clientCountry, component:InputSelect,  variant:'outlined', selectType:CountryCode },
        { label:t('ClientDetail.Detail.Type'),    name:'contractType',  rules:'',            value:storages.current.clientStorage?.contractType,  component:InputSelect,  variant:'outlined', selectType:ClientContractType },
        { label:t('ClientDetail.Detail.BizNo'),   name:'bizNo',         rules:'max:50',      value:storages.current.clientStorage?.bizNo,         component:InputTexts,   variant:'outlined'},
        { label:t('ClientDetail.Detail.Homepage'),name:'siteUrl',       rules:'url|max:255', value:storages.current.clientStorage?.siteUrl,       component:InputTexts,   variant:'outlined'},
        { name:'clientExplain', placeholder:'Explain', rules:'max:1000', value:storages.current.clientStorage?.clientExplain, component:InputTexts, rows:4, style:classes.inputPadding, fullWidth: true},
    ];

    const appInfo = [
        { name:'fcmServerKey', placeholder:'Insert FCM',  rules:'max:200', value:storages.current.clientStorage?.fcmServerKey, component:InputTexts, rows:4 ,variant:'outlined' },
    ];
    const personInfo = [
        { label:t('ClientDetail.Detail.Name'),  name:'managerName',  rules:'max:50', value:storages.current.clientStorage?.managerName,  component:InputTexts, variant:'outlined' },
        { label:t('ClientDetail.Detail.Email'), name:'managerEmail', rules:'email|max:30', value:storages.current.clientStorage?.managerEmail, component:InputTexts, variant:'outlined' },
        { label:t('ClientDetail.Detail.TelNo'), name:'telNo',        rules:'max:20', value:storages.current.clientStorage?.telNo,        component:InputTexts, variant:'outlined' }
    ];

    /*********************** FORM SET : S ***********************/
    const infoForm = setMobxReactForm(info,handleNext);
    const appForm = setMobxReactForm(appInfo,handleNext);
    const personForm = setMobxReactForm(personInfo,handleNext);

    const getComponents = [info,appInfo,personInfo];
    const getForms = [infoForm,appForm,personForm];
    const getFormIds= ['infoForm','appForm','personForm'];
    /*********************** FORM SET : E ***********************/

    return (
        <React.Fragment>

            <div className={classes.stepContainer}>
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel className={classes.stepLabel}>{t(label)}</StepLabel>
                            <StepContent className={classes.stepContent}>
                                <form noValidate autoComplete="off" onSubmit={getForms[index]?.onSubmit} id={getFormIds[index]} className={classes.form}>
                                    {getStepContent(index, getComponents[index], getForms[index])}
                                    <div className={classes.actionsContainer}>

                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            {t('ClientPage.CreateDialog.Prev')}
                                        </Button>
                                        <CustomButton type={'submit'}
                                                      color="primary"
                                                      form={getFormIds[index]}
                                                      className={classes.button}
                                                      step={activeStep}
                                                      loading={loading}
                                        />

                                    </div>
                                </form>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <SaveConfirmDialog open={saveDialog}
                                   onClose={saveOpenHandler}
                                   onOk={saveOkClick}
                                   title={t('ClientDetail.Detail.ConfirmTitle')}
                                   content={t('ClientDetail.Detail.ConfirmContent')}
                />
            </div>

            <LoginBackGround loading={loading}/>
        </React.Fragment>
    );
}


function SaveConfirmDialog({open, onClose, onOk, title, content}) {
    const classes = dialogStyles();
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
        >
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color='primary' autoFocus>{t("ClientDetail.Detail.OkBtn")}</Button>
                <Button onClick={() => onClose(false)}>{t("ClientDetail.Detail.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    );
}
