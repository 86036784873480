import React, {useEffect, useState, useRef} from 'react';
import QRCode from 'qrcode.react';
import {QrLogoImageSrc} from '../components/Logo';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useStores} from '../common/store';
import storage, {StorageKey as SK} from '../common/storage';
import Button from "@material-ui/core/Button";
import api from '../common/api';
import {AuthStatus, ErrCode} from '../common/types';

const dialogStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
            width: '100%',
        },
        title: {
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
        content: {
            marginTop: -theme.spacing(2),
            marginBottom: -theme.spacing(2),
            minWidth: 250,
            maxWidth: 960,
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        qrContentBox: {
            padding: 15,
            backgroundColor: 'white',
            width: 'fit-content',
            margin: 'auto'
        },
        qrCanvas: {
            display: 'flex'
        },
        height: {
            height: '3.5rem'
        },
        description: {
            fontSize: '0.9375rem',
            lineHeight: '1.5625rem',
            color: theme.palette.text.secondary,
            textAlign: 'right'
        },
    })
);

export function stopQrLogin(qrId, userKey, onProgressMsg, onErrMsg) {
    api.deleteAuth(api.MASTER_CLIENT_KEY, userKey, qrId)
        .then(data => {
            if (data.rtCode === ErrCode.RT_SUCCESS) {
                onProgressMsg(null);
            }
        }).catch(err => {
        onErrMsg(err.rtMsg);
    });
}

export default function QrPage({open, onClose, title, qrId, qrUrl, onProgressMsg, onErrMsg}) {
    const dialogStyle = dialogStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const fncClearInterval = useRef(null);

    const [userKey, setUserKey] = useState(null);
    const [progressMsg, setProgressMsg] = useState(null);
    const [countDown, setCountDown] = useState(null);
    const masterClientKey = api.MASTER_CLIENT_KEY;

    const customInterval = (time, onClose, timeoutMsg = null, ws = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {
            if (id !== null) {
                clearInterval(id);
                if (isTimeout && timeoutMsg) {
                    onErrMsg(timeoutMsg);
                }
                if (onClose) {
                    onClose();
                }
                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    const isProcess = progressMsg != null;

    function ProgressAlert({status}) {
        return (
            <Alert severity="info">{status}</Alert>
        );
    }

    function qrCancel(isStop = false, userKey = null) {
        if (onClose) {
            onClose(isStop, userKey);
        }
        setUserKey(null);
    }

    useEffect(() => {
        if (open) {
            const authTimeRemaining = 60000;

            const ws = api.getQrWebSocket(qrId);

            // 타이머 시작
            fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1,
                () => {
                    ws.close(), qrCancel()
                },
                t('LoginPage.LoginTimeout'));


            ws.onclose = (event) => {
                console.log("QR ws CLOSE");
                fncClearInterval.current();
            };

            ws.onmessage = (event) => {
                console.log("QR ws OnMessage");
                if (event.type === 'message') {
                    const data = JSON.parse(event.data);
                    const status = data.status;
                    const statusMsg = t('AuthStatus.' + status);

                    console.log(ws + ", " + status);

                    if (status == AuthStatus.RequestAuth) {
                        setUserKey(data.userKey);
                    }

                    if (AuthStatus.isFinish(status)) {
                        ws.close();
                        open = false;
                        setProgressMsg(null);
                        if (AuthStatus.isSuccess(status)) {
                            console.log(status);
                            const successUserKey = data.userKey;
                            const channelKey = data.channelKey;
                            api.getAuthResult(masterClientKey, successUserKey, channelKey, true)
                                .then(data => {
                                    if (data.rtCode === ErrCode.RT_SUCCESS) {
                                        api.setToken(data.data);
                                        api.getMe()
                                            .then(data => {
                                                ds.setLogin(true);
                                                storage.setSession(SK.LOGIN_USER_INFO, {data});
                                            })
                                            .catch(err => {
                                                onErrMsg(err.rtMsg);
                                            });
                                    }
                                })
                                .catch(err => {
                                    onErrMsg(err.rtMsg);
                                });
                        } else {
                            onErrMsg(statusMsg);
                        }

                    } else {
                        setProgressMsg(statusMsg);
                    }
                } else {
                    onProgressMsg(t('AuthStatus.AuthFailed'));
                }
            };
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => qrCancel(true, userKey)}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
            className={dialogStyle.root}
        >
            <DialogTitle id={`${title}_dialog_id`} className={dialogStyle.title}>{t(title)}</DialogTitle>
            <DialogContent className={dialogStyle.content}>
                <Typography variant={'subtitle1'}>{t('LoginPage.QrDialog.Message')}</Typography>
                <Box className={dialogStyle.qrContentBox}>
                    <QRCode className={dialogStyle.qrCanvas}
                            value={qrUrl}
                            size={128}
                            imageSettings={{
                                src: QrLogoImageSrc(),
                                width: 33,
                                height: 38,
                            }}
                    />
                </Box>
                {countDown == null ? null :
                    <Typography variant='body1' className={dialogStyle.description}>
                        {`${t('LoginPage.TimeRemain')} : ${countDown} (${t('LoginPage.Sec')})`}
                    </Typography>
                }
                <Box mt={3} hidden={!isProcess} className={dialogStyle.height}>
                    <ProgressAlert status={progressMsg} count={countDown}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => qrCancel(true, userKey)}>{t("ConfirmDialog.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    )

}
