import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Cards from "../../components/Cards";
import api from "../../common/api";
import LabelText from "../../components/LabelText";
import {useTranslation} from "react-i18next";
import {ErrCode} from "../../common/types";
import {useStores} from "../../common/store";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding:0,
        '& > div':{
            padding:0,
            flexGrow: 1,
        },
    },
}));

let allAuth = {};
let weekAuth = {};
let todayAuth = {};
let status = {};
export default function StatusPage(props) {
    const {t} = useTranslation();
    const {ds} = useStores();
    const {clientKey} = props;

    const classes = useStyles();

    const [response, setResponse] = React.useState({});

    useEffect(() => {
        if (clientKey !== null) {
           api.getClientStatus(clientKey)
               .then((data)=>{
                   if (data.rtCode === ErrCode.RT_SUCCESS) {
                        const result = data.data;
                        status = result.totalUser;
                        allAuth = result.totalAuthHistory;
                        weekAuth = result.weekAuthHistory;
                        todayAuth = result.todayAuthHistory;
                        setResponse(result);
                   }
               })
               .catch((err)=>{
                   ds.showErrorAlert(api.getErrMsg(err.rtCode));
               });
        }
    },[]);

    const userInfo = [
        {label: 'ClientDetail.Status.TotalUsers', component:LabelText, value:status?.total, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.ValidUsers', component:LabelText, value:status?.valid, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.BlockUsers', component:LabelText, value:status?.block, dataType:'number', translation:true},
    ];

    const statusInfo = [
        {label: 'ClientDetail.Status.AuthCount', component:LabelText, value:allAuth?.total, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.Success',   component:LabelText, value:allAuth?.success, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.Fail',      component:LabelText, value:allAuth?.fail,  dataType:'number', translation:true},
    ];

    const statusWeekInfo = [
        {label: 'ClientDetail.Status.AuthCount', component:LabelText, value:weekAuth?.total, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.Success',   component:LabelText, value:weekAuth?.success, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.Fail',      component:LabelText, value:weekAuth?.fail,  dataType:'number', translation:true},
    ];

    const statusDayInfo = [
        {label: 'ClientDetail.Status.AuthCount', component:LabelText, value:todayAuth?.total, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.Success',   component:LabelText, value:todayAuth?.success, dataType:'number', translation:true},
        {label: 'ClientDetail.Status.Fail',      component:LabelText, value:todayAuth?.fail,  dataType:'number', translation:true},
    ];

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={5}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Cards title={t('ClientDetail.Status.Users')} components={userInfo}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Cards title={t('ClientDetail.Status.Status')} components={statusInfo}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Cards title={t('ClientDetail.Status.StatusDay')} components={statusDayInfo}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Cards title={t('ClientDetail.Status.StatusWeek')} components={statusWeekInfo}/>
                </Grid>
            </Grid>
        </form>
    );
}
