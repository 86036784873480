import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import api from "../common/api";
import Button from "@material-ui/core/Button";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import {CommonCodeValue, QuestionAnswerStatus, QuestionType, UserType} from "../common/types";
import Pagination from "@material-ui/lab/Pagination";
import queryString from 'query-string';
import SearchBox from "../components/SearchBox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {useStores} from "../common/store";
import TextField from "@material-ui/core/TextField";
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';
import {useObserver} from "mobx-react-lite";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextEditor from "../components/TextEditor";
import AccessClientSelectBox from "../components/AccessClientSelectBox";


const PAGE_SIZE = 7;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '40px 50px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: '40px 20px'
        },
    },
    forms: {
        display: 'flex',
        [theme.breakpoints.between('xs', 'md')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        }
    },
    form_left: {
        display: 'flex',
        alignItems: 'stretch',
        flexGrow: 1,
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        "& > *": {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexWrap: 'wrap',
            "& > *": {
                marginTop: theme.spacing(1)
            }
        }
    },
    form_right: {
        display: 'flex',
        alignItems: 'stretch',
        flexGrow: 1,
        justifyContent: 'flex-end',
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        "& > div": {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            '& > div': {
                display: 'flex',
                flex: 1
            }
        }
    },
    client: {
        minWidth: 200,
        [theme.breakpoints.between('xs', 'md')]: {
            flex: 1
        }
    },
    category: {
        minWidth: 200,
        [theme.breakpoints.between('xs', 'md')]: {
            flex: 1
        }
    },
    data_area: {
        marginTop: theme.spacing(4),
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    },
    qnaListArea: {
        minHeight: 480
    },
    q_icon: {
        display: 'flex',
        borderRadius: 4,
        height: 30,
        backgroundColor: theme.palette.primary.main,
        alignItems: 'center',
        "& > p": {
            width: 30,
            margin: '0',
            textAlign: 'center',
            color: 'white'
        },
        [theme.breakpoints.between('xs', 'md')]: {
            alignItems: 'center',
            alignSelf: 'flex-start'
        }
    },
    a_icon: {
        display: 'flex',
        borderRadius: 4,
        height: 30,
        backgroundColor: theme.palette.secondary.main,
        alignItems: 'center',
        "& > p": {
            width: 30,
            margin: '0',
            textAlign: 'center',
            color: 'white'
        }
    },
    search_btn: {
        minWidth: 120,
        paddingTop: 12,
        paddingBottom: 12,
        [theme.breakpoints.between('xs', 'md')]: {
            flex: 1,
        }
    },
    qna_active: {
        backgroundColor: (theme.palette.type === 'dark' ? '#383e47' : '#cee7ff'),
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
            paddingBottom: theme.spacing(2),
            '& > .MuiAccordionSummary-content': {
                width: '100%'
            }
        },
        '& > .MuiAccordionSummary-expandIcon': {
            [theme.breakpoints.between('xs', 'md')]: {
                marginRight: 0,
                padding: 0,
            }
        },
    },
    qna_deactive: {
        backgroundColor: (theme.palette.type === 'dark' ? '#2c333c' : '#e7e7e7'),
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
            paddingBottom: theme.spacing(2),
            '& > .MuiAccordionSummary-content': {
                width: '100%'
            }
        },

        '& > .MuiAccordionSummary-expandIcon': {
            [theme.breakpoints.between('xs', 'md')]: {
                marginRight: 0,
                padding: 0,
            }
        },
    },
    qna_summary: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            //flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'initial',
            "& > *": {
                marginBottom: theme.spacing(1),
            }
        }
    },
    qna_summary_status: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 150,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'initial',
            flex: 1,
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(2),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            '& > :nth-child(2)': {
                marginLeft: 0,
            }
        }
    },
    qna_summary_info: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'initial',
            flexWrap: 'wrap',
            "& > *": {
                marginBottom: theme.spacing(1),
            }
        }
    },
    qna_summary_header: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            alignSelf: 'flex-start'
        }
    },
    qna_summary_footer: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            alignSelf: 'flex-end',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    qna_title: {
        marginRight: theme.spacing(3),
        flexGrow: 1,
        minWidth: 160,
        maxWidth: 720,
        "& > p": {
            wordBreak: 'break-all',
            textOverflow: 'ellipsis'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 46,
            marginRight: 0,
            maxWidth: 'initial',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: 'initial',
        }
    },
    qna_reguser: {
        [theme.breakpoints.between('xs', 'md')]: {
            alignSelf: 'flex-end',
            '& > p': {
                textAlign: 'right'
            }
        }
    },
    qna_regdt: {
        marginLeft: theme.spacing(4),
        minWidth: 100,
        [theme.breakpoints.between('xs', 'md')]: {
            marginLeft: 0,
            alignSelf: 'flex-end',
            '& > p': {
                textAlign: 'right'
            }
        }
    },
    qna_detail_bg: {
        backgroundColor: (theme.palette.type == 'dark' ? '#313740' : '#F0F0F0')
    },
    qna_detail: {
        paddingLeft: 46,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        }
    },
    qna_detail_info: {
        display: 'flex'
    },
    qna_detail_content: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(2),
        width: '100%',
        "& p": {
            lineHeight: 1.8,
            wordBreak: 'break-all',
        },
        '& .ql-size-small': {
            fontSize: '0.75em'
        },
        '& .ql-size-large': {
            fontSize: '1.5em'
        },
        '& .ql-size-huge': {
            fontSize: '2.5em'
        }
    },
    qna_actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(5),
        "& > button": {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            marginLeft: 0,
            marginRight: 0,
        }
    },
    qna_answer_area: {
        paddingTop: theme.spacing(4),
        marginRight: theme.spacing(5),
        display: 'flex',
    },
    qna_answer_content: {
        marginLeft: theme.spacing(3),
        flexGrow: 1,
        width: '100%',
        "& p": {
            lineHeight: 1.8
        },
    },
    dialog_title: {
        padding: '50px 40px 0px 40px',
        "& > *": {
            ...theme.typography.h5
        }
    },
    dialog_forms: {
        display: 'flex',
        justifyContent: 'center',
        "& > div": {
            flexGrow: 1,
            minWidth: 320,
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            marginTop: -theme.spacing(1),
            marginBottom: -theme.spacing(1),
            "& > div": {
                width: '100%',
                minWidth: 160,
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            }
        }
    },
    dialog_content: {
        padding: '27px 40px 50px 40px',
        marginTop: -theme.spacing(1),
        marginBottom: -theme.spacing(1),
        "& > div:first-child": {
            marginLeft: -theme.spacing(1),
            marginRight: -theme.spacing(1),
            "& > div": {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1)
            }
        },
        "& > div": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },

    },
    dialog_buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: -theme.spacing(2),
        "& > *": {
            minWidth: 100,
        },
        "& > button:nth-child(1)": {
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            justifyContent: 'center',
            marginRight: 0
        }
    },
}));

function ClientSelector({className, clients, value, onChange, addAll, field}) {
    const {t} = useTranslation();
    const {ds} = useStores();
    const [labelId] = useState(() => _.uniqueId('label-'));

    const clientsArray = useRef([]);

    if( ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null ) {
        if( clientsArray.current.length === 0 ) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientsArray.current[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }

    return useObserver(() =>
        <FormControl variant="outlined" className={className} {...(field?.error ? {error: true} : null)}>
            <InputLabel id={labelId}>{t("QnaPage.ClientSelect")}</InputLabel>
            <Select
                labelId={labelId}
                value={value}
                onChange={onChange}
                displayEmpty={true}
                defaultValue={""}
                labelWidth={80}
                label={t("QnaPage.ClientSelect")}
                {...field?.bind()}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    clientsArray.current.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
            {field ? <FormHelperText>{field.error ? field.error : " "}</FormHelperText> : null}
        </FormControl>
    );
}

function CategorySelector({className, value, onChange, addAll, field}) {
    const {t} = useTranslation();
    const [labelId] = useState(() => _.uniqueId('label-'));
    return useObserver(() =>
        <FormControl variant="outlined" className={className} {...(field?.error ? {error: true} : null)}>
            <InputLabel id={labelId}>{t("QnaPage.CategorySelect")}</InputLabel>
            <Select
                labelId={labelId}
                value={value}
                onChange={onChange}
                displayEmpty={true}
                defaultValue={""}
                labelWidth={40}
                label={t("QnaPage.CategorySelect")}
                {...field?.bind()}
            >
                {addAll === true ? <MenuItem value={QuestionType.All}>{QuestionType.getText(QuestionType.All)}</MenuItem> : null}
                <MenuItem value={QuestionType.License}>{QuestionType.getText(QuestionType.License)}</MenuItem>
                <MenuItem value={QuestionType.Auth}>{QuestionType.getText(QuestionType.Auth)}</MenuItem>
                <MenuItem value={QuestionType.Partner}>{QuestionType.getText(QuestionType.Partner)}</MenuItem>
                <MenuItem value={QuestionType.TechnicalSupport}>{QuestionType.getText(QuestionType.TechnicalSupport)}</MenuItem>
            </Select>
            {field ? <FormHelperText>{field.error ? field.error : " "}</FormHelperText> : null}
        </FormControl>
    );
}

function QnaComponent({qna, doReload}) {
    const {t} = useTranslation();
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [expand, setExpand] = useState(false);
    const [detail, setDetail] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [replyMode, setReplyMode] = useState(false);
    const [openReply, setOpenReply] = useState(false);
    const replyContent = useRef("");

    const {ds} = useStores();
    const regDt = new Date(qna.regDt);
    const statusColor = qna.answerStatus === QuestionAnswerStatus.Complete ? 'primary' : 'textSecondary';

    const fetchData = (callback) => {
        api.getQnaDetail(qna.questionSeq)
            .then(data => {
                setDetail(data);
                if (callback) {
                    callback();
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    const onChange = (evt, expanded) => {
        if (expanded && detail === null) {
            fetchData(() => setExpand(expanded));
        } else {
            setExpand(expanded);
        }
    }
    const onDeleteClick = (evt) => {
        setOpenDelete(true)
    }

    const onDelete = (isYes) => {
        if (isYes) {
            api.deleteQuestion(qna.questionSeq)
                .then((data) => {
                    doReload();
                    ds.showInfoAlert(t("QnaPage.DeleteComplete"));
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                });
        }
        setOpenDelete(false);
    }

    const onEditClick = (evt) => {
        setOpenEdit(true);
    }

    const onCloseEdit = () => {
        setOpenEdit(false);
        doReload();
    }

    const onReplyClick = (evt) => {
        replyContent.current = "";
        setReplyMode(true);
    }

    const onReplyEditClick = (evt) => {
        replyContent.current = detail.answer.content;
        setReplyMode(true);
    }

    const onReplyCancelClick = (evt) => {
        setReplyMode(false);
    }

    const onReplySaveClick = (evt) => {
        if (replyContent.current.trim().length == 0) {
            ds.showWarningAlert(t("QnaPage.EmptyReply"));
            return;
        } else if (detail.answer && replyContent.current === detail.answer.content) {
            ds.showWarningAlert(t("QnaPage.NoChangeReply"));
            return;
        }
        setOpenReply(true);
    }

    const onReply = (isYes) => {
        if (isYes) {
            const params = {clientKey: qna.clientKey, questionSeq: qna.questionSeq, content: replyContent.current};
            api.postQuestionAnswer(params)
                .then(data => {
                    setReplyMode(false);
                    ds.showInfoAlert(t("QnaPage.ReplyComplete"));
                    fetchData();
                })
                .catch(err => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        }
        setOpenReply(false);
    }

    const onReplyContentChange = (evt) => {
        replyContent.current = evt.target.value;
    }

    return (
        <Accordion expanded={expand} onChange={onChange}>
            <AccordionSummary
                className={expand ? classes.qna_active : classes.qna_deactive}
                expandIcon={<ExpandMoreIcon/>}
            >
                <div className={classes.qna_summary}>
                    <div className={classes.qna_summary_header}>
                        <div className={classes.q_icon}><p>Q</p></div>
                        <div className={classes.qna_summary_status}>
                            <Typography color='textPrimary'>{QuestionType.getText(qna.type)}</Typography>
                            <Typography color={statusColor}>{QuestionAnswerStatus.getText(qna.answerStatus)}</Typography>
                        </div>
                    </div>
                    <div className={classes.qna_summary_info}>
                        <div className={classes.qna_title}>
                            <Typography color='textPrimary'>{qna.title}</Typography>
                        </div>

                        <div className={classes.qna_summary_footer}>
                            <div className={classes.qna_reguser}>
                                <Typography color='textSecondary'>{qna.regUserKey}</Typography>
                            </div>
                            <div className={classes.qna_regdt}>
                                <Typography color='textSecondary'>{regDt.toLocaleDateString()}</Typography>
                            </div>
                        </div>

                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.qna_detail_bg}>
                <div className={classes.qna_detail}>
                    <div className={classes.qna_detail_info}>
                        <Typography variant='subtitle2' color='textSecondary'>Client :</Typography>
                        <Typography variant='subtitle2' color='textSecondary' style={{marginLeft: 16}}>{qna.clientName}</Typography>
                    </div>

                    <div className={classes.qna_detail_content}>
                        <Typography color='textPrimary' dangerouslySetInnerHTML={{__html: qna.content}}/>
                    </div>
                    {!replyMode ?
                        <div className={classes.qna_actions}>
                            {qna.regUserKey === ds.user.userKey ? <Button variant='contained' color={'primary'} onClick={onEditClick}>{t("QnaPage.EditBtn")}</Button> : null}
                            {qna.regUserKey === ds.user.userKey ? <Button variant='contained' onClick={onDeleteClick}>{t("QnaPage.DeleteBtn")}</Button> : null}
                            {(qna.regUserKey !== ds.user.userKey
                                && detail?.answer == undefined
                                && (UserType.isSuperManager(ds.user.userType) || UserType.isVendorManager(ds.user.userType))) ?
                                <Button variant='contained' color={'primary'} onClick={onReplyClick}>{t("QnaPage.ReplyBtn")}</Button> : null
                            }
                        </div> : null
                    }
                    {replyMode ?
                        <React.Fragment>
                            <div className={classes.qna_answer_area}>
                                <div className={classes.a_icon}><p>A</p></div>
                                <div className={classes.qna_answer_content}>
                                    <TextField variant="outlined" multiline rows={12} fullWidth defaultValue={replyContent.current}
                                               onChange={onReplyContentChange}/>
                                </div>
                            </div>
                            <div className={classes.qna_actions}>
                                <Button variant='contained' color={'primary'} onClick={onReplySaveClick}>{t("QnaPage.SaveBtn")}</Button>
                                <Button variant='contained' onClick={onReplyCancelClick}>{t("QnaPage.CancelBtn")}</Button>
                            </div>
                        </React.Fragment> : null
                    }
                    {(!replyMode && detail?.answer) ?
                        <React.Fragment>
                            <div className={classes.qna_answer_area}>
                                <div className={classes.a_icon}><p>A</p></div>
                                <div className={classes.qna_answer_content}>
                                    <Typography noWrap
                                                color='textPrimary'
                                                dangerouslySetInnerHTML={{__html: detail.answer.content}}
                                    />
                                </div>
                            </div>
                            {(detail.answer.regUserKey === ds.user.userKey) ?
                                <div className={classes.qna_actions}>
                                    <Button variant='contained' color={'primary'} onClick={onReplyEditClick}>{t("QnaPage.ReplyEditBtn")}</Button>
                                </div> : null
                            }
                        </React.Fragment> : null
                    }
                </div>
            </AccordionDetails>
            {openDelete ?
                <ConfirmDialog open={true} title={t("QnaPage.Confirm.DeleteTitle")} content={t("QnaPage.Confirm.DeleteMessage")} onClose={onDelete}/> : null}
            {openReply ?
                <ConfirmDialog open={true} title={t("QnaPage.Confirm.ReplyTitle")} content={t("QnaPage.Confirm.ReplyMessage")} onClose={onReply}/> : null}
            {openEdit ? <QnaEditDialog open={true} onClose={onCloseEdit} title={t("QnaPage.Dialog.EditTitle")} qna={qna}/> : null}
        </Accordion>
    )
}

function TextFieldWrapper(props) {
    const {field} = props;
    return useObserver(() =>
        <TextField
            {...props}
            {...field?.bind()}
            {...(field?.error ? {error: true, helperText: field.error} : {helperText: " "})}
        />
    );
}

function ConfirmDialog({title, content, open, onClose}) {
    const {t} = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(true)} color="primary" autoFocus>
                    {t("QnaPage.Confirm.YES")}
                </Button>
                <Button onClick={() => onClose(false)}>
                    {t("QnaPage.Confirm.NO")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function QnaEditDialog({open, onClose, title, description, qna}) {
    if (!open) {
        return null;
    }
    const {t} = useTranslation();
    const classes = useStyles();
    const {ds} = useStores();

    const [clients, setClients] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);

    const values = useRef({
        clientKey: qna?.clientKey ? qna.clientKey : ds.user.clientKey,
        type: qna?.type ? qna.type : "",
        title: qna?.title ? qna.title : "",
        content: qna?.content ? qna.content : "",
        questionSeq: qna?.questionSeq ? qna.questionSeq : null,
    });

    const form = new MobxReactForm({
        fields: [
            {name: 'clientKey', value: values.current.clientKey, rules: 'required', label: "Client"},
            {name: 'type', value: values.current.type, rules: 'required', label: "Category"},
            {
                name: 'title',
                value: values.current.title,
                rules: 'required',
                label: t("QnaPage.Dialog.TitleLabel"),
                placeholder: t("QnaPage.Dialog.TitlePlaceHolder")
            },
            {
                name: 'content',
                value: values.current.content,
                rules: 'required',
                label: t("QnaPage.Dialog.ContentLabel"),
                placeholder: t("QnaPage.Dialog.ContentPlaceHolder")
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                if (isEdit && !form.isDirty) {
                    ds.showWarningAlert(t("QnaPage.NoChange"));
                    return;
                }
                setOpenConfirm(true);
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const isEdit = (qna !== undefined);

    const onCloseBtn = (evt) => {
        onClose(false);
    }

    const onConfirmCloseBtn = (isYes) => {
        if (isYes) {
            if (isEdit) {
                values.current["questionSeq"] = qna.questionSeq;
                api.putQuestion(values.current)
                    .then((data) => {
                        onClose(true);
                        ds.showInfoAlert(t("QnaPage.EditComplete"));
                    })
                    .catch(err => {
                        ds.showErrorAlert(api.getErrMsg(err.rtCode));
                    });
            } else {
                api.postQuestion(values.current)
                    .then((data) => {
                        onClose(true);
                        ds.showInfoAlert(t("QnaPage.CreateComplete"));
                    })
                    .catch(err => {
                        ds.showErrorAlert(api.getErrMsg(err.rtCode));
                    });
            }
        }
        setOpenConfirm(false);
    }

    if (clients.length === 0) {
        api.getMeClientCode("JOIN")
            .then((data) => {
                setClients(data);
            });
    }

    const f_client = form.$("clientKey");
    const f_category = form.$("type");
    const f_title = form.$("title");
    const f_content = form.$("content");

    return useObserver(() =>
        <Dialog open={open} onClose={onClose} aria-labelledby={`${title} dialog`} aria-describedby={description} maxWidth='md' className={classes.dialog}>
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>{title}</DialogTitle>
            <DialogContent className={classes.dialog_content}>
                <div className={classes.dialog_forms}>
                    <ClientSelector className={classes.client} clients={clients} field={f_client}/>
                    <CategorySelector className={classes.category} field={f_category}/>
                </div>
                <TextFieldWrapper
                    placeholder={t("QnaPage.Dialog.TitlePlaceHolder")}
                    variant="outlined"
                    fullWidth
                    field={f_title}
                />
                <TextEditor
                    placeholder={t("QnaPage.Dialog.ContentPlaceHolder")}
                    field={f_content}
                    height={300}
                />
                <div className={classes.dialog_buttons}>
                    <Button variant='contained' size='large' color='primary'
                            onClick={form.onSubmit}>{isEdit ? t("QnaPage.Dialog.EditBtn") : t("QnaPage.Dialog.OkBtn")}</Button>
                    <Button variant='contained' size='large' onClick={onCloseBtn}>{t("QnaPage.Dialog.CancelBtn")}</Button>
                </div>
            </DialogContent>
            <ConfirmDialog
                open={openConfirm}
                onClose={onConfirmCloseBtn}
                title={isEdit ? t("QnaPage.Confirm.EditTitle") : t("QnaPage.Confirm.WriteTitle")}
                content={isEdit ? t("QnaPage.Confirm.EditMessage") : t("QnaPage.Confirm.WriteMessage")}/>
        </Dialog>
    );
}

export default function QnaPage(props) {
    // console.log("PROPS", props);
    const {history} = props;
    const {t} = useTranslation();
    const {ds} = useStores();
    const classes = useStyles();

    const query = queryString.parse(props.location.search);
    const currentPage = parseInt(query.page || 1);
    const clientKey = query.clientKey ? query.clientKey : "ALL";
    const category = query.category ? query.category : "ALL";
    let search = query.search ? query.search : "";
    const [clients, setClients] = useState([]);
    const [qnaData, setQnaData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [dialogInfo, setDialogInfo] = useState({opened: false, title: ""});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const onClientChange = (evt) => {
        query["clientKey"] = evt.target.value;
        query["page"] = 1;
        history.push("/admin/qna?" + queryString.stringify(query));
    };

    const onCategoryChange = (evt) => {
        query["category"] = evt.target.value;
        query["page"] = 1;
        history.push("/admin/qna?" + queryString.stringify(query));
    }

    const onChangePage = (evt, page) => {
        query["page"] = page;
        history.push("/admin/qna?" + queryString.stringify(query));
    }

    const onChangeSearch = (evt) => {
        search = evt.target.value;
    }

    const doQuery = () => {
        query["page"] = 1;
        query["search"] = search;
        history.push("/admin/qna?" + queryString.stringify(query));
    }

    const doReload = () => {
        fetchData(query);
    }

    const fetchData = (query) => {
        const {clientKey, category, search} = query;
        const params = {
            page: parseInt(query.page) - 1,
            size: PAGE_SIZE,
            clientKey: clientKey ? clientKey : "ALL",
            type: category ? (category === "ALL" ? "" : category) : "",
            title: search ? search : "",
            sort: "REG_DT,DESC"
        };

        api.getQnaList(params)
            .then(data => {
                setQnaData(data.data);
                setTotalPage(data.page.totalPages);
            })
            .catch(err => {
                setQnaData([]);
                setTotalPage(0);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    const openWriteDialog = () => {
        setDialogInfo({opened: true, title: t("QnaPage.Dialog.WriteTitle")});
    }

    const closeDialog = (reload) => {
        if (reload) {
            doReload();
        }
        setDialogInfo({opened: false, title: ""});
    }

    useEffect(() => {
        api.getClientCodes().then((data) => {
            setClients(data);
        });
    }, []);
    useEffect(() => {
        fetchData(query);
    }, [props.location.search]);

    return (
        <div className={classes.root}>
            <div className={classes.forms}>
                <div className={classes.form_left}>
                    <ClientSelector className={classes.client} clients={clients} value={clientKey} onChange={onClientChange} addAll/>
                    <CategorySelector className={classes.category} value={category} onChange={onCategoryChange} addAll/>
                    <SearchBox value={search} onChange={onChangeSearch} doQuery={doQuery} placeholder={t('QnaPage.SearchPlaceHolder')}/>
                </div>
                <div className={classes.form_right}>
                    <div>
                        <Button variant='contained' color='primary' size='large' className={classes.search_btn}
                                onClick={openWriteDialog}>{t("QnaPage.WriteBtn")}</Button>
                    </div>
                </div>
            </div>
            <div className={classes.data_area}>
                {(qnaData === undefined || qnaData.length < 1) ?
                    <div className={classes.qnaListArea} >
                        <Accordion>
                            <AccordionDetails className={classes.qna_detail_bg}
                                style={{padding: '8px'}}>
                                <div className={classes.qna_detail}
                                    style={{textAlign: 'center', padding: '16px'}}>
                                    {t('Error.NoData')}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    :
                    [
                        <div className={classes.qnaListArea}>
                            {qnaData.map(qna => <QnaComponent key={qna.questionSeq.toString()} qna={qna} doReload={doReload} clients={clients}/>)}
                        </div>,
                        <div className={classes.pagination}>
                            <Pagination
                                size={isMobile ? 'small' : 'large'} count={totalPage}
                                page={currentPage}
                                onChange={onChangePage}
                                shape="rounded"
                            />
                        </div>
                    ]
                }
            </div>
            <QnaEditDialog open={dialogInfo.opened} onClose={closeDialog} title={dialogInfo.title}/>
        </div>
    )
}
