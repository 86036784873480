import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../components/DataTable';
import api from "../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {AgreementType, CommonCodeValue, LanguageCode, UseStatusCode, SubClientBehavior, UserType} from "../common/types";
import queryString from 'query-string';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {useStores} from "../common/store";
import moment from "moment";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBox from "../components/SearchBox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputTexts from "../components/InputTexts";
import InputSelect from "../components/InputSelect";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {useObserver} from "mobx-react-lite";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextEditor from "../components/TextEditor";
import AccessClientSelectBox from '../components/AccessClientSelectBox';
import FormHelperText from "@material-ui/core/FormHelperText";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '40px 50px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        marginBottom: theme.spacing(4),
        "& > form,& > :last-child": {
            marginTop:theme.spacing(1),
            marginBottom:theme.spacing(1),
        },
        [theme.breakpoints.between('xs','md')]: {
            flexDirection: 'column',
        }
    },
    selectContainer:{
        display: 'flex',
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
        "& > *":{
            marginRight:theme.spacing(2),
            [theme.breakpoints.between('xs','md')]: {
                marginTop:theme.spacing(1),
                marginBottom:theme.spacing(1),
                marginRight:0,
            }
        },
        [theme.breakpoints.between('xs','md')]: {
            flexDirection:'column',
        }
    },
    selectDiv:{
        display:'inline-flex',
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    selectBox: {
        minWidth: 200,
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight:0,
        }
    },
    searchBoxDiv: {
        display: 'flex',
    },
    searchBox: {
        display: 'flex',
        minWidth: '20rem',
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
    },
    searchText: {
        width: '100%',
    },
    button: {
        minWidth: 140,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    inputBox: {
        minWidth: 200,
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0
        }
    },

}));

const dialogStyle = makeStyles((theme) => ({
    root: {
    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    content: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    infoContainer:{
        display:'flex',
        '& > dl':{
            display:'flex',
            flex:1,
            '& > *':{
                flex:1,
                textAlign:'center',
            }
        }
    },
    selectContainer:{
        display:'flex',
        flexWrap:'wrap',
        '& > div':{
            minWidth: 320,
            flex:1,
            marginBottom:theme.spacing(2),
            [theme.breakpoints.between('xs', 'md')]: {
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                minWidth: 160,
            }
        },
        '& > div:first-child':{marginRight:theme.spacing(1)},
        '& > div:nth-child(2)':{marginLeft:theme.spacing(1)},
        [theme.breakpoints.between('xs', 'sm')]: {
            flex:1,
            flexDirection:'column',
            marginBottom:0,
            '& > div:first-child':{marginRight:0},
            '& > div:nth-child(2)':{marginLeft:0},
        }
    },
    bodyContainer:{
        display:'flex',
        flexDirection:'column',
        '& > div':{
            marginBottom:theme.spacing(2),
        },
        '& > div:first-child':{
            minWidth: 650,
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth:'initial',
                flex:1,
            }
        },
    },
    buttonContainer:{
        display:'flex',
        justifyContent:'flex-end',

        '& > *':{
            margin:theme.spacing(1)
        }
    }

}));

const tableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60
        },
        '& > :nth-child(2)': {
            minWidth: 130,
        },
        '& > :nth-child(3)': {
            minWidth: 130,
        },
        '& > :nth-child(4)': {
            minWidth: 130,
        },
        '& > :nth-child(5)': {
            minWidth: 130
        },
        '& > :nth-child(6)': {
            minWidth: 130,
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : 'white',
        backgroundColor: theme.palette.type === 'dark' ? '#0d1015' : '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.type === 'dark' ? '#29303a' : '#f8f8f8'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.type === 'dark' ? '#323942' : '#e7e7e7'
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

export default function AgreementListPage(props) {
    const pageSize = 5;
    const {location} = props;
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();

    const query = queryString.parse(location.search);
    let lang = query['lang'] !== undefined ? query['lang'] : null;
    let useStatus = query['useStatus'] !== undefined ? query['useStatus'] : null;
    let type = query['type'] !== undefined ? query['type'] : null;
    let client = query['client'] !== undefined ? query['client'] : null;
    let searchWord = query['searchWord'] !== undefined ? query['searchWord'] : null;

    const searchParams = useRef(
        {
            lang : lang,
            useStatus : useStatus,
            type : type,
            client : client,
            searchWord : searchWord,
        }
    );

    const [page, setPage] = React.useState(1);
    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);
    const [keywordResponse, setKeywordResponse] = React.useState({title:null,regUserKey:null});
    const [createOpen, setCreateOpen] = useState(false);

    /********************** USE STATE EVENT **********************/
    const changedSearchKeywordEvent = ({target}) => {
        searchParams.current.searchWord = target.value;
        setKeywordResponse({searchWord:target.value})
        search(null,1);
    }

    const onChangeSelect = (type, value) => {
        if (type === 'language') {
            searchParams.current.lang = value;
        } else if (type === 'useStatus') {
            searchParams.current.useStatus = value;
        } else if (type === 'type') {
            searchParams.current.type = value;
        } else if (type === 'client') {
            searchParams.current.client = value;
        }
        search(null,1);
    }
    const createClickEvent = () => {
        setCreateOpen(prevState => !prevState);
    }

    const setResponseData = (data) => {
        console.log("setResponseData")
        setResponse(data);
    }

    /********************** USE STATE EVENT **********************/


    const rowClickEvent = (row) => (event) => {
        setEvent({type: 'detail', agreementSeq: row[8]?.content});
    }

    const search = (event, page = 1) => {
        const query = {
            lang: searchParams.current.lang,
            useStatus: searchParams.current.useStatus,
            type: searchParams.current.type,
            client: searchParams.current.client,
            searchWord: searchParams.current.searchWord,
            page: page
        };
        setPage(page);
        fetchData(query,pageSize);
    }

    const fetchData = (query) => {
        const lang = query.lang !== 'ALL' ? query.lang : null;
        const useStatus = query.useStatus !== 'ALL' ? query.useStatus : null;
        const type = query.type !== 'ALL' ? query.type : null;
        const client = query.client !== 'ALL' ? query.client : null;

        api.getAgreementList(lang, useStatus, type, client, query.searchWord, query.page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData({lang, useStatus, type, client, searchWord, page});
    }, []);


    if (event?.type === 'detail') {
        return (
            <Redirect push to={{
                pathname: '/admin/agreements/detail',
                search: "?" + api.encodeParams({ agreementSeq: event.agreementSeq}),
            }}/>
        );
    }
    return (
        <div className={classes.root}>
            <ConditionPanel searchParams={searchParams}
                            search={search}
                            onChangeSelect={onChangeSelect}
                            changedSearchKeywordEvent={changedSearchKeywordEvent}
                            onClickCreateEvent={createClickEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={page}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
            <CreateDialog title={t('Agreement.CreateDialog.Title')}
                          open={createOpen}
                          onClose={createClickEvent}
                          onOk={createClickEvent}
                          search={search}
            />
        </div>
    );
}

function ConditionPanel({searchParams, search, onChangeSelect, changedSearchKeywordEvent, onClickCreateEvent}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();

    const langArray = [];

    if (langArray.length === 0) {
        Object.keys(LanguageCode).map((key, index) => {
            langArray[index] = {key: key, value: LanguageCode[key], text: t(CommonCodeValue.translateKey + '.' + LanguageCode[key])};
        })
    }

    const useStatusArray = [];

    if (useStatusArray.length === 0) {
        Object.keys(UseStatusCode).map((key, index) => {
            useStatusArray[index] = {key: key, value: UseStatusCode[key], text: t(CommonCodeValue.translateKey + '.' + UseStatusCode[key])};
        })
    }

    const typeArray = [];

    if (typeArray.length === 0) {
        Object.keys(AgreementType).map((key, index) => {
            typeArray[index] = {key: key, value: AgreementType[key], text: t(CommonCodeValue.translateKey + '.' + AgreementType[key])};
        })
    }

    const clientArray = [];

    if( ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null ) {
        if( clientArray.length === 0 ) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientArray[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }


    const onChangeLanguage = (event) => {
        if (onChangeSelect) {
            onChangeSelect('language', event.target.value);
        }
    }

    const onChangeUseStatus = (event) => {
        if (onChangeSelect) {
            onChangeSelect('useStatus', event.target.value);
        }
    }

    const onChangeType = (event) => {
        if (onChangeSelect) {
            onChangeSelect('type', event.target.value);
        }
    }

    const onChangeClient = (event) => {
        if (onChangeSelect) {
            onChangeSelect('client', event.target.value);
        }
    }

    return (
        <div className={classes.conditionDiv}>
            <div className={classes.selectContainer}>
                <SelectBox className={classes.inputBox}
                           title={t('Agreement.Lang')}
                           defaultValue={'ALL'}
                           value={searchParams.current.lang}
                           contents={langArray}
                           onChange={onChangeLanguage}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('Agreement.UseStatus')}
                           defaultValue={'ALL'}
                           value={searchParams.current.useStatus}
                           contents={useStatusArray}
                           onChange={onChangeUseStatus}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('Agreement.Type')}
                           defaultValue={'ALL'}
                           value={searchParams.current.type}
                           contents={typeArray}
                           onChange={onChangeType}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('Agreement.Client')}
                           defaultValue={'ALL'}
                           value={searchParams.current.client}
                           contents={clientArray}
                           onChange={onChangeClient}
                           addAll={true}
                />

                <SearchBox
                    value={searchParams.current.searchWord}
                    placeholder={t('Agreement.Search')}
                    doQuery={search}
                    onChange={changedSearchKeywordEvent}
                />

            </div>
            <Button className={classes.button}
                    variant="contained"
                    color='primary'
                    size='large'
                    onClick={onClickCreateEvent}>
                <Typography className={classes.button.text} variant="button">{t('Agreement.NewAgreement')}</Typography>
            </Button>
        </div>
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {
    const tableStyles = tableStyle();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: 'No'},
            {content: t('Agreement.Type')},
            {content: t('Agreement.Title')},
            {content: t('Agreement.Client')},
            {content: t('Agreement.Lang')},
            {content: t('Agreement.UseStatus')},
            {content: t('Agreement.UserId')},
            {content: t('Agreement.Date')},
        ]
    ];

    if (response !== null) {
        //let num = response.page.currentPage * pageSize;
        let num = response.page.totalElements - ( response.page.currentPage * pageSize );

        response.data.map((data, idx) => {
            let title = data.title ? data.title : '-';
            let clientName = data.clientName ? data.clientName : '-';
            let type = data.type ? t(CommonCodeValue.translateKey + '.' + data.type) : '-';
            let lang = data.lang ? t(CommonCodeValue.translateKey + '.' + data.lang) : '-';
            let useStatus = data.useStatus ? t(CommonCodeValue.translateKey + '.' + data.useStatus) : '-';
            let regUserKey = data.regUserKey ? data.regUserKey : '-';
            let regDt = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD')  : '-';

            body[idx] = [
                {id: 'index', content: num--},
                {id: 'type', content: type},
                {id: 'title', content: title, align:'left'},
                {id: 'clientName', content: clientName, align: 'left'},
                {id: 'lang', content: lang},
                {id: 'useStatus', content: useStatus},
                {id: 'regUserKey', content: regUserKey},
                {id: 'regDt', content: regDt},
                {id: 'agreementSeq', content: data.seq},
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={tableStyles}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}


function SelectBox({className, title, contents, defaultValue, value, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                defaultValue={defaultValue}
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={title}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content,idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}


function CreateDialog({open, onClose, onOk, title, search}) {
    const dialogStyles = dialogStyle();
    const {t} = useTranslation();
    const {ds} = useStores();

    const [openConfirm, setOpenConfirm] = useState(false);

    const agreementDetail = useRef(
        {
            regUserKey:ds.user.userKey,
            lang:"",
            useStatus:"",
            type:"",
            title:"",
            clientKey:"",
            content:"",
            subCltBhv:"",
        }
    );

    const cloneData = useRef(
        {
            regUserKey:ds.user.userKey,
            lang:"",
            useStatus:"",
            type:"",
            title:"",
            clientKey:"",
            content:"",
            subCltBhv:"",
        }
    );

    const onChangeClient = (event, value) => {
        agreementDetail.current.clientKey = value;
    }

    const saveCancelClick = () => {
        agreementDetail.current = _.cloneDeep(cloneData.current);
        onClose();
    }

    const formId = "agreementForm";
    const form = new MobxReactForm({
        fields: [
            {name: 'lang', value: agreementDetail.current.lang, rules: 'required', label: t('Agreement.Lang')},
            {name: 'useStatus', value: agreementDetail.current.useStatus, rules: 'required', label: t('Agreement.UseStatus')},
            {name: 'type', value: agreementDetail.current.type, rules: 'required', label: t('Agreement.Type')},
            {
                name: 'title',
                value: agreementDetail.current.title,
                rules: 'required',
                label: t("Agreement.Title"),
            },
            {
                name: 'clientKey',
                value: agreementDetail.current.clientKey,
                label: t('Agreement.Client')
            },
            {
                name: 'subCltBhv',
                value: agreementDetail.current.subCltBhv,
                label: t('Agreement.SubClientBehavior')
            },
            {
                name: 'content',
                value: agreementDetail.current.content,
                rules: 'required',
                label: t("Agreement.Content"),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                const values = form.values();
                Object.keys(values).map( (key,index) => {
                    if (values[key] !== null && values[key] !== "") {
                        agreementDetail.current[key] = values[key];
                    }
                });
                setOpenConfirm(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map( (data, index) => {
                    if( errorMsg === "" ) {
                        errorMsg = form.errors()[data];
                        if( errorMsg === null ) { errorMsg = "";}
                    }
                } );
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            }
        }
    });

    let langText = "";
    if (agreementDetail.current.lang !== undefined) {
        langText = CommonCodeKey(LanguageCode, agreementDetail.current.lang);
    }

    let statusText = "";
    if (agreementDetail.current.useStatus !== undefined) {
        statusText = CommonCodeKey(UseStatusCode, agreementDetail.current.useStatus);
    }

    let subClientBehaviorText = "";
    if (agreementDetail.current.subCltBhv !== undefined) {
        subClientBehaviorText = CommonCodeKey(SubClientBehavior, agreementDetail.current.subCltBhv);
    }

    let typeText = "";
    if (agreementDetail.current.type !== undefined) {
        typeText = CommonCodeKey(AgreementType, agreementDetail.current.type);
    }

    const onClickBtn = (isYes) => {
        if( isYes ) {
            api.postAgreement(agreementDetail.current)
                .then((data) => {
                    onClose(true);
                    search();
                    ds.showInfoAlert(t('Agreement.RegistrationSuccess'));
                })
                .catch(err => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                });
        }
        setOpenConfirm(false);
    }

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
            scroll={'paper'}
            className={dialogStyles.dialog}
        >
            <DialogTitle id={`${title}_dialog_id`} className={dialogStyles.title}>{t(title)}</DialogTitle>
            <DialogContent className={dialogStyles.content}>
                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>

                    <div className={dialogStyles.selectContainer}>
                        <InputSelect
                            form={form}
                            name={'lang'}
                            label={t('Agreement.Lang')}
                            selectType={LanguageCode}
                            value={agreementDetail.current.lang}
                            text={langText}
                            variant={'outlined'}
                        />

                        <InputSelect
                            form={form}
                            name={'useStatus'}
                            label={t('Agreement.UseStatus')}
                            selectType={UseStatusCode}
                            value={agreementDetail.current.useStatus}
                            text={statusText}
                            variant={'outlined'}
                        />

                        <InputSelect
                            form={form}
                            name={'type'}
                            label={t('Agreement.Type')}
                            selectType={AgreementType}
                            value={agreementDetail.current.type}
                            text={typeText}
                            variant={'outlined'}
                        />

                        <AccessClientSelectBox
                            className={dialogStyles.client}
                            title={t("Agreement.ClientSelect")}
                            defaultClientKey={""}
                            onChangeSelect={onChangeClient}
                            addAll={false}

                            //form={form}
                            //value= {agreementDetail.current.clientKey}
                        />
                        <InputSelect
                            form={form}
                            name={'subCltBhv'}
                            label={t('Agreement.SubClientBehavior')}
                            selectType={SubClientBehavior}
                            value={agreementDetail.current.subCltBhv}
                            text={t('CommonCode.' + subClientBehaviorText)}
                            variant={'outlined'}
                            rules={'required'}
                        />
                    </div>
                    <div className={dialogStyles.bodyContainer}>
                        <div>
                            <InputTexts
                                form={form}
                                name={'title'}
                                label={t('Agreement.Title')}
                                variant={'outlined'}
                            />
                        </div>
                        <div>
                            <TextEditor placeholder={t('Agreement.Content')} field={form.$("content")} height={300}/>
                        </div>
                    </div>
                </form>
                <div className={dialogStyles.buttonContainer}>
                    <Button variant='contained' size='large' color='primary' onClick={form.onSubmit}>{t('Agreement.CreateDialog.Save')}</Button>
                    <Button variant="contained"
                            type={'button'} form={formId}
                            className={dialogStyles.grayButton}
                            onClick={saveCancelClick}
                    >
                        {t('Agreement.CreateDialog.Cancel')}
                    </Button>
                </div>
            </DialogContent>
            <ConfirmDialog
                open={openConfirm}
                onClick={onClickBtn}
                title={t('Agreement.CreateDialog.ConfirmTitle')}
                content={t('Agreement.CreateDialog.ConfirmContent')}/>
        </Dialog>
    ));
}

function ConfirmDialog({title, content, open, onClick}) {
    const {t} = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClick(true)} color="primary" autoFocus>
                    {t('Agreement.CreateDialog.ConfirmYes')}
                </Button>
                <Button onClick={() => onClick(false)}>
                    {t('Agreement.CreateDialog.ConfirmNo')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function CommonCodeKey(dataList, data) {
    let dataKey = "";
    Object.keys(dataList).map((key, index) => {
        if (dataList[key] === data) {
            dataKey = dataList[key];
        }
    });
    return dataKey;
}
























