import React, {useState} from 'react';
import TabPanels from "../components/TabPanels";
import InfoPage from "./my/InfoPage";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MyAuthHistoryPage from "./my/MyAuthHistoryPage";
import MyEventPage from "./my/MyEventPage";
import MySitePage from "./my/MySitePage";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        padding: theme.spacing(6),
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        }
    },
}));

export default function MyPage() {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);

    const items = [
        {label: t('MyPage.MyInfo.Title')},
        {label: t('MyPage.AuthHistory.Title')},
        {label: t('MyPage.Event.Title')},
        {label: t('MyPage.Site.Title')},
    ];

    const tabItems = [];
    const onTabChange = (selectedIndex) => {
        setTabIndex(selectedIndex);
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} tabItems={tabItems} changeHandler={onTabChange} useBack={false} />
            <Box className={classes.tabContent}>
                {switchTab(tabIndex)}
            </Box>
        </React.Fragment>
    );


    function switchTab(index) {
        switch (index) {
            case 0 :
                return <InfoPage/>
            case 1 :
                return <MyAuthHistoryPage/>
            case 2 :
                return <MyEventPage/>
            case 3 :
                return <MySitePage />
            default:
                return null
        }
    }
}
