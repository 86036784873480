import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Cards from "../../components/Cards";
import {useStores} from "../../common/store";
import api from "../../common/api";
import {ErrCode, CountryCode, ClientStatus, UserType, ApprovalStatus, ClientContractType, LoginType} from "../../common/types"
import _ from "lodash";
import Button from "@material-ui/core/Button";
import InputTexts from "../../components/InputTexts";
import InputSelect from "../../components/InputSelect";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {useObserver} from "mobx-react";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ProgressButton, {LoginBackGround} from "../../components/ProgressButton";
import {PhoneNumberFormat} from "../../common/utils/StringFormatUtils";
import AccessClientSelectBox from "../../components/AccessClientSelectBox";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        '& > div': {
            padding: 0,
            flexGrow: 1,
        },
        '& > div > .MuiGrid-item': {
            display: 'flex',
        }
    },
    copyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    copyText: {
        maxWidth: '150px',
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.between('xs', 'xs')]: {
            maxWidth: 'initial',
        }
    },
    grayButton: {
        background: theme.buttons.btn1.background.default,
        color: theme.buttons.btn1.color,
        '&:hover': {
            background: theme.buttons.btn1.background.hover,
        }
    },
    button: {
        justifyContent: 'center',
    },
    buttonBox: {
        paddingBottom: theme.spacing(3),
        marginTop: theme.spacing(-3),
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(-2),
        },
    },
    inputPadding: {
        '& div.MuiOutlinedInput-input, & input.MuiOutlinedInput-input': {
            padding: 14
        }
    },
    inputText: {
        '& input': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,

        }
    },
    accessClientSelectBox: {
        display: 'flex',
        flex: 1,
    }
}));


const dialogStyles = makeStyles((theme) => ({
        dialog_title: {
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
        dialog_content: {
            marginTop: -theme.spacing(1),
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 960,
        },
        dialog_buttons: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: theme.spacing(1),
            "& > *": {
                minWidth: 100,
            }
        }
    })
);


export default function DetailPage(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {t, i18n} = useTranslation();
    const {clientKey, isEditable, pageEvents, openDialog, formId} = props;
    const {toggleClickHandler} = pageEvents;

    const [response, setResponse] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [saveDialog, setSaveDialog] = React.useState(false);
    // const history = useHistory();

    const storages = useRef(
        {
            clientStorage: {},
            vendorStorage: {}
        }
    );
    const cloneData = useRef(
        {
            clientStorage: {},
            vendorStorage: {}
        }
    );

    const accessUserType = useRef('');

    useEffect(() => {

        accessUserType.current = ds.user.getAccessClientUserType(clientKey);

        api.getClientDetail(clientKey)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    storages.current.clientStorage = data.data;
                    cloneData.current.clientStorage = data.data;
                    setResponse(data.data);
                }
            }).catch((err) => {
            ds.showErrorAlert(api.getErrMsg(err.rtCode));
            console.log("err ==>", err);
        });


        if (openDialog) {
            storages.current = cloneData.current;
            toggleClickHandler(true);
        }

    }, [openDialog]);


    /* ====================================================================================================================================================== */

    const loadingHandler = (value) => {
        setLoading(value);
    }

    const saveOpenHandler = (value) => {
        setSaveDialog(value);
    }

    const storageProcessing = () => {

        /********************* CLIENT DETAIL MODIFY *********************/
        api.setClientModify(clientKey, storages.current.clientStorage)
            .then((data) => {
                ds.showInfoAlert(t('ClientDetail.Detail.SaveClientSuccess'));
                storages.current.clientStorage = data.data;
                loadingHandler(false);
            })
            .catch((err) => {
                console.log("error ==> ", err);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                loadingHandler(false);
            });
        /********************* CLIENT DETAIL MODIFY *********************/

        cloneData.current = _.cloneDeep(storages.current);

        loadingHandler(false);
        toggleClickHandler(false);

    }

    const onAccessClientChange = (type, value) => {
        form.$("vendorClientKey").value = value;
    }

    const info = [
        {
            label: t('ClientDetail.Detail.Vendor'),
            name: 'vendorClientKey',
            rules: storages.current.clientStorage?.clientKey === api.MASTER_CLIENT_KEY ? '' : 'required',
            value: storages.current.clientStorage?.vendorClientKey,
            component: AccessClientSelectBox,
            defaultClientKey: storages.current.clientStorage?.vendorClientKey,
            defaultClientName: storages.current.clientStorage?.vendorClientName,
            addAll: false,
            readOnly: !isEditable,
            onChangeSelect: onAccessClientChange,
            className: classes.accessClientSelectBox
        },
        {
            label: t('ClientDetail.Detail.Status'),
            name: 'clientStatus',
            rules: 'required',
            value: storages.current.clientStorage?.clientStatus,
            component: InputSelect,
            isEditable: isEditable,
            selectType: ClientStatus,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.LoginType'),
            name: 'loginType',
            rules: 'required',
            value: storages.current.clientStorage?.loginType,
            component: InputSelect,
            isEditable: isEditable,
            selectType: LoginType,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.Approval'),
            name: 'approvalStatus',
            rules: 'required',
            value: storages.current.clientStorage?.approvalStatus,
            component: InputSelect,
            isEditable: isEditable,
            selectType: ApprovalStatus,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.Region'),
            name: 'clientCountry',
            rules: 'required',
            value: storages.current.clientStorage?.clientCountry,
            component: InputSelect,
            isEditable: isEditable,
            selectType: CountryCode,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.Type'),
            name: 'contractType',
            rules: '',
            value: storages.current.clientStorage?.contractType,
            component: InputSelect,
            isEditable: isEditable,
            selectType: ClientContractType,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.BizNo'),
            name: 'bizNo',
            rules: 'max:50',
            value: storages.current.clientStorage?.bizNo,
            component: InputTexts,
            isEditable: isEditable,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.Homepage'),
            name: 'siteUrl',
            rules: 'url|max:255',
            value: storages.current.clientStorage?.siteUrl,
            component: InputTexts,
            isEditable: isEditable,
            style: classes.inputPadding
        },
        {label: t('ClientDetail.Detail.Explain'), cardType: "type1"},
        {
            name: 'clientExplain',
            placeholder: 'Explain',
            cardType: "type2",
            rules: 'max:1000',
            value: storages.current.clientStorage?.clientExplain,
            component: InputTexts,
            isEditable: isEditable,
            rows: 4,
            style: classes.inputPadding
        },
    ];


    const appInfo = [
        {label: t('ClientDetail.Detail.FCM'), cardType: "type1"},
        {
            name: 'fcmServerKey',
            placeholder: 'Insert FCM',
            cardType: "type2",
            rules: 'max:200',
            value: storages.current.clientStorage?.fcmServerKey,
            component: InputTexts,
            isEditable: isEditable,
            rows: 4,
            style: classes.inputPadding
        },
    ];

    if (storages.current.clientStorage?.telNo !== undefined) {
        storages.current.clientStorage.telNo = PhoneNumberFormat(storages.current.clientStorage.telNo);
    }

    const personInfo = [
        {
            label: t('ClientDetail.Detail.Name'),
            name: 'managerName',
            rules: 'max:50',
            value: storages.current.clientStorage?.managerName,
            component: InputTexts,
            isEditable: isEditable,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.Email'),
            name: 'managerEmail',
            rules: 'email|max:30',
            value: storages.current.clientStorage?.managerEmail,
            component: InputTexts,
            isEditable: isEditable,
            style: classes.inputPadding
        },
        {
            label: t('ClientDetail.Detail.TelNo'),
            name: 'telNo',
            rules: 'max:20',
            value: storages.current.clientStorage?.telNo,
            component: InputTexts,
            isEditable: isEditable,
            style: classes.inputPadding
        }
    ];

    const fields = info.concat(appInfo).concat(personInfo);
    +

        validatorjs.useLang(i18n.language);

    const plugins = {
        dvr: dvr(validatorjs)
    };

    const hooks = {
        onSubmit(form) {
            storages.current = _.cloneDeep(cloneData.current);

            const values = form.values();
            Object.keys(values).map((key, index) => {
                storages.current.clientStorage[key] = values[key];
            });

        },
        onSuccess(form) {
            saveOpenHandler(true);
        },
        onError(form) {
            loadingHandler(false);
            console.log("All form errors", form.errors());
        },
    };

    const form = new MobxReactForm({fields}, {plugins, hooks});


    const saveOkClick = () => {
        loadingHandler((prevLoginHandler) => !prevLoginHandler);

        saveOpenHandler(false);
        storageProcessing();
    }

    const saveCancelClick = () => {

        storages.current = _.cloneDeep(cloneData.current);

        toggleClickHandler(false);
    }

    /*========================================================================================================================================================*/
    return useObserver(() => (
        <React.Fragment>
            {UserType.isManager(accessUserType.current) &&
            <div className={classes.buttonBox}>
                {
                    isEditable === false ?
                        (
                            <Button color={'primary'} variant="contained"
                                    type={'button'} form={formId}
                                    onClick={() => toggleClickHandler(true)}
                            >
                                {'Edit'}
                            </Button>
                        ) :
                        (
                            <React.Fragment>
                                <ProgressButton form={formId} loading={loading} label={'Save'} color={'primary'} variant={'contained'}/>
                                <Button color={'primary'} variant="contained"
                                        type={'button'} form={formId}
                                        className={classes.grayButton}
                                        onClick={saveCancelClick}
                                >
                                    {'Cancel'}
                                </Button>
                            </React.Fragment>
                        )
                }
            </div>
            }
            <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId} className={classes.root}>
                <Grid container spacing={5}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Cards title='ClientDetail.Detail.BasicInfo' components={info} form={form}/>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Cards title='ClientDetail.Detail.AppInfo' components={appInfo} form={form}/>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Cards title='ClientDetail.Detail.Manager' components={personInfo} form={form}/>
                    </Grid>
                </Grid>
                <LoginBackGround loading={loading}/>
            </form>


            <SaveConfirmDialog open={saveDialog}
                               onClose={saveOpenHandler}
                               onOk={saveOkClick}
                               title={t('ClientDetail.Detail.ConfirmTitle')}
                               content={t('ClientDetail.Detail.ConfirmContent')}
            />

        </React.Fragment>
    ));
}

function SaveConfirmDialog({open, onClose, onOk, title, content}) {
    const classes = dialogStyles();
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
        >
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color='primary' autoFocus>{t("ClientDetail.Detail.OkBtn")}</Button>
                <Button onClick={() => onClose(false)}>{t("ClientDetail.Detail.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    );
}
