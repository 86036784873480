import React, {useEffect} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import api from "../common/api";
import {useStores} from "../common/store";
import {ErrCode} from "../common/types";
import ConfirmDialog from "./ConfirmDialog";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    buttonGroup: {
      display: 'flex',
      border:'initial',
      flex:1,
      alignItems:'center',
      justifyContent:'space-between',
      boxShadow:'initial',
      '& :not(:last-child)' : {
          border:'initial'
      }
    },
    buttonDown:{
        minWidth:40,
        padding:'4px 10px',
        borderRadius:`4px !important`,
        background:theme.buttons.btn1.background.default,
        color:theme.buttons.btn1.color,
        '&:hover':{
            background:theme.buttons.btn1.background.hover,
        }
    },
    '.MuiButton-containedPrimary:hover': {
            backgroundColor:'#6a6d72',
    },
    popperContainer:{
        zIndex:1
    }
}));

export default function SplitButton(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const [open, setOpen] = React.useState(false);
    const { params, value, confirm, isEdit } = props;
    const options = params.options.current;
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [authorityConfirm, setAuthorityConfirm] = React.useState(false);

    const preSelect = React.useRef(null);

    /********************** USE STATE EVENT **********************/
     const changeAuthorityConfirm = () => {
        setAuthorityConfirm( (prevAuthorityConfirm) => !prevAuthorityConfirm);
    }
    /********************** USE STATE EVENT **********************/

    useEffect( () => {
        setSelectedIndex(options.indexOf(value));
    }, [value]);

    const handleMenuItemClick = (event, index) => {
        changeAuthorityConfirm();
        preSelect.current = selectedIndex;

        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const onConfirmClose = () => {
        setSelectedIndex(preSelect.current);
        changeAuthorityConfirm();
    }

    const changeAuthority = () => {

        console.info(`changing to: ${options[selectedIndex]} for client: ${params.clientKey} and user ${params.userKey}`);

        api.changeAuthority(params.clientKey, params.userKey, options[selectedIndex])
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    ds.showInfoAlert(t('UserPage.Detail.Dialog.ChangeAuthoritySuccess'));
                    changeAuthorityConfirm();
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("err => ",err);
                return err;
            });
    }

    return (
        <React.Fragment>
            {/*<ButtonGroup className={classes.buttonGroup} variant="contained" color="primary" ref={anchorRef} aria-label="split button">*/}
            <Box className={classes.buttonGroup} ref={anchorRef} >

                { options !== undefined && <span>{t('CommonCode.' + options[selectedIndex])}</span> }
                { isEdit &&
                    <Button
                        className={classes.buttonDown}
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon/>
                    </Button>
                }
            {/*</ButtonGroup>*/}
            </Box>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start" className={classes.popperContainer}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}  style={{transformOrigin: 'center top'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options?.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            disabled={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {t('CommonCode.' + option)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <ConfirmDialog open={authorityConfirm}
                           onClose={onConfirmClose}
                           onOk={changeAuthority}
                           title={confirm.title}
                           content={confirm.content}
            />

        </React.Fragment>
    )
}


