import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Cards from "../../components/Cards";
import api from "../../common/api";
import {CommonCodeValue, ErrCode, LicenseQuotaType, UserType} from "../../common/types";
import LabelText from "../../components/LabelText";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import PropTypes from 'prop-types';
import moment from "moment";
import queryString from 'query-string';
import _ from "lodash";
import {useStores} from "../../common/store";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Redirect, useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: 0,
        '& > div': {
            padding: 0,
            flexGrow: 1,
        },
        '& > div > div': {
            display: 'flex',
        }
    },
    licenseDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(-3),
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(-2),
        },
    },
    buttonBox: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(-2),
        },
    },
}));

const alertStyles = makeStyles((theme) => ({
        alert: {
            left: theme.spacing(4),
            marginBottom: theme.spacing(3),
            backgroundColor: '#a78e32'
        }
    })
);

export default function LicenseDetailPage(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const history = useHistory();

    const {location} = props;
    const querySearch = queryString.parse(location.search);
    const seq = querySearch.seq;
    const clientKey = querySearch.clientKey;
    const userType = ds.user.getAccessClientUserType(clientKey) ?? null;

    const [revokeDialog, setRevokeDialog] = React.useState(false);
    const [licenseData, setLicenseData] = useState(null);

    const revokeOpenHandler = (value) => {
        setRevokeDialog(value);
    }

    const revoke = () => {
        api.deleteLicense(seq)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    // revokeOpenHandler(false);
                    ds.showInfoAlert(t('LicenseDetail.RevokeDialog.RevokeSuccess'));
                    history.push('/admin/license');
                }
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    };

    const search = () => {
        api.getLicenseDetail(seq)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    setLicenseData(data.data);
                }
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    };

    useEffect(() => {
        search();
    }, []);

    return (
        <React.Fragment>
            {UserType.isSuperManager(userType) &&
            <div className={classes.buttonBox}>
                <Button color={'primary'}
                        variant="contained"
                        type={'button'}
                        onClick={() => revokeOpenHandler(true)}>
                    Revoke
                </Button>
            </div>
            }
            <form className={classes.root}>
                <Grid container spacing={5}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <LicenseStatusCard licenseData={licenseData}/>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <SpecificationsCard licenseData={licenseData}/>
                    </Grid>
                </Grid>
            </form>

            <Dialog
                open={revokeDialog}
                onClose={revokeOpenHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('LicenseDetail.RevokeDialog.Title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('LicenseDetail.RevokeDialog.ConfirmContent')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => revoke()} autoFocus>
                        {t('LicenseDetail.RevokeDialog.ConfirmYes')}
                    </Button>
                    <Button onClick={() => revokeOpenHandler(false)}>
                        {t('LicenseDetail.RevokeDialog.ConfirmNo')}
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

function SpecificationsCard({licenseData}) {
    const {t} = useTranslation();

    let client = null;
    let vendor = null;
    let contract = null;
    let quota = null;
    let period = null;
    let threshold = null;
    let createDt = null;


    if (licenseData !== null) {
        client = licenseData.clientName ?? '-';
        vendor = licenseData.vendorClientName ?? '-';
        contract = t(CommonCodeValue.translateKey + '.' + (licenseData.contractType ?? '-'));
        period = licenseData.startDt + ' ~ ' + licenseData.endDt;
        quota = t(CommonCodeValue.translateKey + '.' + (licenseData.quotaType ?? '-'));
        threshold = licenseData.maxQuota;
        createDt = _dataFormat(licenseData?.createDt, 'date', 'YYYY-MM-DD HH:mm:ss');
    }

    const specificationsInfo = [
        {label: 'LicenseDetail.Client', name: 'clientName', component: LabelText, value: client, translation: true},
        {label: 'LicenseDetail.Vendor', name: 'vendorClientName', component: LabelText, value: vendor, translation: true},
        {label: 'LicenseDetail.Contract', name: 'contract', component: LabelText, value: contract, translation: true},
        {label: 'LicenseDetail.QuotaType', name: 'quota', component: LabelText, value: quota, translation: true},
        {label: 'LicenseDetail.Threshold', name: 'threshold', component: LabelText, value: threshold, translation: true},
        {label: 'LicenseDetail.Period', name: 'period', component: LabelText, value: period, translation: true},
        {label: 'LicenseDetail.CreateDt', name: 'createDt', component: LabelText, value: createDt, translation: true}
    ];

    return (
        <Cards title={'LicenseDetail.Specifications'} components={specificationsInfo}/>
    );
}

function LicenseStatusCard({licenseData}) {
    const {t} = useTranslation();

    let seq = null;
    let status = null;
    let quota = null;
    let reaming = null;

    let usage = 0;
    let usageRate = 0;

    let dayAlert = null;
    let usageAlert = null;

    const alertContents = [];

    if (licenseData ?? false) {
        seq = licenseData.seq ?? '-';
        quota = t(CommonCodeValue.translateKey + '.' + licenseData.quotaType ?? '-');
        status = t(CommonCodeValue.translateKey + '.' + licenseData.status ?? '-');
        reaming = licenseData.reamingDays ?? 0;

        if (licenseData.maxQuota > -1) {
            if (licenseData.quotaType === LicenseQuotaType.ByAuth) {
                usage = licenseData.clientAuthCount;
            } else if (licenseData.quotaType === LicenseQuotaType.ByUser) {
                usage = licenseData.clientUserCount;
            }

            if (licenseData.maxQuota > 0) {
                usageRate = Math.round(usage / licenseData.maxQuota * 100);
            }
        }

        if (usageRate > 80) {
            usageAlert = `License expiration usage is less than ${usageRate}%`;
            alertContents.push(usageAlert);
        }

        if (reaming < 30) {
            dayAlert = `The date is less than ${reaming} days away.`;
            alertContents.push(dayAlert);
        }
    }


    const statusInfo = [
        {label: 'LicenseDetail.Seq', name: 'seq', component: LabelText, value: seq, translation: true},
        {label: 'LicenseDetail.Status', value: status, component: LabelText, translation: true},
        {label: 'LicenseDetail.QuotaType', name: 'quota', component: LabelText, value: quota, translation: true},
        {label: 'LicenseDetail.UsageRate', name: 'threshold', component: LabelText, value: usageRate + '%', translation: true},
        {label: 'LicenseDetail.Remaining', name: 'reaming', component: LabelText, value: `${reaming?.toLocaleString()} days`, translation: true},
        {cardType: "expireAlert", component: ExpireAlert({level: 'error', variant: 'filled', style: alertStyles(), contents: alertContents})},
    ];

    return (
        <Cards title={'LicenseDetail.StatusInformation'} components={statusInfo}/>
    );
}


ExpireAlert.prototype = {
    level: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
}

function ExpireAlert({style, level, title, contents, variant}) {
    return (
        contents?.length > 0 ?
            contents.map((content) =>
                <Alert severity={level}
                       variant={variant}
                       className={style.alert}
                >
                    <AlertTitle className={style.title}>{title}</AlertTitle>
                    {content}
                </Alert>
            )
            : null
    );
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
