import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {CommonCodeValue, UserType, UserStatus} from "../../common/types";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {useStores} from "../../common/store";
import moment from "moment";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBox from "../../components/SearchBox";

const useStyles = makeStyles((theme) => ({
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        marginBottom: theme.spacing(4),
        "& > :last-child": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            flexDirection: 'column',
        }
    },
    selectDiv: {
        display: 'inline-flex',
        [theme.breakpoints.between('sm', 'md')]: {
            '& > *': {
                flex: 1,
            },
            '& > :nth-child(1)': {
                marginRight: theme.spacing(1),
            },
            '& > :nth-child(2)': {
                marginLeft: theme.spacing(1),
            }
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    selectBox: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            marginRight: 0,
        }
    },
    searchBox: {
        display: 'flex',
        minWidth: '20rem',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const tableStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
    },
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60
        },
        '& > :nth-child(2)': {
            minWidth: 130,
        },
        '& > :nth-child(3)': {
            minWidth: 130,
        },
        '& > :nth-child(4)': {
            minWidth: 130,
        },
        '& > :nth-child(5)': {
            minWidth: 130
        },
        '& > :nth-child(6)': {
            minWidth: 130,
        },
    },
    headCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : 'white',
        backgroundColor: theme.palette.type === 'dark' ? '#0d1015' : '#525458',
    },
    bodyRow: {
        height: '3.875rem',
        cursor: 'pointer',
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.type === 'dark' ? '#29303a' : '#f8f8f8'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.type === 'dark' ? '#323942' : '#e7e7e7'
        },
    },
    bodyCell: {
        ...theme.typography.body1,
        color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    },
}));

export default function ManagerListPage(props) {
    const pageSize = 5;
    const {clientKey, clientName} = props;
    const classes = useStyles();
    const {ds} = useStores();

    let page = 1;
    const [keyword, setKeyword] = useState(null);
    let userType = 'ALL';
    let userStatus = 'ALL';

    const selectType = useRef(userType);
    const selectStatus = useRef(userStatus);

    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);

    const changedSearchKeywordEvent = (event) => {
        setKeyword(event.target.value);
    }

    const onChangeSelect = (type, value) => {
        if (type === 'type') {
            selectType.current = value;
        } else if (type === 'status') {
            selectStatus.current = value;
        }
        search();
    }

    const rowClickEvent = (row) => (event) => {
        setEvent({type: 'detail', clientName: clientName, clientKey: row[7]?.content, userKey: row[8]?.content});
    }

    const search = (event, page = 1) => {
        const type = selectType.current !== 'ALL' ? selectType.current : null;
        const status = selectStatus.current !== 'ALL' ? selectStatus.current : null;
        api.getManagerList(clientKey, keyword, type, status, page - 1, pageSize, 'REG_DT,DESC')
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                setResponse(null);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    const fetchData = (query) => {
        const type = query.userType !== 'ALL' ? query.userType : null;
        const status = query.userStatus !== 'ALL' ? query.userStatus : null;
        api.getManagerList(clientKey, query.keyword, type, status, query.page - 1, pageSize, 'REG_DT,DESC')
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                setResponse(null);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    useEffect(() => {
        fetchData({clientKey, userType, userStatus, keyword, page});
    }, [location.search]);


    if (event?.type === 'detail') {
        return (
            <Redirect push to={{
                pathname: '/admin/users/detail',
                search: "?" + api.encodeParams({clientKey: event.clientKey, userKey: event.userKey}),
                state: api.encodeParams({clientName: event.clientName})
            }}/>
        );
    }
    
    return (
        <div className={classes.root}>
            <ConditionPanel keyword={keyword}
                            defaultClientKey={clientKey}
                            defaultType={userType}
                            defaultStatus={userStatus}
                            search={search}
                            onChangeSelect={onChangeSelect}
                            changedSearchKeywordEvent={changedSearchKeywordEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={response !== null ? response.page.currentPage + 1 : 1}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
        </div>
    );
}

function ConditionPanel({keyword, defaultType, defaultStatus, search, onChangeSelect, changedSearchKeywordEvent}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const currentUserType = ds.user.userType;

    return (
        <div className={classes.conditionDiv}>
            <div className={classes.selectDiv}>
                {UserType.isClientManager(currentUserType) ? null :
                    <TypeSelectBox className={classes.selectBox}
                    title={t('UserPage.UserType')}
                    defaultType={defaultType}
                    onChangeSelect={onChangeSelect}
                    currentUserType={currentUserType}/>
                }
                <StatusSelectBox className={classes.selectBox}
                                 title={t('UserPage.UserStatus')}
                                 defaultStatus={defaultStatus}
                                 onChangeSelect={onChangeSelect}
                                 addAll/>
            </div>
            <SearchBox
                value={keyword}
                placeholder={t('UserPage.SearchPlaceholder')}
                doQuery={search}
                onChange={changedSearchKeywordEvent}
            />
        </div>
    );
}

function TypeSelectBox({className, title, defaultType, onChangeSelect, currentUserType}) {
    const {t} = useTranslation();
    const typeArray = [];

    if (typeArray.length === 0) {
        Object.keys(UserType).map((key, index) => {
            if (UserType.isManager(UserType[key])) {
                if (UserType[key] >= currentUserType) {
                    typeArray[index] = {key: key, value: UserType[key], text: t(CommonCodeValue.translateKey + '.' + UserType[key])};
                }
            }
        })
    }

    const onChangeType = (event) => {
        const typeCode = event.target.value;

        if (onChangeSelect) {
            onChangeSelect('type', typeCode);
        }
    }

    return (
        <SelectBox className={className}
                   title={title}
                   displayEmpty={false}
                   defaultValue={defaultType}
                   contents={typeArray}
                   onChange={onChangeType}
                   addAll
        />
    );
}

function StatusSelectBox({className, title, defaultStatus, onChangeSelect}) {
    const {t} = useTranslation();
    const statusArray = [];

    if (statusArray.length === 0) {
        Object.keys(UserStatus).map((key, index) => {
            statusArray[index] = {key: key, value: UserStatus[key], text: t(CommonCodeValue.translateKey + '.' + UserStatus[key])};
        })
    }

    const onChangeStatus = (event) => {
        const statusCode = event.target.value;

        if (onChangeSelect) {
            onChangeSelect('status', statusCode);
        }
    }

    return (
        <SelectBox className={className}
                   title={title}
                   displayEmpty={false}
                   defaultValue={defaultStatus}
                   contents={statusArray}
                   onChange={onChangeStatus}
                   addAll
        />
    );
}

function SelectBox({className, title, contents, defaultValue, value, displayEmpty = true, onChange, addAll}) {
    const [labelId] = useState(() => _.uniqueId('label-'));

    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                labelId={labelId}
                label={title}
                displayEmpty={displayEmpty}
                defaultValue={defaultValue}
                onChange={onChange}
                labelWidth={80}
            >
                {addAll === true ? <MenuItem value="ALL">{CommonCodeValue.getText("ALL")}</MenuItem> : null}
                {
                    contents.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {
    const tableStyles = tableStyle();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: ''},
            {content: t('UserPage.UserID')},
            {content: t('UserPage.UserType')},
            {content: t('UserPage.Email')},
            {content: t('UserPage.PhoneNumber')},
            {content: t('UserPage.UserStatus')},
            {content: t('UserPage.Date')}
        ]
    ];

    if (response !== null) {
        let num = response.page.currentPage * pageSize;

        response.data.map((data, idx) => {
            let userId = data.userKey ? data.userKey : '-';
            let userType = data.userType ? t(CommonCodeValue.translateKey + '.' + data.userType) : '-';
            let userEmail = data.email ? data.email : '-';
            let userPhone = data.phoneNum ? data.phoneNum : '-';
            let userStatus = data.userStatus ? t(CommonCodeValue.translateKey + '.' + data.userStatus) : '-';
            let userDate = data.regDt ? _dataFormat(data.regDt, 'date') : '-';

            body[idx] = [
                {id: 'index', content: ++num},
                {id: 'userId', content: userId},
                {id: 'type', content: userType},
                {id: 'userEmail', content: userEmail},
                {id: 'userPhone', content: userPhone},
                {id: 'status', content: userStatus},
                {id: 'date', content: userDate},
                {id: 'clientKey', content: data.clientKey},
                {id: 'userKey', content: data.userKey}
            ];
        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable style={tableStyles}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}