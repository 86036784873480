import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import TabPanels from "../components/TabPanels";
import DetailPage from "./clients/DetailPage";
import StatusPage from "./clients/StatusPage";
import queryString from 'query-string';
import _ from "lodash";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import AuthHistoryPage from "./clients/AuthHistoryPage";
import AgreementConfigPage from "./clients/AgreementConfigPage";
import {useStores} from "../common/store";
import ManagerListPage from "./clients/ManagerListPage"
import LicenseDetailPage from "./clients/LicenseDetailPage";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        padding: 0
    },
    bdR: {
        borderRadius: theme.spacing(5),
    },
    editBtn: {
        margin: theme.spacing(1),
    },
    tabBox: {
        textAlign: 'right',
        margin: 'auto',
        '& > *': {
            marginRight: theme.spacing(1)
        }
    },
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        paddingTop: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.between('xs', 'md')]: {
            padding: theme.spacing(3),
        }
    },
}));


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box>{children}</Box>)}
        </div>
    );
}

export default function ClientDetailsPage(props) {
    const classes = useStyles();
    const {location} = props;
    const query = queryString.parse(location.search);
    const queryState = queryString.parse(location.state);
    const clientKey = query.clientKey;
    const licenseSeq = query.licenseSeq ?? null;
    const clientName = queryState.clientName;
    const {ds} = useStores();
    const {t} = useTranslation();
    const history = useHistory();

    const [isEditable, setIsEditable] = React.useState(false);
    const [tabIndex, setTabIndex] = React.useState(0);

    const changeTabIndex = (value) => {
        if (tabIndex !== value) {
            setTabIndex(value);
            setIsEditable(false);
        }
    }

    const toggleClickHandler = (value) => {
        console.log("toggleClickHandler", value);
        setIsEditable(value);
    }

    useEffect(() => {
        if (_.includes(props.url, "detail")) {
            if (clientKey === undefined) {
                ds.showErrorAlert(t('Error.WRONG_APPROACH'));
                history.push('/admin/clients');
            }
        }
    }, []);

    const items = [
        {label: t('ClientDetail.Title.Detail'), page: DetailPage, pageEvents: {toggleClickHandler}, formId: 'detailForm'}
    ]

    items.push(
        {label: t('ClientDetail.Title.Status'), page: StatusPage},
        {label: t('ClientDetail.Title.License'), page: LicenseDetailPage},
        {label: t('ClientDetail.Title.AuthHistory'), page: AuthHistoryPage},
    );

    if( ds.user.isManager()) {
        items.push( {label: t('ClientDetail.Title.Agreement'), page: AgreementConfigPage} );
    }

    items.push( {label: t('ClientDetail.Title.Manager'), page: ManagerListPage} );

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} changeHandler={changeTabIndex}/>
            <Grid container>
                <Grid item xs={12} className={classes.tabContainer}>
                    <div className={classes.tabContent}>
                        {items.map((data, index) => (
                            (data.page !== undefined) &&
                            <TabPanel key={index.toString()} value={tabIndex} index={index}>
                                <data.page clientKey={clientKey} clientName={clientName} licenseSeq={licenseSeq} isEditable={isEditable} pageEvents={data.pageEvents} formId={data.formId}/>
                            </TabPanel>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

