import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router';
import queryString from 'query-string';
import api from "../common/api";
import {AgreementType, ErrCode, LanguageCode, UseStatusCode, SubClientBehavior} from "../common/types";
import {useStores} from "../common/store";
import Typography from "@material-ui/core/Typography";
import InputSelect from "../components/InputSelect";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import InputTexts from "../components/InputTexts";
import LabelText from "../components/LabelText";
import Button from "@material-ui/core/Button";
import ProgressButton, {LoginBackGround} from "../components/ProgressButton";
import _ from "lodash";
import {useObserver} from "mobx-react-lite";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import TextEditor from "../components/TextEditor";
import {Scrollbars} from "react-custom-scrollbars";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        padding: '40px 50px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        },
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    root2: {
        flexGrow: 1,
        flexBasis: 0,
        borderRadius: 10,
        paddingTop: '3.125rem',
        paddingBottom: '3.125rem',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',

        backgroundColor: theme.palette.type === 'dark' ? '#2c333c' : '#e7e7e7',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
            marginBottom: '1.875rem',
            paddingTop: '2.188rem',
            paddingBottom: '2.188rem',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.between('sm', 'md')]: {
            maxWidth: 'auto',
        }
    },
    title: {
        color: theme.palette.text.primary,
        "& > *": {
            color: theme.palette.text.title,
        }
    },
    detailContainer: {
        border: (theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255)' : '1px solid rgba(0, 0, 0)'),
        borderRadius: 10,
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > *": {
            color: theme.palette.text.primary,
        },
        '& > div:first-child': {
            minWidth: 130
        },
    },
    headerInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexFlow: 'wrap',
        '& > div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            [theme.breakpoints.between('xs', 'sm')]: {
                flexDirection:'column'
            },
            '& > dl': {
                display: 'flex',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                [theme.breakpoints.between('xs', 'sm')]: {
                    flexDirection:'column',
                    alignItems: 'initial',
                },
                '& > dt': {
                    flex: 1,
                    minWidth: 100,
                    color: theme.palette.text.gray,
                    [theme.breakpoints.between('xs', 'sm')]: {
                        marginBottom:theme.spacing(1),
                    },
                },
                '& > dd': {
                    flexGrow: 1,
                    minWidth: 150,
                    marginLeft: theme.spacing(2),
                    marginRight: theme.spacing(2),
                    [theme.breakpoints.between('xs', 'sm')]: {
                        margin:0,
                    }
                }
            }
        },

    },
    Divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    bodyContainer: {
        position:'relative',
        minHeight: 400,
        display: 'flex',
        flex: 1,
        flexDirection: "column",
        "& > *": {
            color: theme.palette.text.primary,
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '& > span': {
            ...theme.typography.h5,
            color: theme.palette.text.primary,
        }
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'stretch',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
    },
    footerContainer: {},
    buttonBox: {
        marginTop: theme.spacing(2),
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const dialogStyles = makeStyles((theme) => ({
        dialog_title: {
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
        dialog_content: {
            marginTop: -theme.spacing(1),
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 960,
        },
        dialog_buttons: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: theme.spacing(1),
            "& > *": {
                minWidth: 100,
            }
        }
    })
);


export default function AgreementDetailsPage(props) {

    const classes = useStyles();
    const {location} = props;
    const {t} = useTranslation();
    const {ds} = useStores();
    const history = useHistory();

    const querySearch = queryString.parse(location.search);
    const queryState = queryString.parse(location.state);
    const clientKey = querySearch.clientKey;
    const agreementSeq = querySearch.agreementSeq;
    const clientName = queryState.clientName;

    const agreementDetail = useRef({});
    const cloneData = useRef({});

    const [response, setResponse] = React.useState([]);
    const [isEditable, setIsEditable] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [saveDialog, setSaveDialog] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    /********************** USE STATE EVENT **********************/

    const toggleClickHandler = (value) => {
        console.log("toggleClickHandler", value);
        setIsEditable(value);
    }

    const loadingHandler = (value) => {
        setLoading(value);
    }

    const saveOpenHandler = (value) => {
        setSaveDialog(value);
    }
    const deleteOpenHandler = (value) => {
        setDeleteDialog(value);
    }

    /********************** USE STATE EVENT **********************/


    useEffect(() => {
        if (agreementSeq === undefined) {
            alert(t("Error.WRONG_APPROACH"));
            history.push('/admin/agreements');
        }

        api.getAgreementDetail(agreementSeq)
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    agreementDetail.current = data.data;
                    cloneData.current = data.data;
                    setResponse(data.data);
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("err => ", err);
                return err;
            });
    }, []);


    const formId = "agreementForm";
    const form = new MobxReactForm({
        fields: [
            {name: 'type', value: agreementDetail.current.type, rules: 'required', label: t('Agreement.Type')},
            {name: 'lang', value: agreementDetail.current.lang, rules: 'required', label: "Category"},
            {name: 'useStatus', value: agreementDetail.current.useStatus, rules: 'required', label: "Category"},
            {name: 'subCltBhv', value: agreementDetail.current.subCltBhv, rules: 'required', label: "Category"},
            {
                name: 'title',
                value: agreementDetail.current.title,
                rules: 'required',
                label: t("Agreement.Title"),
            },
            {
                name: 'content',
                value: agreementDetail.current.content,
                rules: 'required',
                label: t("Agreement.Content"),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSubmit(form) {
                console.log("onSubmit");
                console.log("agreementDetail.current.content 1 =>",agreementDetail.current.content);
                agreementDetail.current = _.cloneDeep(cloneData.current);

                const values = form.values();
                Object.keys(values).map((key, index) => {
                    agreementDetail.current[key] = values[key];
                });

                console.log("agreementDetail.current.content 2 =>",agreementDetail.current.content);
            },
            onSuccess(form) {
                if (isEditable && !form.isDirty) {
                    ds.showWarningAlert(t('Agreement.NoChange'));
                    return;
                }

                saveOpenHandler(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map( (data, index) => {
                    if( errorMsg === "" ) {
                        errorMsg = form.errors()[data];
                        if( errorMsg === null ) { errorMsg = "";}
                    }
                } );
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            },
        }
    });

    let langText = "";
    if (agreementDetail.current.lang !== undefined) {
        langText = CommonCodeKey(LanguageCode, agreementDetail.current.lang);
    }

    let statusText = "";
    if (agreementDetail.current.useStatus !== undefined) {
        statusText = CommonCodeKey(UseStatusCode, agreementDetail.current.useStatus);
    }

    let subClientBehaviorText = "";
    if (agreementDetail.current.subCltBhv !== undefined) {
        subClientBehaviorText = CommonCodeKey(SubClientBehavior, agreementDetail.current.subCltBhv);
    }

    let typeText = "";
    if (agreementDetail.current.type !== undefined) {
        typeText = CommonCodeKey(AgreementType, agreementDetail.current.type);
    }

    const saveCancelClick = () => {

        agreementDetail.current = _.cloneDeep(cloneData.current);

        toggleClickHandler(false);
    }

    const saveOkClick = () => {
        loadingHandler((prevLoginHandler) => !prevLoginHandler);
        saveOpenHandler(false);

        api.setAgreementModify(agreementSeq, agreementDetail.current)
            .then((data) => {
                ds.showInfoAlert(t('Agreement.SaveSuccess'));
                agreementDetail.current = data.data;
                cloneData.current = _.cloneDeep(agreementDetail.current);
                loadingHandler(false);
            })
            .catch((err) => {
                console.log("error ==> ", err);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                loadingHandler(false);
            });

        toggleClickHandler(false);
    }

    const deleteOkClick = () => {
        api.deleteAgreement(agreementSeq)
            .then((data) => {
                ds.showInfoAlert(t('Agreement.DeleteSuccess'));
                history.push('/admin/agreements');
            })
            .catch((err) => {
                console.log("error ==> ", err);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                loadingHandler(false);
            });

        deleteOpenHandler(false);
    }

    return useObserver(() => (
        <div className={classes.root}>

            <div className={classes.root2}>
                <div className={classes.titleContainer}>
                    <InputTexts
                        form={form}
                        name={'title'}
                        label={'Title'}
                        value={agreementDetail.current.title}
                        isEditable={isEditable}
                        variant={'outlined'}
                    />
                </div>


                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                    <div className={classes.headerContainer}>
                        <div className={classes.headerInfo}>
                            <div>
                                <dl>
                                    <dt>{t('Agreement.UserId')}</dt>
                                    <dd>
                                        <LabelText translation={false} label={agreementDetail.current.regUserKey}/>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t('Agreement.Date')}</dt>
                                    <dd>
                                        <LabelText translation={false} value={agreementDetail.current.regDt}
                                                   dataType={'date'} format={'YYYY-MM-DD HH:mm:ss'}/>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t('Agreement.Client')}</dt>
                                    <dd>
                                        <LabelText translation={false} value={agreementDetail.current.clientName}/>
                                    </dd>
                                </dl>
                            </div>
                            <div>
                                <dl>
                                    <dt>{t('Agreement.Type')}</dt>
                                    <dd>
                                        <InputSelect
                                            form={form}
                                            name={'type'}
                                            selectType={AgreementType}
                                            value={agreementDetail.current.type}
                                            text={t('CommonCode.' + typeText)}
                                            variant={'outlined'}
                                            rules={'required'}
                                            isEditable={isEditable}
                                        />
                                    </dd>
                                </dl>

                                <dl>
                                    <dt>{t('Agreement.Lang')}</dt>
                                    <dd>
                                        <InputSelect
                                            form={form}
                                            name={'lang'}
                                            selectType={LanguageCode}
                                            value={agreementDetail.current.lang}
                                            text={t('CommonCode.' + langText)}
                                            rules={'required'}
                                            variant={'outlined'}
                                            isEditable={isEditable}
                                        />
                                    </dd>
                                </dl>

                                <dl>
                                    <dt>{t('Agreement.UseStatus')}</dt>
                                    <dd>
                                        <InputSelect
                                            form={form}
                                            name={'useStatus'}
                                            selectType={UseStatusCode}
                                            value={agreementDetail.current.useStatus}
                                            text={t('CommonCode.' + statusText)}
                                            variant={'outlined'}
                                            rules={'required'}
                                            isEditable={isEditable}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t('Agreement.SubClientBehavior')}</dt>
                                    <dd>
                                        <InputSelect
                                            form={form}
                                            name={'subCltBhv'}
                                            selectType={SubClientBehavior}
                                            value={agreementDetail.current.subCltBhv}
                                            text={t('CommonCode.' + subClientBehaviorText)}
                                            variant={'outlined'}
                                            rules={'required'}
                                            isEditable={isEditable}
                                        />
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <Divider className={classes.Divider}/>

                    <div className={classes.bodyContainer}>

                            <div className={classes.contentContainer}>

                                { isEditable
                                    ?
                                    <TextEditor placeholder={t('Agreement.Content')} field={form.$("content")} height={400}/>
                                    :
                                    <Typography color='textPrimary' dangerouslySetInnerHTML={{__html: agreementDetail.current.content}}/>
                                }
                            </div>
                    </div>
                </form>


                <div className={classes.footerContainer}>
                    {ds.user.isManager() &&
                    <div className={classes.buttonBox}>
                        {
                            isEditable === false ?
                                (
                                    <React.Fragment>
                                        <Button color={'primary'} variant="contained"
                                                type={'button'} form={formId}
                                                onClick={() => toggleClickHandler(true)}
                                        >
                                            {t('Agreement.Detail.Edit')}
                                        </Button>
                                        <Button variant="contained" type={'button'}
                                                onClick={() => deleteOpenHandler(true)}
                                        >
                                            {t('Agreement.Detail.Delete')}
                                        </Button>
                                    </React.Fragment>
                                ) :
                                (
                                    <React.Fragment>
                                        <ProgressButton form={formId} loading={loading} label={t('Agreement.Detail.Save')} color={'primary'} variant={'contained'}/>
                                        <Button variant="contained"
                                                type={'button'} form={formId}
                                                className={classes.grayButton}
                                                onClick={saveCancelClick}
                                        >
                                            {t('Agreement.Detail.Cancel')}
                                        </Button>
                                    </React.Fragment>
                                )
                        }
                    </div>
                    }

                </div>
            </div>
            <LoginBackGround loading={loading}/>
            <SaveConfirmDialog open={saveDialog}
                               onClose={saveOpenHandler}
                               onOk={saveOkClick}
                               title={t('Agreement.Detail.ConfirmTitle')}
                               content={t('Agreement.Detail.EditContent')}
            />

            <DeleteConfirmDialog open={deleteDialog}
                                 onClose={deleteOpenHandler}
                                 onOk={deleteOkClick}
                                 title={t('Agreement.Detail.ConfirmTitle')}
                                 content={t('Agreement.Detail.DeleteContent')}
            />

        </div>
    ));
}

function CommonCodeKey(dataList, data) {
    let dataKey = "";
    Object.keys(dataList).map((key, index) => {
        if (dataList[key] === data) {
            dataKey = dataList[key];
        }
    });
    return dataKey;
}


function SaveConfirmDialog({open, onClose, onOk, title, content}) {
    const classes = dialogStyles();
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
        >
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color='primary' autoFocus>{t("Agreement.Detail.OkBtn")}</Button>
                <Button onClick={() => onClose(false)}>{t("Agreement.Detail.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    );
}

function DeleteConfirmDialog({open, onClose, onOk, title, content}) {
    const classes = dialogStyles();
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
        >
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color='primary' autoFocus>{t("Agreement.Detail.OkBtn")}</Button>
                <Button onClick={() => onClose(false)}>{t("Agreement.Detail.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    );
}
