import axios from 'axios';
import SockJS from 'sockjs-client';
import i18next from 'i18next';
import storage, {StorageKey as SK} from './storage';
import store from './store';
import {ErrCode, User} from './types';

class GuardianAPI {
    // HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://192.168.0.56" : "https://api.onebsa.com.my";
    /* TM PRODUCT */
    HOST = "https://api.onebsa.com.my";
    /* TM POC */
    // HOST = "https://appbsa.tabgdc.com";
    /* LAB */
    // HOST = "https://lab.fnsvalue.co.kr";
    /* AZURE */
    // HOST = "https://azlab.fnsvalue.co.kr";
    /* LAB TEST */
    // HOST = "https://labtest.fnsvalue.co.kr";

    MASTER_CLIENT_KEY = '1daec78593a643e6b53ce9803ded5916';
    // MASTER_CLIENT_KEY = 'baa1f90abfa443299edc527b67ab88bd';
    // MASTER_CLIENT_KEY = '5364131c35ea4cc380b3733668c1bd1a';
    // MASTER_CLIENT_KEY = '2d1e41baf5c0402aaa71a088c079b525';
    // MASTER_CLIENT_KEY = 'd227e269a29d4b44b30e28a9c296ba2f';
    // MASTER_CLIENT_KEY = 'a335dd9ebea2479fa4a66a0320afb31f';

    api = null;
    token = null;

    constructor() {
        this.token = storage.getSession(SK.ACCESS_TOKEN);

        this.api = axios.create({
            baseURL: this.HOST + '/api/v3',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.api.defaults.withCredentials = true;
        this.api.interceptors.request.use(config => {
            config.metadata = {startTime: new Date()};
            if (this.token != null && this.token.length > 0) {
                config.headers['Authorization'] = this.token;
            }
            const method = config.method.toUpperCase();
            //console.log(method, '-', config.baseURL + config.url, '[' + this.token + ']', '[' + config.headers['Content-Type'] + ']', config.params);
            return config;
        });
        this.api.interceptors.response.use(response => {
            const rtime = new Date() - response.config.metadata.startTime;
            //console.log('ResponseTime (', rtime, 'ms) :', response.data);
            const {rtCode} = response.data;
            if (rtCode !== ErrCode.RT_SUCCESS) {
                response.data.rtMsg = this.getErrMsg(rtCode);
                throw response.data;
            }

            if (response.data.token != undefined) {
                this.token = response.data.token;
            }

            return response;
        });
    }

    getErrMsg = (errCode) => {
        return i18next.t('Error.' + errCode);
    };

    setToken = (token) => {
        this.token = token;
        storage.setSession(SK.ACCESS_TOKEN, token);
    };

    hasToken = () => {
        return this.token !== null;
    };

    encodeParams = (data, nonNull = false) => {
        return Object
            .keys(data)
            .map(key => {
                    if (!nonNull || data[key] !== null) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                    }
                    return null;
                }
            )
            .filter(value => value !== null)
            .join('&');
    };

    getClientCodes = () => {
        return new Promise((resolve, reject) => {
            this.api.get('/clients')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getWebSocket = (clientKey, userKey, channelKey) => {
        const query = this.encodeParams({clientKey, userKey, channelKey});
        const ws = new SockJS(this.HOST + '/ws/v3/auth/status?' + query);
        return ws;
    };

    requestAuth = (clientKey, userKey, isOtpAuth = false) => {
        return new Promise((resolve, reject) => {
            this.api.post('/auth', {clientKey, userKey, isOtpAuth})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAuthResult = (clientKey, userKey, channelKey, isQrAuth =false) => {
        return new Promise((resolve, reject) => {
            const query = this.encodeParams({clientKey, userKey, channelKey,isQrAuth});
            this.api.get('/auth?' + query)
                .then(res => {
                    this.setToken(res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    deleteAuth = (clientKey, userKey, qrId = null) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/auth', {data: {clientKey: clientKey, userKey: userKey, qrId: qrId}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    getMe = () => {
        return new Promise((resolve, reject) => {
            this.api.get('/me')
                .then(res => {
                    let user = new User(res.data.data);

                    store.setUser(user);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getClientList = (clientName, page = 0, size = 0) => {
        let url = '/admin/clients?' + this.encodeParams({page, size, clientName}, true);
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getClientDetail = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get('/admin/clients/' + clientKey)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setClientModify = (clientKey, clientData) => {
        return new Promise((resolve, reject) => {
            this.api.put('/admin/clients/' + clientKey, clientData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setClient = (clientData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/clients', clientData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAppVersion(os, packageName) {
        return new Promise((resolve, reject) => {
            this.api.get('/admin/apps?os=' + os + "&packageName=" + packageName)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getClientStatus = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/status`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getVendorList = (page, size) => {
        const query = this.encodeParams({page, size});
        return new Promise((resolve, reject) => {
            this.api.get('/admin/vendors?' + (query ? query : ''))
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setAppVersionModify = (appVersionData) => {
        //console.log("appVersionData ==> ", appVersionData);
        return new Promise((resolve, reject) => {
            this.api.put('/admin/apps', appVersionData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setAppVersion = (appVersionData) => {
        //console.log("appVersionData ==> ", appVersionData);
        return new Promise((resolve, reject) => {
            this.api.post('/admin/apps', appVersionData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getQnaList = (params) => {
        const query = this.encodeParams(params);
        return new Promise((resolve, reject) => {
            this.api.get('/admin/qna?' + query)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getQnaDetail = (questionSeq) => {
        return new Promise((resolve, reject) => {
            this.api.get('/admin/qna/' + questionSeq)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postQuestion = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/qna', params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    putQuestion = (params) => {
        return new Promise((resolve, reject) => {
            this.api.put('/admin/qna/' + params.questionSeq, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postQuestionAnswer = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/qna/' + params.questionSeq + '/answer', params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteQuestion = (questionSeq) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/admin/qna/' + questionSeq)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getUserList = (clientKey, keyword, status, page = 0, size = 0, sort = null) => {
        const url = `/admin/clients/${clientKey}/users?${this.encodeParams({keyword: keyword !== null ? keyword.trim() : null, status: status, page, size, sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getUserDetail = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getPermittedModifications = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/permitted-modifications`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    changeAuthority = (clientKey, userKey, userType) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/clients/users/change-authority/`, {clientKey, userKey, userType})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    putUserInfoReset = (clientKey, userKey, category) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/clients/${clientKey}/users/${userKey}/reset`, {category})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getUserAuthHistory = (clientKey, userKey, status, startDtStr, endDtStr, page = 0, size = 5) => {
        const url = `/admin/clients/${clientKey}/users/${userKey}/history?${this.encodeParams({status: status, startDtStr: startDtStr, endDtStr: endDtStr, page, size}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getMyAuthHistory = (clientKey, authStatus, startDtStr, endDtStr, page = 0, size = 5, sort = null) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/history?${this.encodeParams({clientKey, authStatus: authStatus, startDate: startDtStr, endDtStr: endDtStr, page, size, sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getEvent = (clientKey, userKey, type, eventType, startDtStr, endDtStr, page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/users/${userKey}/event?${this.encodeParams({clientKey, type, eventType, startDate: startDtStr, endDate: endDtStr, page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getMyEvent = (clientKey, category, type, startDtStr, endDtStr, page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/event?${this.encodeParams({clientKey, category, type, startDate: startDtStr, endDate: endDtStr, page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    putMe = ({authType = null, iconSelect = null, name = null, email = null}) => {
        return new Promise((resolve, reject) => {
            this.api.put('/me', {authType, icons: iconSelect, name, email})
                .then(res => {
                    store.setUser(new User(res.data.data));
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getLogList = () => {
        let url = '/admin/config/log';
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setLogModify = (logData) => {
        return new Promise((resolve, reject) => {
            this.api.put('/admin/config/log', logData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAgreementList = (lang, useStatus, type, client, searchWord, page = 0, size = 0) => {
        const url = `/admin/agreements?${this.encodeParams({lang: lang, useStatus: useStatus, type: type, client: client, searchWord: searchWord, page, size}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getAgreementDetail = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/agreements/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    setAgreementModify = (seq, data) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/agreements/${seq}`, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteAgreement = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(`/admin/agreements/${seq}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postAgreement = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post(`/admin/agreements`, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getClientAuthHistory = (clientKey, authStatus, startDtStr, endDtStr, page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/auth/history?${this.encodeParams({status: authStatus, startDtStr: startDtStr, endDtStr: endDtStr, page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getClientAgreementList = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clients/${clientKey}/agreements/available`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getClientAgreementApplyList = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clients/${clientKey}/agreements`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postClientAgreement = (clientKey, params) => {

        return new Promise((resolve, reject) => {
            this.api.post(`/admin/clients/${clientKey}/agreements`, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getCommonAgreementDetail = (clientKey, seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clients/${clientKey}/agreements/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getMeClientCode = (type) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/clients?${this.encodeParams(type)}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateVerifyToken = () => {
        return new Promise((resolve, reject) => {
            this.api.put('/me/updateToken')
                .then(res => {

                    this.getMe()
                        .then(data => {
                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                        })
                        .catch(err => {
                            console.log("error ==> ", err);
                        });

                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAccessClientKey = (clientName) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clientKey/${clientName}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getEnabledStatus = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/enabled/status`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    putEnabledStatus = (clientKey, userKey, userStatus) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/clients/${clientKey}/users/${userKey}/enabled/status`, {userStatus})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getManagerList = (clientKey, keyword, type, status, page = 0, size = 0, sort = null) => {
        const url = `/admin/clients/${clientKey}/managers?${this.encodeParams({keyword: keyword !== null ? keyword.trim() : null, type, status, page, size, sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getQrWebSocket = (qrId) => {
        const query = this.encodeParams({qrId});
        const ws = new SockJS(this.HOST + '/ws/v3/qr/status?' + query);
        return ws;
    };

    getLoginQrUrl = (clientKey) => {
        const url = `/qr/generate?${this.encodeParams({clientKey})}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getTotpVerify = (otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/totp/verify?${this.encodeParams({otpCode})}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postTotpUserVerify = (clientKey, userKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.post('/totp/user/verify', {clientKey, userKey, otpCode})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getOtpVerify = (masterClientKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/otp/verify?${this.encodeParams({clientKey: masterClientKey, otpCode})}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postOtpUserVerify = (clientKey, userKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.post('/otp/user/verify', {clientKey, userKey, otpCode})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    sendOtp = (userKey, name, clientKey, verifyType, verifyData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/users/send-otp', {userKey, name, clientKey, verifyType, verifyData})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postMailOtpUserVerify = (clientKey, userKey, seq, email, authNum) => {
        return new Promise((resolve, reject) => {
            this.api.post('/mail/user/verify', {clientKey, userKey, seq, email, authNum})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postSmsOtpUserVerify = (clientKey, userKey, seq, phoneNum, authNum) => {
        return new Promise((resolve, reject) => {
            this.api.post('/sms/user/verify', {clientKey, userKey, seq, phoneNum, authNum})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getLicensePage = (client, vendor, status, page = 0, size = 0) => {
        const url = `/admin/clients/license?${this.encodeParams({clientKey: client, vendorClientKey: vendor, status: status, page, size}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getLicenseDetail = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/license/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postLicense = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/clients/license', params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteLicense = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/admin/clients/license', {data: {licenseSeq: seq}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getLinkClient = (page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/links?${this.encodeParams({page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteUnLink(clientKey) {
        return new Promise((resolve, reject) => {
            this.api.delete('/me/unlink', {data: {clientKey: clientKey}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteAdminUnLink(params) {
        return new Promise((resolve, reject) => {
            this.api.delete(`/admin/clients/${params.clientKey}/users/${params.userKey}/unlink`, {data: {...params}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getUserManagementPage = (keyword, status, page = 0, size = 0, sort = null) => {
        const url = `admin/management/users?${this.encodeParams({keyword: keyword !== null ? keyword.trim() : null, status: status, page, size, sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getUserInfo = (userKey) => {
        const url = `admin/management/users/${userKey}/info`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    deleteUser = (userKey, isUserDelete = false, isDeviceDelete = false) => {
        const url = `admin/management/users/${userKey}/delete`;
        return new Promise((resolve, reject) => {
            this.api.delete(url, {data: {isUserDelete: isUserDelete, isDeviceDelete: isDeviceDelete}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
}

export default new GuardianAPI();
